import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { AdminUpdateUserInput, SuperfeelUser } from '@superfeel/types';
import { Modal } from 'components/atoms';
import { Dropzone } from 'components/atoms/dropzone';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'state/context/snackBar';
import { getSignedS3Url } from 'utils/s3/getSignedS3Url';
import { ConfirmDialog } from 'components/atoms/dialog';
import { formatFileSize } from 'common/utils';
import { useAdminUpdateUserMutation } from '../api/user';

const ACCEPTED_MIME_TYPES = ['image/jpeg'];
const MIN_WIDTH = 256;
const RECOMMENDED_WIDTH = 512;
const MAX_WIDTH = 4096;
const MAX_FILE_SIZE = 1024 * 1024 * 4; // 4 mb

type UpdateProfileProps = {
  isOpen?: boolean;
  user?: SuperfeelUser;
  onClose?: () => void;
};

export function UpdateProfilePictureModal({
  isOpen,
  user,
  onClose,
}: UpdateProfileProps) {
  const [updateUser] = useAdminUpdateUserMutation();
  const { showSnackbar } = useSnackbar();
  const [s3Key, setS3Key] = useState('');
  const [signedUploadUrl, setSignedUploadUrl] = useState('');
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [fileDimensions, setFileDimensions] = useState({ height: 0, width: 0 });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const fileSizeDisplay = useMemo(() => {
    if (!uploadedFile?.size) {
      return '';
    }
    const { size } = uploadedFile;
    return formatFileSize(size);
  }, [uploadedFile]);

  const currentProfilePicture = useMemo(
    () =>
      uploadedImageUrl ||
      user?.profilePictureUri1024 ||
      user?.profilePictureUri512 ||
      user?.profilePictureUri,
    [
      uploadedImageUrl,
      user?.profilePictureUri,
      user?.profilePictureUri1024,
      user?.profilePictureUri512,
    ],
  );

  const resetUploadState = useCallback(() => {
    setUploadedImageUrl('');
    setUploadedFile(undefined);
    setFileDimensions({ height: 0, width: 0 });
    setMessage('');
    setIsError(false);
    setSignedUploadUrl('');
    setS3Key('');
  }, []);

  const handleUploadProcess = useCallback(
    async (key: string) => {
      setUpdating(true);
      try {
        if (key && signedUploadUrl && uploadedFile) {
          await fetch(signedUploadUrl, {
            method: 'PUT',
            body: uploadedFile,
            headers: { 'Content-Type': uploadedFile.type },
          });
        }
        const input: AdminUpdateUserInput = {
          action: 'UPDATE',
          user: {
            email: user.email,
            firstName: user.firstName,
            isFlagged: user.isFlagged,
            isVirtual: user.isVirtual,
            lastName: user.lastName,
            membershipType: user.membership?.type,
            phone: user.phone,
            userId: user.userId,
            username: user.username,
            profilePictureUri: key,
          },
        };
        await updateUser({ input }).unwrap();
        resetUploadState();
        showSnackbar(
          `Profile picture ${key ? 'updated' : 'deleted'}`,
          'success',
        );
      } catch (err) {
        showSnackbar('Error updating profile picture', 'error');
      } finally {
        setUpdating(false);
      }
    },
    [
      resetUploadState,
      showSnackbar,
      signedUploadUrl,
      updateUser,
      uploadedFile,
      user,
    ],
  );

  const getSignedUploadUrl = useCallback(
    async (file: File) => {
      if (s3Key) {
        return;
      }
      const extension = file.type.includes('png') ? 'png' : 'jpg';
      const key = `${user.username}-${Date.now()}.${extension}`;
      setS3Key(key);
      const { url } = await getSignedS3Url({
        key,
        type: 'PROFILE',
        expiry: 60 * 60 * 24,
        method: 'PUT',
        headers: JSON.stringify({
          'Content-Type': file.type,
        }),
      });
      setSignedUploadUrl(url);
    },
    [s3Key, user.username],
  );

  const handlePressEdit = useCallback(() => {
    inputRef?.current?.click();
  }, []);

  const validateImage = useCallback((img: HTMLImageElement) => {
    if (img.width < MIN_WIDTH || img.height < MIN_WIDTH) {
      setMessage('Image too small - must be at least 256 x 256');
      setIsError(true);
    } else if (
      img.width < RECOMMENDED_WIDTH ||
      img.height < RECOMMENDED_WIDTH
    ) {
      setMessage(
        'Images smaller than 512 x 512 may appear blurry in the HOURS app',
      );
      setIsError(false);
    } else if (img.width > MAX_WIDTH || img.height > MAX_WIDTH) {
      setMessage('Image too large - must be smaller than 4096 x 4096');
      setIsError(true);
    }
  }, []);

  const handleImageSelect = useCallback(
    (files: FileList) => {
      const file = files.item(0);
      if (!file) {
        return;
      }
      if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
        showSnackbar('Invalid file type', 'error');
      }
      setUploadedFile(file);
      if (file.size > MAX_FILE_SIZE) {
        setMessage('Image too large - must be less than 4MB');
        setIsError(true);
      }
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          setFileDimensions({
            width: img.width,
            height: img.height,
          });
          validateImage(img);
        };
        img.src = e.target?.result as string;
        setUploadedImageUrl(img.src);
        getSignedUploadUrl(file);
      };

      reader.readAsDataURL(file); // Convert file to a Data URL
    },
    [getSignedUploadUrl, showSnackbar, validateImage],
  );

  const handleClose = useCallback(() => {
    if (isUpdating) {
      return;
    }
    resetUploadState();
    onClose();
  }, [isUpdating, onClose, resetUploadState]);

  return (
    <>
      <Modal
        title="Update Profile Picture"
        isOpen={isOpen}
        onClose={handleClose}
        disableBackgroundDismiss
      >
        {currentProfilePicture ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <img
              src={currentProfilePicture}
              alt="Profile"
              style={{
                borderRadius: 10,
                maxHeight: '50vh',
                maxWidth: '50vw',
                height: 'auto',
                width: 'auto',
                padding: 5,
              }}
            />
            {!!uploadedFile && (
              <Typography textOverflow="ellipsis">
                {uploadedFile.name}
              </Typography>
            )}
            {!!fileSizeDisplay && <Typography>{fileSizeDisplay}</Typography>}
            {!!fileDimensions.height && !!fileDimensions.width && (
              <Typography>
                {fileDimensions.width} x {fileDimensions.height}
              </Typography>
            )}
            {!!message && (
              <Typography
                color={isError ? 'red' : undefined}
                fontWeight={600}
              >
                {message}
              </Typography>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1,
                padding: 2,
              }}
            >
              <input
                type="file"
                accept={ACCEPTED_MIME_TYPES.join(',')}
                id="input-file-upload"
                ref={inputRef}
                onChange={(ev) => handleImageSelect(ev.target.files)}
                style={{ opacity: 0, height: 0, width: 0 }}
              />
              {uploadedImageUrl ? (
                <>
                  <Tooltip title="Confirm">
                    <IconButton
                      disabled={!signedUploadUrl || !s3Key}
                      onClick={() => {
                        handleUploadProcess(s3Key);
                      }}
                    >
                      {isUpdating ? <CircularProgress size={16} /> : <Check />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => {
                        resetUploadState();
                        showSnackbar('Uploaded image discarded', 'success');
                      }}
                      disabled={isUpdating}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Update">
                    <IconButton onClick={handlePressEdit}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Delete"
                    onClick={() => setShowConfirmDelete(true)}
                  >
                    <IconButton>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Dropzone
            label="Drag image here"
            acceptFileTypes={['image/png', 'image/jpeg']}
            sx={{ height: 200, width: 200 }}
            onFilesChange={handleImageSelect}
          />
        )}
      </Modal>
      <ConfirmDialog
        isShown={showConfirmDelete}
        title="Delete profile picture?"
        message="This is irreversible and cannot be undone"
        onClose={() => setShowConfirmDelete(false)}
        onReject={() => setShowConfirmDelete(false)}
        onConfirm={() => {
          setShowConfirmDelete(false);
          handleUploadProcess('');
        }}
      />
    </>
  );
}
