import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { Interview, SuperfeelUser } from '@superfeel/types';
import { formatDate, formatFileSize } from 'common/utils';
import { useAdminSearchTwinJobsQuery } from 'routes/user/api/user';
import { useCallback, useState } from 'react';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { ProgressTracker } from '../collectionCard';
import { ProgressStepper } from './ProgressStepper';
import { InterviewSamplesModal } from '../interviewSamplesModal';
import { InterviewActionButtons } from './InterviewActionButtons';

interface InterviewCardProps {
  progressTracker: ProgressTracker | null;
  interview: Interview;
  targetUser?: SuperfeelUser;
}

export function InterviewCard({
  progressTracker,
  interview,
  targetUser,
}: InterviewCardProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: jobData,
    isError: isJobDataError,
    isLoading: isJobDataLoading,
  } = useAdminSearchTwinJobsQuery(
    {
      input: {
        parentFileId: interview.interviewId,
        limit: 10,
        targetUserId: targetUser?.userId,
        sortDirection: 'DESC',
      },
    },
    {
      skip:
        !interview.interviewId ||
        !targetUser?.userId ||
        interview?.processingStage === 'GRAPH', // no need to load job as status is present as ingestion state
    },
  );

  const openSamplesModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  return (
    <Card sx={{ position: 'relative' }}>
      <Box
        sx={{
          filter:
            progressTracker?.[interview?.interviewId] !== undefined
              ? 'blur(2px)'
              : 'none',
          transition: 'filter 0.2s ease',
        }}
      >
        <CardHeader
          sx={{
            minHeight: 100,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            overflow: 'hidden',
          }}
          title={interview.fileName || 'Untitled File'}
          subheader={
            <Typography variant="caption">
              Updated: {formatDate(interview.lastUpdated)}
            </Typography>
          }
          action={
            interview.processingStage === 'GRAPH' && (
              <ButtonIconTooltip
                icon="recordvoiceover"
                onClick={() => setIsModalOpen(true)}
                tooltipTitle="Review voice samples"
                tooltipColorVariant="info"
              />
            )
          }
        />
        <CardContent>
          <ProgressStepper
            interview={interview}
            isJobStatusError={isJobDataError}
            isLoadingJobStatus={isJobDataLoading}
            relatedJobs={jobData?.searchTwinJobs || []}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
            }}
          >
            <InterviewActionButtons
              interview={interview}
              jobs={jobData?.searchTwinJobs || []}
              openSamplesModal={openSamplesModal}
            />
          </Box>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {formatFileSize(interview.fileSize || 0) || 'Unknown'}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Created: {formatDate(interview.dateCreated)}
            </Typography>
          </Stack>
        </CardContent>
      </Box>

      {progressTracker?.[interview?.interviewId] !== undefined && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            zIndex: 1,
          }}
        >
          <Box sx={{ width: '80%' }}>
            <Typography
              variant="body2"
              color="primary"
              textAlign="center"
              mb={1}
            >
              {progressTracker[interview?.interviewId].status}
              {progressTracker[interview?.interviewId].progress > 0 && (
                <>
                  :{' '}
                  {progressTracker[interview?.interviewId].progress?.toFixed(2)}
                  %
                </>
              )}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progressTracker[interview?.interviewId].progress}
              sx={{
                height: 4,
                borderRadius: 1,
              }}
            />
          </Box>
        </Box>
      )}
      <InterviewSamplesModal
        interview={interview}
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        user={targetUser}
      />
    </Card>
  );
}
