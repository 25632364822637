import { useState, useEffect, useCallback } from 'react';

export function useGetDocument(contentUri: string) {
  const [content, setContent] = useState<string | null>(null);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchDocumentContent = useCallback(async () => {
    setisLoading(true);
    setError(null);
    try {
      const response = await fetch(contentUri);
      if (!response.ok) {
        throw new Error(`Failed to fetch document. Status: ${response.status}`);
      }
      const text = await response.text();
      setContent(text);
    } catch (err) {
      setError((err as Error).message);
      setContent(null);
    } finally {
      setisLoading(false);
    }
  }, [contentUri]);

  useEffect(() => {
    if (contentUri) {
      fetchDocumentContent();
    }
  }, [contentUri, fetchDocumentContent]);

  return { content, isLoading, error, refetch: fetchDocumentContent };
}
