import {
  AdminGetTwinDigestInput,
  AdminSearchSamplesInput,
  AdminSearchTwinMediaInput,
  AdminStartInterviewProcessInput,
  AdminUpdatePromptInput,
  AdminUpdateTwinJobInput,
  InterviewSegment,
  SearchTwinJobsInput,
  TwinDocument,
  TwinGraphDigest,
  TwinJob,
  TwinMedia,
  UpdateInterviewSegmentsInput,
} from '@superfeel/types';
import {
  AdminCreateUserDocument,
  AdminCreateUserMutation,
  AdminCreateUserMutationVariables,
  AdminUpdateUserDocument,
  AdminGetUserDocument,
  AdminSearchUsersDocument,
  SearchInterviewCollectionsDocument,
  api as generatedApi,
  UpdateInterviewCollectionDocument,
  TwinModelConfig,
  AdminUpdateTwinModelConfigInput,
  AdminUpdateTwinModelConfigDocument,
  AdminSearchTwinModelConfigsInput,
  AdminSearchTwinModelConfigsDocument,
  UpdateInterviewDocument,
  AdminGetSignedS3UrlDocument,
  CompleteFileUploadDocument,
  AdminUpdateTwinVoiceSampleInput,
  AdminUpdateVoiceSampleDocument,
  AdminUpdateTwinVoiceDocument,
  TwinModelTtsConfig,
  AdminUpdateTwinVoiceInput,
  CreateRoomResult,
  InterviewCollection,
  UpdateInterviewCollectionInput,
  UpdateInterviewInput,
  AdminGetSignedS3UrlInput,
  Interview,
  AdminGetUserResponse,
  AdminSearchUsersResponse,
  AdminSearchUsersInput,
  SuperfeelUser,
  AdminUpdateUserInput,
  SearchInterviewCollectionsInput,
  UploadUrl,
  FileUpload,
  CompleteFileUploadInput,
  AdminCreateRoomDocument,
  GenericResponse,
  AdminInviteTwinToRoomInput,
  AdminInviteTwinToRoomDocument,
  AdminSearchTwinVoicesInput,
  AminSearchTwinVoicesDocument,
  AdminSearchTwinMediaDocument,
  TwinPromptConfig,
  AdminSearchTwinPromptInput,
  AdminSearchTwinPromptDocument,
  AdminUpdateTwinPromptDocument,
  AdminGetTwinGraphDigestDocument,
  AdminSearchSamplesDocument,
  AdminStartInterviewProcessDocument,
  AdminUpdateTwinJobDocument,
  AdminSearchTwinJobsDocument,
  AdminSearchTwinDocumentsDocument,
  AdminSearchTwinDocumentsInput,
  AdminUpdateTwinDocumentDocument,
  AdminUpdateTwinDocumentInput,
  UpdateInterviewSegmentsDocument,
} from 'routes/graphql.generated';

export const usersApi = generatedApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Queries
     */
    AdminSearchUsers: build.query<
      { adminSearchUsers: AdminSearchUsersResponse },
      AdminSearchUsersInput
    >({
      query: (variables) => ({
        document: AdminSearchUsersDocument,
        variables,
      }),
      providesTags: ['Users'],
    }),

    AdminGetUser: build.query<
      { adminGetUser: AdminGetUserResponse },
      { input: { username: string } }
    >({
      query: (variables) => ({
        document: AdminGetUserDocument,
        variables,
      }),
      providesTags: (_result, _error, arg) => [
        {
          type: 'User',
          id: `AdminGetUser({"input":{"username":"${arg.input.username}"}})`,
        },
      ],
    }),

    SearchInterviewCollections: build.query<
      { searchInterviewCollections?: Array<InterviewCollection> },
      { input: SearchInterviewCollectionsInput }
    >({
      query: (variables) => ({
        document: SearchInterviewCollectionsDocument,
        variables,
      }),
      providesTags: ['InterviewCollections'],
    }),

    SearchAdminTwinConfigs: build.query<
      { adminSearchTwinModelConfigs?: Array<TwinModelConfig> },
      { input: AdminSearchTwinModelConfigsInput }
    >({
      query: (variables) => ({
        document: AdminSearchTwinModelConfigsDocument,
        variables,
      }),
      providesTags: ['TwinConfigs'],
    }),

    SearchAdminTwinVoices: build.query<
      { adminSearchTwinVoices?: Array<TwinModelTtsConfig> },
      { input: AdminSearchTwinVoicesInput }
    >({
      query: (variables) => ({
        document: AminSearchTwinVoicesDocument,
        variables,
      }),
      providesTags: ['Voices'],
    }),

    AdminSearchTwinMedia: build.query<
      { adminSearchTwinMedia?: Array<TwinMedia> },
      { input: AdminSearchTwinMediaInput }
    >({
      query: (variables) => ({
        document: AdminSearchTwinMediaDocument,
        variables,
      }),
    }),

    AdminGetSignedS3Url: build.query<
      { adminGetSignedS3Url: UploadUrl },
      { input: AdminGetSignedS3UrlInput }
    >({
      query: (variables) => ({
        document: AdminGetSignedS3UrlDocument,
        variables,
      }),
    }),

    AdminSearchTwinPrompt: build.query<
      { adminSearchTwinPrompt: TwinPromptConfig[] },
      { input: AdminSearchTwinPromptInput }
    >({
      query: (variables) => ({
        document: AdminSearchTwinPromptDocument,
        variables,
      }),
      providesTags: ['TwinPrompts'],
    }),

    AdminGetTwinGraphDigest: build.query<
      { adminGetTwinGraphDigest: TwinGraphDigest },
      { input: AdminGetTwinDigestInput }
    >({
      query: (variables) => ({
        document: AdminGetTwinGraphDigestDocument,
        variables,
      }),
    }),

    AdminSearchTwinJobs: build.query<
      { searchTwinJobs: TwinJob[] },
      { input: SearchTwinJobsInput }
    >({
      query: (variables) => ({
        document: AdminSearchTwinJobsDocument,
        variables,
      }),
      providesTags: ['TwinJobs'],
    }),
    AdminSearchTwinDocuments: build.query<
      { adminSearchTwinDocuments: TwinDocument[] },
      { input: AdminSearchTwinDocumentsInput }
    >({
      query: (variables) => ({
        document: AdminSearchTwinDocumentsDocument,
        variables,
      }),
      providesTags: ['TwinDocuments'],
    }),

    AdminSearchSamples: build.query<
      { adminSearchSamples: InterviewSegment[] },
      { input: AdminSearchSamplesInput }
    >({
      query: (variables) => ({
        document: AdminSearchSamplesDocument,
        variables,
      }),
      providesTags: ['InterviewCollections', 'InterviewSegments'],
    }),

    /**
     * Mutations
     */
    AdminCreateUser: build.mutation<
      AdminCreateUserMutation,
      AdminCreateUserMutationVariables
    >({
      query: (variables) => ({
        document: AdminCreateUserDocument,
        variables,
      }),
      invalidatesTags: ['Users'],
    }),

    AdminUpdateUser: build.mutation<
      SuperfeelUser,
      { input: AdminUpdateUserInput }
    >({
      query: (variables) => ({
        document: AdminUpdateUserDocument,
        variables,
      }),
      invalidatesTags: (_result, _error, { input }) => [
        {
          type: 'User',
          id: `AdminGetUser({"input":{"username":"${input.user.username}"}})`,
        },
      ],
    }),

    UpdateInterviewCollection: build.mutation<
      InterviewCollection,
      { input: UpdateInterviewCollectionInput }
    >({
      query: (variables) => ({
        document: UpdateInterviewCollectionDocument,
        variables,
      }),
      invalidatesTags: ['InterviewCollections'],
    }),

    AdminUpdateTwinConfig: build.mutation<
      TwinModelConfig,
      { input: AdminUpdateTwinModelConfigInput }
    >({
      query: (variables) => ({
        document: AdminUpdateTwinModelConfigDocument,
        variables,
      }),
      invalidatesTags: ['TwinConfigs', 'TwinPrompts', 'Voices'],
    }),

    UpdateInterview: build.mutation<Interview, { input: UpdateInterviewInput }>(
      {
        query: (variables) => ({
          document: UpdateInterviewDocument,
          variables,
        }),
        transformResponse: (response: { updateInterview: Interview }) =>
          response.updateInterview,
        invalidatesTags: ['InterviewCollections'],
      },
    ),

    CompleteFileUpload: build.mutation<
      { completeFileUpload: FileUpload },
      { input: CompleteFileUploadInput }
    >({
      query: (variables) => ({
        document: CompleteFileUploadDocument,
        variables,
      }),
      invalidatesTags: ['InterviewCollections'],
    }),

    AdminUpdateVoiceSample: build.mutation<
      { adminUpdateTwinVoiceSample: TwinModelTtsConfig },
      { input: AdminUpdateTwinVoiceSampleInput }
    >({
      query: (variables) => ({
        document: AdminUpdateVoiceSampleDocument,
        variables,
      }),
      invalidatesTags: ['Voices', 'TwinConfigs'],
    }),

    AdminUpdateTwinVoice: build.mutation<
      { adminUpdateTwinVoice: TwinModelTtsConfig },
      { input: AdminUpdateTwinVoiceInput }
    >({
      query: (variables) => ({
        document: AdminUpdateTwinVoiceDocument,
        variables,
      }),
      invalidatesTags: ['Voices', 'TwinConfigs'],
    }),

    AdminCreateRoom: build.mutation<
      { adminCreateRoom: CreateRoomResult },
      undefined
    >({
      query: () => ({
        document: AdminCreateRoomDocument,
      }),
    }),

    AdminInviteTwinToRoom: build.mutation<
      { adminInviteTwinToRoom: GenericResponse },
      { input: AdminInviteTwinToRoomInput }
    >({
      query: (variables) => ({
        document: AdminInviteTwinToRoomDocument,
        variables,
      }),
    }),

    AdminUpdateTwinPrompt: build.mutation<
      { adminUpdateTwinPrompt: TwinPromptConfig },
      { input: AdminUpdatePromptInput }
    >({
      query: (variables) => ({
        document: AdminUpdateTwinPromptDocument,
        variables,
      }),
      invalidatesTags: ['TwinPrompts', 'TwinConfigs'],
    }),

    AdminStartInterviewProcess: build.mutation<
      { adminStartInterviewProcess: TwinJob },
      { input: AdminStartInterviewProcessInput }
    >({
      query: (variables) => ({
        document: AdminStartInterviewProcessDocument,
        variables,
      }),
      invalidatesTags: ['InterviewCollections', 'TwinJobs'],
    }),

    AdminUpdateTwinJob: build.mutation<
      { adminUpdateTwinJob: TwinJob[] },
      { input: AdminUpdateTwinJobInput }
    >({
      query: (variables) => ({
        document: AdminUpdateTwinJobDocument,
        variables,
      }),
      invalidatesTags: ['TwinDocuments', 'InterviewCollections', 'TwinJobs'],
    }),
    AdminUpdateTwinDocument: build.mutation<
      { adminUpdateTwinDocument: TwinDocument },
      { input: AdminUpdateTwinDocumentInput }
    >({
      query: (variables) => ({
        document: AdminUpdateTwinDocumentDocument,
        variables,
      }),
      invalidatesTags: ['TwinDocuments'],
    }),

    UpdateInterviewSegments: build.mutation<
      { updateInterviewSegments: InterviewSegment[] },
      { input: UpdateInterviewSegmentsInput }
    >({
      query: (variables) => ({
        document: UpdateInterviewSegmentsDocument,
        variables,
      }),
      invalidatesTags: ['InterviewCollections', 'InterviewSegments'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useAdminSearchUsersQuery,
  useAdminCreateUserMutation,
  useAdminGetUserQuery,
  useAdminSearchTwinMediaQuery,
  useAdminUpdateUserMutation,
  useUpdateInterviewCollectionMutation,
  useSearchInterviewCollectionsQuery,
  useAdminUpdateTwinConfigMutation,
  useSearchAdminTwinConfigsQuery,
  useAdminSearchSamplesQuery,
  useAdminSearchTwinJobsQuery,
  useUpdateInterviewMutation,
  useAdminGetSignedS3UrlQuery,
  useAdminUpdateVoiceSampleMutation,
  useAdminUpdateTwinVoiceMutation,
  useAdminUpdateTwinJobMutation,
  useUpdateInterviewSegmentsMutation,
  useAdminCreateRoomMutation,
  useAdminInviteTwinToRoomMutation,
  useAdminUpdateTwinPromptMutation,
  useAdminStartInterviewProcessMutation,
  useAdminSearchTwinPromptQuery,
  useSearchAdminTwinVoicesQuery,
  useAdminSearchTwinDocumentsQuery,
  useAdminUpdateTwinDocumentMutation,
} = usersApi;
