/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Switch, Typography } from '@mui/material';
import AutoAwesome from '@mui/icons-material/AutoAwesome';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  AdminUpdateTwinPromptInput,
  SuperfeelUser,
  TwinPromptConfig,
} from 'routes/graphql.generated';
import { useAdminUpdateTwinPromptMutation } from 'routes/user/api/user';
import * as Yup from 'yup';
import { useSnackbar } from 'state/context/snackBar';
import { TextField } from 'components/atoms';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { nameStandardiser } from 'utils/nameStandardiser';
import { DateTime } from 'luxon';
import getTwinGraphDigest from '../utils/getTwinGraphDigest';

type AutogeneratePromptMode =
  | 'ALL'
  | 'PERSONAL_BACKGROUND'
  | 'CONVERSATIONAL_STYLE'
  | 'PERSONALITY_TRAITS';

interface EditPromptFormValues {
  conversationalStyle?: string;
  customValue?: string;
  namePronunciation?: string;
  personalBackground?: string;
  personalityTraits?: string;
  promptOverride?: string;
  startPhrase?: string;
  configName: string;
  overrideActive: boolean;
}

const formSections: Array<{
  fieldName: keyof EditPromptFormValues;
  label: string;
  autogenerateMode?: AutogeneratePromptMode;
  tooltip: string;
  rows: number;
  maxLength: number;
  requirementState: 'ALWAYS' | 'OVERRIDE_ON' | 'OVERRIDE_OFF' | 'NEVER';
}> = [
  {
    fieldName: 'configName',
    label: 'Config Name',
    tooltip:
      'A label for this configuration to be identifiable. Has no impact on prompt.',
    rows: 1,
    maxLength: 30,
    requirementState: 'ALWAYS',
  },
  {
    fieldName: 'startPhrase',
    label: 'Start Phrase',
    tooltip: 'How the Heir should open the conversation',
    rows: 1,
    maxLength: 125,
    requirementState: 'OVERRIDE_OFF',
  },
  {
    fieldName: 'namePronunciation',
    label: 'Name Pronunciation',
    tooltip: "How the Heir's name should be pronounced phonetically (optional)",
    rows: 1,
    maxLength: 30,
    requirementState: 'NEVER',
  },
  {
    fieldName: 'personalBackground',
    label: 'Personal Background',
    autogenerateMode: 'PERSONAL_BACKGROUND',
    tooltip:
      'Significant past events the Heir should draw upon to use in their answers',
    rows: 6,
    maxLength: 2500,
    requirementState: 'OVERRIDE_OFF',
  },
  {
    fieldName: 'personalityTraits',
    label: 'Personality Traits',
    autogenerateMode: 'PERSONALITY_TRAITS',
    tooltip:
      'Descriptions to help the Heir better embody the personality of the individual',
    rows: 6,
    maxLength: 2500,
    requirementState: 'OVERRIDE_OFF',
  },
  {
    fieldName: 'conversationalStyle',
    label: 'Conversational Style',
    autogenerateMode: 'CONVERSATIONAL_STYLE',
    tooltip:
      'Descriptions to help the Heir construct responses that represent the style of the individual',
    rows: 6,
    maxLength: 2500,
    requirementState: 'OVERRIDE_OFF',
  },
  {
    fieldName: 'customValue',
    label: 'Custom Text',
    tooltip:
      'Additional text that will be added to the end of the prompt (optional)',
    rows: 6,
    maxLength: 2500,
    requirementState: 'NEVER',
  },
  {
    fieldName: 'promptOverride',
    label: 'Custom System Prompt',
    tooltip:
      'System prompt that will be provided to the Heir. It will override all other values',
    rows: 15,
    maxLength: 25000,
    requirementState: 'OVERRIDE_ON',
  },
];

const validationSchema = Yup.object({
  customValue: Yup.string().nullable().notRequired(),
  startPhrase: Yup.string().when('overrideActive', {
    is: false,
    then: (schema) => schema.required('Start Phrase is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  namePronunciation: Yup.string().nullable().notRequired(),
  personalBackground: Yup.string().when('overrideActive', {
    is: false,
    then: (schema) =>
      schema.required('Personal Background is required').min(50, 'Too short'),
    otherwise: (schema) => schema.nullable(),
  }),
  personalityTraits: Yup.string().when('overrideActive', {
    is: false,
    then: (schema) =>
      schema.required('Personality Traits is required').min(50, 'Too short'),
    otherwise: (schema) => schema.nullable(),
  }),
  conversationalStyle: Yup.string().when('overrideActive', {
    is: false,
    then: (schema) =>
      schema.required('Conversational Style is required').min(50, 'Too short'),
    otherwise: (schema) => schema.nullable(),
  }),
  promptOverride: Yup.string().when('overrideActive', {
    is: true,
    then: (schema) => schema.required('Prompt Override is required'),
    otherwise: (schema) => schema.nullable(),
  }),
  configName: Yup.string().required('Config name is required'),
  overrideActive: Yup.boolean().required(),
});

interface EditPropmptFormProps {
  targetUser: SuperfeelUser;
  closeModal: () => void;
  initialConfig?: TwinPromptConfig;
}

export default function EditPromptForm({
  targetUser,
  initialConfig,
  closeModal,
}: EditPropmptFormProps) {
  const { showSnackbar } = useSnackbar();
  const [updateTwinPrompt, { isLoading }] = useAdminUpdateTwinPromptMutation();

  const [loadingMap, setLoadingMap] = useState<
    Record<AutogeneratePromptMode, boolean>
  >({
    ALL: false,
    CONVERSATIONAL_STYLE: false,
    PERSONAL_BACKGROUND: false,
    PERSONALITY_TRAITS: false,
  });

  const isAutogeneratingPrompt = useMemo(
    () => !!Object.values(loadingMap).filter(Boolean).length,
    [loadingMap],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields, defaultValues, isDirty },
    reset,
    watch,
    setValue,
    resetField,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      conversationalStyle: initialConfig?.conversationalStyle || '',
      customValue: initialConfig?.customValue || '',
      namePronunciation: initialConfig?.namePronunciation || '',
      personalBackground: initialConfig?.personalBackground || '',
      personalityTraits: initialConfig?.personalityTraits || '',
      promptOverride: initialConfig?.promptOverride || '',
      startPhrase:
        initialConfig?.startPhrase ||
        `Hi there, I'm ${nameStandardiser(targetUser, 'SINGLE_NAME')}. How are you today?`,
      configName:
        initialConfig?.configName ||
        `${nameStandardiser(targetUser, 'SINGLE_NAME')} New Prompt ${DateTime.now().toISODate()}`,
      overrideActive: initialConfig?.overrideActive || false,
    },
    mode: 'onChange',
  });
  const isOverrideActive = watch('overrideActive');

  const onSubmit = useCallback(
    (data: EditPromptFormValues) => {
      const input: AdminUpdateTwinPromptInput = {
        action: initialConfig ? 'UPDATE' : 'CREATE',
        prompt: {
          conversationalStyle: data.conversationalStyle?.trim() || '',
          customValue: data.customValue?.trim() || '',
          namePronunciation: data.namePronunciation?.trim() || '',
          personalBackground: data.personalBackground?.trim() || '',
          personalityTraits: data.personalityTraits?.trim() || '',
          startPhrase: data.startPhrase?.trim() || '',
          targetUserId: targetUser.userId,
          promptConfigId: initialConfig ? initialConfig.promptConfigId : '',
          promptOverride: data.promptOverride?.trim() || '',
          overrideActive: data.overrideActive || false,
          configName: data.configName?.trim() || '',
        },
      };

      updateTwinPrompt({
        input,
      })
        .unwrap()
        .then((res) => {
          if (!res.adminUpdateTwinPrompt)
            throw new Error('Failed to create/update prompt configuration.');
          showSnackbar('Prompt updated', 'success');
          closeModal();
        })
        .catch((err: unknown) => {
          showSnackbar(
            `Error creating prompt configuration: ${(err as Error)?.message}`,
            'error',
          );
        });
    },
    [
      updateTwinPrompt,
      closeModal,
      initialConfig,
      showSnackbar,
      targetUser.userId,
    ],
  );

  const handleAutogeneratePrompt = useCallback(
    (mode: AutogeneratePromptMode) => {
      setLoadingMap((prev) => ({ ...prev, [mode]: true }));
      getTwinGraphDigest(targetUser.userId, mode)
        .then((res) => {
          const { conversationalStyle, keyExperiences, personalitySummary } =
            res;
          if (conversationalStyle) {
            setValue('conversationalStyle', conversationalStyle, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }
          if (keyExperiences) {
            setValue('personalBackground', keyExperiences, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }
          if (personalitySummary) {
            setValue('personalityTraits', personalitySummary, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            });
          }
          const promptTypeLabel = mode.toLowerCase().replaceAll('_', ' ');
          const message = `${promptTypeLabel} prompt${mode === 'ALL' ? 's' : ''} updated`;
          const capitalisedMessage =
            message[0].toUpperCase() + message.slice(1);
          showSnackbar(capitalisedMessage, 'success');
        })
        .catch((err) => {
          showSnackbar((err as Error)?.message || 'Unknown error', 'error');
        })
        .finally(() => {
          setLoadingMap((prev) => ({ ...prev, [mode]: false }));
        });
    },
    [setValue, showSnackbar, targetUser.userId],
  );

  return (
    <Box
      component="form"
      // NOTE: Wierd situation where when you fix the lint issue, it just complains about the opposite issue.
      // E.g you allow onSubmit to return a promise, compalins that it expects a void return.
      // Void return, complains that it needs a promise.
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gap={3}
      pl={2}
      pr={1}
      borderRadius={2}
      minWidth={400}
      mx="auto"
      mt={5}
      boxShadow={24}
    >
      {formSections.map(
        ({
          fieldName,
          label,
          rows,
          maxLength,
          tooltip,
          autogenerateMode,
          requirementState,
        }) =>
          !!(
            requirementState === 'ALWAYS' ||
            (isOverrideActive && requirementState === 'OVERRIDE_ON') ||
            (!isOverrideActive &&
              (requirementState === 'OVERRIDE_OFF' ||
                requirementState === 'NEVER'))
          ) && (
            <Controller
              key={fieldName}
              name={fieldName}
              control={control}
              render={({ field }) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <TextField
                    {...field}
                    label={label}
                    sx={{ flex: 1, pr: 1 }}
                    error={!!errors[fieldName]}
                    helperText={errors[fieldName]?.message}
                    maxLength={maxLength}
                    multiline={rows > 1}
                    rows={rows}
                    required={requirementState !== 'NEVER'}
                    disabled={
                      autogenerateMode &&
                      (loadingMap[autogenerateMode] || loadingMap.ALL)
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ButtonIconTooltip
                      icon="info"
                      onClick={() => {}}
                      isDisabled
                      isLoading={false}
                      tooltipTitle={tooltip}
                      tooltipColorVariant="info"
                      tooltipPlacement="right"
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                      }}
                    >
                      {!!defaultValues[fieldName] && rows > 1 && (
                        <ButtonIconTooltip
                          icon="undo"
                          onClick={() => resetField(fieldName)}
                          isDisabled={
                            !dirtyFields[fieldName] ||
                            loadingMap[autogenerateMode] ||
                            loadingMap.ALL
                          }
                          isLoading={false}
                          tooltipTitle={
                            dirtyFields[fieldName]
                              ? 'Undo all changes'
                              : 'No changes to undo'
                          }
                          tooltipColorVariant={
                            dirtyFields[fieldName] ? 'info' : 'primary'
                          }
                          tooltipPlacement="right"
                        />
                      )}
                      {autogenerateMode && (
                        <ButtonIconTooltip
                          icon="sparkle"
                          onClick={() =>
                            handleAutogeneratePrompt(autogenerateMode)
                          }
                          isLoading={
                            loadingMap[autogenerateMode] || loadingMap.ALL
                          }
                          tooltipTitle="Autogenerate from graph"
                          tooltipColorVariant="secondary"
                          tooltipPlacement="right"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            />
          ),
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography>I want to write my own custom prompt</Typography>
        <Controller
          name="overrideActive"
          control={control}
          render={({ field }) => (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Switch
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                color="warning"
              />
              <ButtonIconTooltip
                icon="warning"
                onClick={() => {}}
                isDisabled
                isLoading={false}
                tooltipTitle="CAUTION - Only use this option if you know what you're doing"
                tooltipColorVariant="warning"
              />
            </Box>
          )}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        gap={2}
        pr={5}
        pb={2}
      >
        <LoadingButton
          variant="contained"
          loading={loadingMap.ALL}
          disabled={isAutogeneratingPrompt}
          onClick={() => handleAutogeneratePrompt('ALL')}
        >
          Autogenerate all <AutoAwesome sx={{ pl: 1 }} />
        </LoadingButton>
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              reset();
              closeModal();
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            type="submit"
            variant="contained"
            disabled={!isValid || !isDirty || isAutogeneratingPrompt}
          >
            {initialConfig ? 'Update Prompt' : 'Create Prompt'}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}
