import { AdminGetTwinDigestInput, TwinGraphDigest } from '@superfeel/types';
import { usersApi } from 'routes/user/api/user';
import { store } from 'store';

export default async function getTwinGraphDigest(
  targetUserId: string,
  mode:
    | 'ALL'
    | 'PERSONAL_BACKGROUND'
    | 'CONVERSATIONAL_STYLE'
    | 'PERSONALITY_TRAITS',
) {
  const input: AdminGetTwinDigestInput = {
    targetUserId,
  };
  if (mode === 'ALL') {
    input.getConversationalStyle = true;
    input.getKeyExperiences = true;
    input.getPersonality = true;
  } else if (mode === 'PERSONAL_BACKGROUND') {
    input.getKeyExperiences = true;
  } else if (mode === 'CONVERSATIONAL_STYLE') {
    input.getConversationalStyle = true;
  } else if (mode === 'PERSONALITY_TRAITS') {
    input.getPersonality = true;
  }

  const { data, error, isError } = await store.dispatch(
    usersApi.endpoints.AdminGetTwinGraphDigest.initiate(
      { input },
      { forceRefetch: true },
    ),
  );
  if (isError) {
    throw new Error(error.message || 'Unknown error');
  }

  return data.adminGetTwinGraphDigest as TwinGraphDigest;
}
