import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import Add from '@mui/icons-material/Add';
import { useCallback, useState } from 'react';
import UserView from './components/user-voice-view';
import VoiceModal from './components/voice-modal';
import VoiceEditModal from './components/voice-edit-modal';
import useGetTargetUser from '../hooks/useGetTargetUser';
import { useSearchAdminTwinVoicesQuery } from '../api/user';

const MAX_CONFIGS_PER_USER = 5;

export default function UserVoice() {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isVoiceModalOpen, setIsVoiceModalOpen] = useState(false);

  const { user } = useGetTargetUser();
  const { data } = useSearchAdminTwinVoicesQuery(
    {
      input: {
        targetUserId: user?.userId || '',
      },
    },
    { skip: !user?.userId },
  );

  const handleCloseModal = useCallback(() => {
    setIsVoiceModalOpen(false);
    setIsEditModalOpen(false);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
        >
          User Voices
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {data?.adminSearchTwinVoices?.length >= MAX_CONFIGS_PER_USER && (
            <Typography
              p={1}
              variant="body2"
              color="error"
            >
              Max {MAX_CONFIGS_PER_USER} voices per user
            </Typography>
          )}
          <Button
            startIcon={<Add />}
            size="medium"
            onClick={() => setIsVoiceModalOpen(true)}
            variant="contained"
            color="primary"
            label="Add voice"
            disabled={
              data?.adminSearchTwinVoices?.length >= MAX_CONFIGS_PER_USER
            }
          />
        </Box>
      </Box>
      <UserView />
      <VoiceModal
        isOpen={isVoiceModalOpen}
        closeModal={handleCloseModal}
        initialVoiceConfig={null}
      />
      <VoiceEditModal
        config={null}
        closeModal={handleCloseModal}
        isOpen={isEditModalOpen}
        targetUser={user}
      />
    </Box>
  );
}
