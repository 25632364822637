import { Provider as ReduxProvider } from 'react-redux';
import { Authenticator } from '@aws-amplify/ui-react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import {
  DailyAudio,
  DailyProvider,
  useCallObject,
} from '@daily-co/daily-react';
import { FC, useCallback } from 'react';
import { store } from 'store';
import { darkTheme } from './theme';
import { router } from './router';
import '@aws-amplify/ui-react/styles.css';
import ContextProvider from './state/context';

const App: FC = function App() {
  const callObject = useCallObject({
    options: { videoSource: false, audioSource: true, startAudioOff: false },
  });

  const customHeader = useCallback(
    () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px',
        }}
      >
        <img
          src="/img/hours-logo.png"
          alt="Logo"
          style={{ width: '100px' }}
        />
      </div>
    ),
    [],
  );

  return (
    <Authenticator
      hideSignUp
      components={{ Header: customHeader }}
      variation="modal"
    >
      <ThemeProvider theme={darkTheme}>
        <ReduxProvider store={store}>
          <ContextProvider>
            <DailyProvider callObject={callObject}>
              <DailyAudio />
              <RouterProvider router={router} />
            </DailyProvider>
          </ContextProvider>
        </ReduxProvider>
      </ThemeProvider>
    </Authenticator>
  );
};

export default App;
