import { getCurrentAuthCredentials } from '@api/auth/auth.api';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateRoomResult,
  GenericResponse,
  SuperfeelUser,
} from '@superfeel/types';
import { get, post, put, ApiError } from 'aws-amplify/api';

type RequestBody = Record<string, string | number | boolean>;

let cachedJWT: string | null = null;
let lastJWTUpdate = 0;
const JWT_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

const getCachedJwt = async (): Promise<string | null> => {
  const currentTime = Date.now();
  if (!cachedJWT || currentTime - lastJWTUpdate > JWT_CACHE_DURATION) {
    try {
      const authCredentials = await getCurrentAuthCredentials();
      cachedJWT = authCredentials.jwt;
      lastJWTUpdate = currentTime;
    } catch (err) {
      console.error('Failed to get authentication credentials:', err);
      cachedJWT = null;
    }
  }
  return cachedJWT;
};

async function getRequest<T>(
  path: string,
  queryParams: Record<string, string> = {},
): Promise<T> {
  const jwt = await getCachedJwt();
  const res = await get({
    apiName: 'main',
    path,
    options: { queryParams, headers: { Authorization: `Bearer ${jwt}` } },
  }).response;
  return res.body.json() as Promise<T>;
}

async function postRequest<ReqT extends RequestBody, ResT>(
  path: string,
  body: ReqT,
): Promise<ResT> {
  const jwt = await getCachedJwt();
  const res = await post({
    apiName: 'main',
    path,
    options: { body, headers: { Authorization: `Bearer ${jwt}` } },
  }).response;
  return res.body.json() as Promise<ResT>;
}

async function putRequest<ReqT extends RequestBody, ResT>(
  path: string,
  body: ReqT,
): Promise<ResT> {
  const jwt = await getCachedJwt();
  const res = await put({
    apiName: 'main',
    path,
    options: { body, headers: { Authorization: `Bearer ${jwt}` } },
  }).response;
  return res.body.json() as Promise<ResT>;
}

type AmplifyQueryArgs<ReqT extends RequestBody> = {
  path: string;
  method: 'GET' | 'PUT' | 'POST';
  body?: ReqT;
  queryParams?: Record<string, string>;
};

const amplifyBaseQuery: BaseQueryFn<AmplifyQueryArgs<RequestBody>> = async <
  T,
  U extends RequestBody,
>({
  path,
  method,
  body,
  queryParams,
}: AmplifyQueryArgs<U>) => {
  let response: T | undefined;

  try {
    if (method === 'GET') {
      response = await getRequest<T>(path, queryParams);
    } else if (method === 'PUT') {
      response = await putRequest<U, T>(path, body);
    } else if (method === 'POST') {
      response = await postRequest<U, T>(path, body);
    } else {
      throw new Error('Unsupported method');
    }
    return { data: response };
  } catch (err) {
    const error = err as ApiError;
    return {
      error: {
        status: error.response?.statusCode || 500,
        message: error.response?.body || error.message,
      },
    };
  }
};

export const restApiSlice = createApi({
  baseQuery: amplifyBaseQuery,
  endpoints: (builder) => ({
    GetTwinInfo: builder.query<SuperfeelUser, { deploymentId: string }>({
      query: (queryParams) => ({
        path: '/deployment/info',
        method: 'GET',
        queryParams,
      }),
    }),
    PutGuestUser: builder.mutation<SuperfeelUser, { deploymentId: string }>({
      query: (body) => ({
        path: '/user/guest',
        method: 'PUT',
        body,
      }),
    }),
    PostNewRoom: builder.mutation<CreateRoomResult, { deploymentId: string }>({
      query: (body) => ({
        path: '/deployment/room',
        method: 'POST',
        body,
      }),
    }),
    PostInviteTwin: builder.mutation<
      GenericResponse,
      { url: string; deploymentId: string }
    >({
      query: (body) => ({
        path: '/deployment/invite',
        method: 'POST',
        body,
      }),
    }),
  }),
  tagTypes: [],
});

export const {
  useGetTwinInfoQuery,
  usePutGuestUserMutation,
  usePostNewRoomMutation,
  usePostInviteTwinMutation,
} = restApiSlice;
