import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Card, Divider, IconButton, Typography } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useWavesurfer } from '@wavesurfer/react';
import { Loading, Modal } from 'components/atoms';
import {
  useAdminGetSignedS3UrlQuery,
  useAdminSearchSamplesQuery,
  useUpdateInterviewSegmentsMutation,
} from 'routes/user/api/user';
import {
  Interview,
  InterviewRole,
  InterviewSegment,
  SuperfeelUser,
} from '@superfeel/types';
import { useSnackbar } from 'state/context/snackBar';
import { SampleHeader } from '../sampleHeader';

interface InterviewSamplesModalProps {
  isOpen: boolean;
  closeModal: () => void;
  interview: Interview;
  user?: SuperfeelUser;
}

interface SamplePlayerProps {
  url: string;
  duration: number;
}

function SamplePlayer({ url, duration }: SamplePlayerProps) {
  const containerRef = useRef(null);
  const {
    data,
    isError,
    isLoading: isUrlLoading,
  } = useAdminGetSignedS3UrlQuery(
    {
      input: {
        key: url,
        type: 'INTERVIEW',
        headers: JSON.stringify({
          'Content-Type': 'audio/wav',
        }),
        method: 'GET',
        expiry: 60 * 60 * 24,
      },
    },
    {
      skip: !url,
    },
  );

  const { wavesurfer, isReady, isPlaying } = useWavesurfer({
    container: containerRef,
    url: data?.adminGetSignedS3Url?.url,
    waveColor: '#ffffff',
    progressColor: '#2196f3',
    normalize: true,
    minPxPerSec: 50,
    backend: 'WebAudio',
    mediaControls: false,
    duration,
    hideScrollbar: true,
    autoScroll: true,
    autoCenter: true,
    sampleRate: 8000,
    fillParent: true,
    barWidth: 2,
    height: 64,
    barGap: 1,
    barRadius: 8,
  });

  const onPlayPause = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (!isReady || isError || isUrlLoading) return;
      wavesurfer.playPause();
    },
    [isError, isReady, isUrlLoading, wavesurfer],
  );

  // stop playing audio on unmount
  useEffect(() => {
    return () => {
      wavesurfer?.stop();
    };
  }, [wavesurfer]);

  return (
    <Box sx={{ my: 2 }}>
      <Card sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <IconButton
            onClick={onPlayPause}
            disabled={!isReady || isError || isUrlLoading}
          >
            {isPlaying ? (
              <PauseCircleIcon fontSize="large" />
            ) : (
              <PlayCircleIcon fontSize="large" />
            )}
          </IconButton>

          <Box
            width="100%"
            height={64}
          >
            {isUrlLoading && <Loading minHeight="100px" />}
            {!isUrlLoading && !isReady && <Loading minHeight="100px" />}
            <div ref={containerRef} />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

function SampleItem({
  sample,
  interview,
  user,
}: {
  sample: InterviewSegment;
  interview: Interview;
  user?: SuperfeelUser;
}) {
  const [speakerRole, setSpeakerRole] = useState<InterviewRole>(
    sample.speakerRole,
  );
  const { showSnackbar } = useSnackbar();

  const [updateInterviewSegments, { isLoading: isUpdating }] =
    useUpdateInterviewSegmentsMutation();

  const handleSpeakerRoleUpdate = useCallback(
    async (event: React.MouseEvent<HTMLElement>, newRole: InterviewRole) => {
      event.preventDefault();
      if (isUpdating) return;

      try {
        setSpeakerRole(newRole);
        await updateInterviewSegments({
          input: {
            action: 'UPDATE',
            interviewSegment: {
              speakerRole: newRole,
              speakerLabel: sample.speakerLabel,
              interviewId: sample.parentInterviewId,
            },
          },
        });
        showSnackbar('Speaker successfully  updated', 'success');
      } catch (err) {
        showSnackbar(
          'Could not update speaker. Please try again later.',
          'error',
        );
        console.error(err);
      }
    },
    [
      isUpdating,
      sample.parentInterviewId,
      sample.speakerLabel,
      showSnackbar,
      updateInterviewSegments,
    ],
  );

  return (
    <Box sx={{ mb: 2 }}>
      <Card
        elevation={0}
        sx={{ bgcolor: 'transparent', border: 'none' }}
      >
        <SampleHeader
          sample={sample}
          speakerRole={speakerRole}
          handleSpeakerRoleChange={(e, role) => {
            handleSpeakerRoleUpdate(e, role);
          }}
          isLoading={isUpdating}
          isDisabled={
            interview.processingStage === 'GRAPH' &&
            interview.twinGraphIngestionStatus !== 'NOT_STARTED'
          }
          user={user}
        />
        <SamplePlayer
          url={sample.s3Key}
          duration={sample.endTimeSecs - sample.startTimeSecs}
        />
        <Typography
          color="lightgray"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          variant="body2"
        >
          {sample.transcription}
        </Typography>
      </Card>
    </Box>
  );
}

export function InterviewSamplesModal({
  isOpen,
  closeModal,
  interview,
  user,
}: InterviewSamplesModalProps) {
  const { data, isLoading: isFetchingSamples } = useAdminSearchSamplesQuery(
    {
      input: {
        interviewId: interview.interviewId,
        limit: 10,
        sortDirection: 'DESC',
      },
    },
    {
      skip: !interview?.interviewId || !isOpen,
    },
  );

  // Show two samples from each speaker.
  const samples = useMemo(() => {
    if (!data?.adminSearchSamples) return [];

    const spk0Samples = data.adminSearchSamples.filter(
      (sample) => sample.speakerLabel === 'SPK_0',
    );
    const spk1Samples = data.adminSearchSamples.filter(
      (sample) => sample.speakerLabel === 'SPK_1',
    );

    // Can change value in future if want multiple validations per user
    const limit = 1;
    const topSpk0 = spk0Samples.slice(0, limit);
    const topSpk1 = spk1Samples.slice(0, limit);

    const alternatingSamples = [];
    for (let i = 0; i < limit; i += 1) {
      if (topSpk0[i]) alternatingSamples.push(topSpk0[i]);
      if (topSpk1[i]) alternatingSamples.push(topSpk1[i]);
    }

    return alternatingSamples as InterviewSegment[];
  }, [data?.adminSearchSamples]);

  // if (!data?.adminSearchSamples?.length) {
  //   return <div>Could not find any samples</div>;
  // }
  // if (!isError) {
  //   return <div>Something went wrong</div>;
  // }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      aria-labelledby="Review interview samples modal"
      aria-describedby="Review interview samples"
      title="Review Samples"
      minHeight={260}
      width={800}
      disableBackgroundDismiss
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        gap={2}
        px={2}
      >
        {isFetchingSamples ? (
          <Loading minHeight="100px" />
        ) : (
          <div>
            {samples.map((sample, i) => (
              <>
                {i > 0 && <Divider sx={{ mb: 2 }} />}
                <SampleItem
                  sample={sample}
                  key={sample.s3Key}
                  interview={interview}
                  user={user}
                />
              </>
            ))}
          </div>
        )}
      </Box>
    </Modal>
  );
}
