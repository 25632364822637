import { getFileExtension } from 'common/utils';
import { uploadVoiceSample } from 'hooks/use-upload-content/utils';
import { useCallback, useState } from 'react';
import {
  useAdminUpdateTwinVoiceMutation,
  useAdminUpdateVoiceSampleMutation,
} from 'routes/user/api/user';
import {
  AdminUpdateVoiceSampleMutation,
  TwinModelTtsConfig,
} from 'routes/graphql.generated';
import { generateSampleText } from 'utils/generateSampleText';
import { SuperfeelUser } from '@superfeel/types';
import { useSnackbar } from 'state/context/snackBar';
import { EditVoiceFromValues } from '../forms/edit-voice-form';
import { languageStringToSymbol } from '../utils';

interface UseSubmitVoiceFormOptions {
  onVoiceSubmittionComplete: () => void;
}

export default function useSubmitVoiceForm(
  targetUser: SuperfeelUser,
  { onVoiceSubmittionComplete }: UseSubmitVoiceFormOptions,
) {
  const [voiceSampleConfig, setVoiceSample] = useState<
    | TwinModelTtsConfig
    | (AdminUpdateVoiceSampleMutation & TwinModelTtsConfig)
    | null
  >();
  const { showSnackbar } = useSnackbar();
  const [isSubmiting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState('');
  const [updateVoiceSampleMutation] = useAdminUpdateVoiceSampleMutation();

  const [updateTwinVoice] = useAdminUpdateTwinVoiceMutation();

  const onSubmit = useCallback(
    async (data: EditVoiceFromValues) => {
      if (data.audioFile) {
        try {
          setIsSubmitting(true);
          setStatus('Uploading recording...');
          const key = `${targetUser.username}-voice-sample-${Date.now()}.${getFileExtension(data.audioFile.type)}`;

          // upload file
          await uploadVoiceSample(key, data.audioFile);

          const {
            adminUpdateTwinVoice: { ttsConfigId },
          } = await updateTwinVoice({
            input: {
              action: 'CREATE',
              voiceId: data.name,
              voiceSampleUri: key,
              language: languageStringToSymbol(data.language),
              targetUserId: targetUser.userId,
            },
          }).unwrap();

          setStatus('Updating voice configuration...');
          const voiceSample = await updateVoiceSampleMutation({
            input: {
              ttsConfigId,
              speed: 1,
              text: generateSampleText(targetUser),
              targetUserId: targetUser.userId,
            },
          }).unwrap();

          setVoiceSample(voiceSample.adminUpdateTwinVoiceSample);
          onVoiceSubmittionComplete();
        } catch (err) {
          setVoiceSample(null);
          setStatus('Error - try again');
          showSnackbar((err as Error)?.message || 'Unknown error', 'error');
        } finally {
          setIsSubmitting(false);
        }
      }
    },
    [
      targetUser,
      updateTwinVoice,
      updateVoiceSampleMutation,
      onVoiceSubmittionComplete,
      showSnackbar,
    ],
  );

  return {
    onSubmit,
    isSubmitingVoiceForm: isSubmiting,
    status,
    voiceSampleConfig,
  };
}
