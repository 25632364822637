import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Modal, TextField } from 'components/atoms';
import { Button } from 'components/button';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { CreateCollectionForm } from '../interviews';

interface CreateModalCollectionProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmitForm: (e: React.FormEvent<HTMLFormElement>) => void;
  register: UseFormRegister<CreateCollectionForm>;
  errors: FieldErrors<CreateCollectionForm>;
  isSubmitting: boolean;
  isValid: boolean;
}

export function CreateModalCollection({
  isOpen,
  closeModal,
  onSubmitForm,
  register,
  errors,
  isSubmitting,
  isValid,
}: CreateModalCollectionProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      aria-labelledby="Create collection modal"
      aria-describedby="Create a new collection"
      title="Create collection"
      minHeight={260}
    >
      <Box
        component="form"
        noValidate
        onSubmit={onSubmitForm}
        display="flex"
        flexDirection="column"
        gap={3}
        p={2}
      >
        <TextField
          label="Collection Name"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('name')}
          placeholder="Enter collection name"
          error={!!errors.name}
          helperText={errors.name?.message}
          fullWidth
        />

        <TextField
          label="Interviewer Name"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...register('interviewerName')}
          placeholder="Enter interviewer name"
          error={!!errors.interviewerName}
          helperText={errors.interviewerName?.message}
          fullWidth
        />

        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          mt={2}
        >
          <Button
            variant="outlined"
            onClick={closeModal}
            label="Cancel"
          />
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid || isSubmitting}
            type="submit"
            variant="contained"
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
