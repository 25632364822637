import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState, useRef, useCallback, ChangeEvent, DragEvent } from 'react';
import { SxProps } from '@mui/material/styles';

type DragDropInput = {
  label?: string;
  buttonLabel?: string;
  multipleFiles?: boolean;
  acceptFileTypes?: string | string[];
  onFilesChange?: (file: FileList) => void;
  sx?: SxProps;
};

export function Dropzone({
  label = 'Drag files here',
  buttonLabel = 'Click here to upload',
  multipleFiles = false,
  acceptFileTypes = '*/*',
  onFilesChange = () => {},
  sx = {},
}: DragDropInput) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [dragOver, setDragOver] = useState(false);

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      if (e.dataTransfer?.files?.length) {
        onFilesChange(e.dataTransfer?.files);
      }
    },
    [onFilesChange],
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const files = e.target?.files;
      if (!files?.length) {
        return;
      }
      onFilesChange(files);
    },
    [onFilesChange],
  );

  const onButtonClick = useCallback(() => {
    inputRef?.current?.click();
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        type="file"
        accept={
          typeof acceptFileTypes === 'string'
            ? acceptFileTypes
            : acceptFileTypes.join(',')
        }
        id="input-file-upload"
        ref={inputRef}
        multiple={multipleFiles}
        onChange={handleChange}
        style={{ opacity: 0, height: 0, width: 0 }}
      />
      <Box
        id="label-file-upload"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '2px dashed',
          borderColor: dragOver ? 'primary.main' : 'grey.400',
          borderRadius: 2,
          padding: 4,
          textAlign: 'center',
          backgroundColor: dragOver ? 'action.hover' : '',
          transition: 'background-color 0.3s ease',
          ...sx,
        }}
      >
        <Box>
          <CloudUploadIcon
            fontSize="large"
            color="action"
          />
          <Typography
            textAlign="center"
            paddingY={1}
            sx={{ cursor: 'default' }}
          >
            {label}
          </Typography>
          <Typography
            textAlign="center"
            sx={{ cursor: 'default' }}
          >
            or
          </Typography>
          <Button
            onClick={onButtonClick}
            sx={{
              padding: 1,
              backgroundColor: 'transparent',
              ':hover': { textDecoration: 'underline' },
            }}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
