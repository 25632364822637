import { Modal } from 'components/atoms';
import EditDocumentForm from '../../forms/edit-document-form';

interface UserDocumentsModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export default function UserDocumentsModal({
  isOpen,
  closeModal,
}: UserDocumentsModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Add New Document"
      disableBackgroundDismiss
    >
      <EditDocumentForm closeModal={closeModal} />
    </Modal>
  );
}
