import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  InterviewRole,
  InterviewSegment,
  SuperfeelUser,
} from '@superfeel/types';
import { secsToRelativeTime } from 'utils/date';
import { nameStandardiser } from 'utils/nameStandardiser';

type MetadataValue = string | number;

interface MetadataItem {
  label: string;
  value: MetadataValue;
}

interface MetadataGroupProps {
  items: MetadataItem[];
}

function MetadataGroup({ items }: MetadataGroupProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
    >
      {items.map((item) => (
        <Stack
          key={item.label}
          spacing={1}
        >
          <Typography
            color="grey"
            sx={{
              textTransform: 'uppercase',
              fontSize: '10px',
              fontWeight: 600,
            }}
            variant="caption"
          >
            {item.label}
          </Typography>
          <Typography
            color="lightgray"
            sx={{
              fontWeight: 600,
              fontSize: '16px',
            }}
            variant="body1"
          >
            {typeof item.value === 'number'
              ? secsToRelativeTime(item.value)
              : item.value}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}

interface SampleHeaderProps {
  sample: InterviewSegment;
  speakerRole: InterviewRole;
  isDisabled?: boolean;
  isLoading?: boolean;
  user?: SuperfeelUser;
  handleSpeakerRoleChange: (
    event: React.MouseEvent<HTMLElement>,
    newRole: InterviewRole,
  ) => void;
}

export function SampleHeader({
  sample,
  speakerRole,
  isDisabled = false,
  isLoading = false,
  user,
  handleSpeakerRoleChange,
}: SampleHeaderProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <MetadataGroup
        items={[
          { label: 'Label', value: sample.speakerLabel },
          { label: 'Start Time', value: sample.startTimeSecs },
          { label: 'End Time', value: sample.endTimeSecs },
        ]}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {isLoading && (
          <CircularProgress
            size={16}
            sx={{ mr: 1 }}
          />
        )}
        <ToggleButtonGroup
          size="small"
          value={speakerRole}
          onChange={handleSpeakerRoleChange}
          exclusive
          disabled={isDisabled}
        >
          <ToggleButton
            value="USER"
            aria-label="left aligned"
          >
            {nameStandardiser(user, 'SINGLE_NAME') || 'USER'}
          </ToggleButton>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ opacity: 0.5 }}
          />
          <ToggleButton
            value="INTERVIEWER"
            aria-label="left aligned"
          >
            Interviewer
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
}
