/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Switch,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useSnackbar } from 'state/context/snackBar';
import { Modal, TextField } from 'components/atoms';
import {
  AdminUserInfoInput,
  MembershipType,
  SuperfeelUser,
} from '@superfeel/types';
import { Button } from 'components/button';
import { validateAndTransformPhoneNumber } from 'utils/validate-phonenumber';
import { useAdminUpdateUserMutation } from '../api/user';

const schema = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    username: yup
      .string()
      .required('Username is required')
      .min(3, 'Username must be at least 3 characters')
      .max(15, 'Username must be 15 characters or less')
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Username can only contain letters, numbers, and underscores',
      ),
    phone: yup
      .string()
      .required('Phone number is required')
      .test('valid-phone', 'Invalid phone number', (value) => {
        if (!value) return false;
        const [isPhoneValid] = validateAndTransformPhoneNumber(value);
        return isPhoneValid;
      }),
    email: yup
      .string()
      .notRequired()
      .test('valid-email', 'Invalid email', (value) => {
        if (!value) return true;
        return isEmail(value);
      }),
    membershipType: yup
      .string()
      .required()
      .oneOf(['STANDARD', 'PRO', 'PLUS', 'ADMIN'])
      .default('STANDARD'),
    isFlagged: yup.boolean().required().default(false),
  })
  .required();

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: SuperfeelUser;
}

export function EditUserModal({ isOpen, onClose, user }: EditUserModalProps) {
  const { showSnackbar } = useSnackbar();
  const [updateUser, { isLoading }] = useAdminUpdateUserMutation();

  const defaultValues = useMemo(
    () => ({
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      username: user.username,
      phone: user.phone || '',
      email: user.email || '',
      userId: user.userId,
      membershipType: user.membership?.type || 'STANDARD',
      isFlagged: Boolean(user.isFlagged),
    }),
    [user],
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset, defaultValues]);

  const onSubmit: SubmitHandler<AdminUserInfoInput> = useCallback(
    async (data) => {
      try {
        await updateUser({
          input: {
            action: 'UPDATE',
            user: {
              ...data,
              isVirtual: user.isVirtual,
            },
          },
        }).unwrap();

        showSnackbar('User updated successfully', 'success');
        onClose();
      } catch (error) {
        showSnackbar(
          error instanceof Error ? error.message : 'Failed to update user',
          'error',
        );
      }
    },
    [updateUser, user, showSnackbar, onClose],
  );

  const isProcessing = useMemo(
    () => isLoading || isSubmitting,
    [isLoading, isSubmitting],
  );

  return (
    <Modal
      title="Edit User"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
        minHeight={260}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box p={2}>
          <Stack spacing={3}>
            <Box
              display="flex"
              gap={2}
            >
              <TextField
                {...register('firstName')}
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
                disabled={isProcessing}
              />
              <TextField
                {...register('lastName')}
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
                disabled={isProcessing}
              />
            </Box>

            <Box
              display="flex"
              gap={2}
            >
              <TextField
                {...register('phone')}
                label="Phone"
                error={!!errors.phone}
                helperText={errors.phone?.message}
                fullWidth
                disabled={isProcessing}
              />
              <TextField
                {...register('email')}
                label="Email"
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                disabled={isProcessing}
              />
            </Box>

            <Box
              display="flex"
              gap={2}
            >
              <FormControl
                variant="outlined"
                fullWidth
              >
                <InputLabel id="select-label">Membership</InputLabel>
                <Select<MembershipType>
                  {...register('membershipType')}
                  label="Membership"
                  fullWidth
                  disabled={isProcessing}
                  defaultValue={defaultValues.membershipType}
                >
                  <MenuItem value="STANDARD">Standard</MenuItem>
                  <MenuItem value="PRO">Pro</MenuItem>
                  <MenuItem value="PLUS">Plus</MenuItem>
                  <MenuItem value="ADMIN">Admin</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              display="flex"
              alignItems="center"
            >
              <Typography
                variant="body1"
                color="text.primary"
                width="20%"
              >
                Is Flagged
              </Typography>
              <Controller
                name="isFlagged"
                control={control}
                render={({ field }) => (
                  <Switch
                    color="primary"
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
              <Tooltip
                title="Toggles the user's visibility on the platform"
                arrow
              >
                <InfoOutlined
                  sx={{ ml: 1, fontSize: 20, color: 'action.active' }}
                />
              </Tooltip>
            </Box>
          </Stack>
        </Box>

        <Box
          component="footer"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            p: 2,
          }}
        >
          <Button
            onClick={onClose}
            disabled={isProcessing}
            label="CANCEL"
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            disabled={isProcessing}
            label={isProcessing ? 'Updating User...' : 'UPDATE USER'}
          />
        </Box>
      </Box>
    </Modal>
  );
}
