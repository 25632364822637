import { styled, SxProps } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Cancel from '@mui/icons-material/Cancel';
import PauseCircle from '@mui/icons-material/PauseCircle';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutline from '@mui/icons-material/HelpOutline';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Interview, JobStatus, JobType, TwinJob } from '@superfeel/types';
import { useMemo } from 'react';

const STAGES: JobType[] = [
  'PROCESSING',
  'CLEANING',
  'TRANSCRIBE',
  'SEGMENTATION',
  'GRAPH',
];

type ProgressStepperProps = {
  interview: Interview;
  isLoadingJobStatus?: boolean;
  isJobStatusError?: boolean;
  relatedJobs?: TwinJob[];
  sx?: SxProps;
};

type JobLoadingState = 'LOADING' | 'ERROR' | 'NONE';

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#cccccc',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#ffffff',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 1,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}));

const CustomStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    '& .CustomStepIcon-completedIcon': {
      color: '#4caf50',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-activeIcon': {
      color: '#2196f3',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-idleIcon': {
      color: '#444444',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-queuedIcon': {
      color: '#673ab7',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-startingIcon': {
      color: '#2196f3',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-inProgressIcon': {
      color: '#ffc107',
      zIndex: 1,
      fontSize: 18,
    },
    '& .CustomStepIcon-errorIcon': {
      color: '#f44336',
      zIndex: 1,
      fontSize: 18,
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: '#784af4',
        },
      },
    ],
  }),
);

function CustomStepIcon(
  props: StepIconProps,
  loadingStatus: JobLoadingState,
  jobStatus: JobStatus,
) {
  const { active, completed, className } = props;

  const stepIcon = useMemo(() => {
    if (loadingStatus === 'LOADING') {
      return <CircularProgress size={16} />;
    }
    if (loadingStatus === 'ERROR') {
      return <HelpOutline className="CustomStepIcon-errorIcon" />;
    }
    if (completed || (active && jobStatus === 'SUCCEEDED')) {
      return <CheckCircle className="CustomStepIcon-completedIcon" />;
    }
    if ((!active && !completed) || (active && jobStatus === 'NOT_STARTED')) {
      return <RadioButtonUnchecked className="CustomStepIcon-idleIcon" />;
    }
    if (active && jobStatus === 'FAILED') {
      return <Cancel className="CustomStepIcon-errorIcon" />;
    }
    if (active && jobStatus === 'QUEUED') {
      return <PauseCircle className="CustomStepIcon-queuedIcon" />;
    }
    if (
      active &&
      (jobStatus === 'PENDING' ||
        jobStatus === 'SUBMITTED' ||
        jobStatus === 'STARTING')
    ) {
      return <RadioButtonChecked className="CustomStepIcon-startingIcon" />;
    }
    if (active && (jobStatus === 'RUNNABLE' || jobStatus === 'RUNNING')) {
      return <RadioButtonChecked className="CustomStepIcon-inProgressIcon" />;
    }

    return <RadioButtonChecked className="CustomStepIcon-idleIcon" />;
  }, [active, completed, jobStatus, loadingStatus]);

  return (
    <CustomStepIconRoot
      ownerState={{ active }}
      className={className}
    >
      {stepIcon}
    </CustomStepIconRoot>
  );
}

type CustomStepInput = {
  stage: JobType;
  loadingState: JobLoadingState;
  graphIngestionStatus: JobStatus;
  jobs: TwinJob[];
};

function CustomStep({
  stage,
  loadingState,
  jobs,
  graphIngestionStatus,
}: CustomStepInput) {
  const jobStatus: JobStatus = useMemo(() => {
    if (stage === 'GRAPH') {
      return graphIngestionStatus;
    }
    if (stage === 'PROCESSING') {
      return 'SUCCEEDED';
    }
    const validJobs = jobs
      .filter((j) => j.jobType === stage)
      .sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
    if (!validJobs.length) {
      return 'NOT_STARTED';
    }
    return validJobs[0].status;
  }, [graphIngestionStatus, jobs, stage]);

  const tooltip = useMemo(() => {
    if (stage === 'PROCESSING') {
      return 'UPLOAD';
    }
    if (jobStatus === 'NOT_STARTED' || jobStatus === 'SUCCEEDED') {
      return stage;
    }
    if (jobStatus === 'FAILED') {
      const validJobs = jobs
        .filter((j) => j.jobType === stage)
        .sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
      const message = validJobs.length ? validJobs[0].statusMessage : '';
      return message
        ? `${stage} - ${jobStatus}\n\n${message}`
        : `${stage} - ${jobStatus}`;
    }
    return `${stage} - ${jobStatus}`;
  }, [jobStatus, jobs, stage]);

  return (
    <Tooltip
      title={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}
      arrow
    >
      <StepLabel
        slots={{
          stepIcon: (props) => CustomStepIcon(props, loadingState, jobStatus),
        }}
      />
    </Tooltip>
  );
}

export function ProgressStepper({
  interview,
  sx,
  isLoadingJobStatus,
  isJobStatusError,
  relatedJobs = [],
}: ProgressStepperProps) {
  const activeStep = useMemo(() => {
    if (!interview?.processingStage) {
      return 0;
    }
    if (
      interview.processingStage === 'GRAPH' &&
      interview.twinGraphIngestionStatus === 'SUCCEEDED'
    ) {
      return STAGES.length;
    }
    return STAGES.indexOf(interview.processingStage);
  }, [interview.processingStage, interview.twinGraphIngestionStatus]);

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      sx={sx}
    >
      {STAGES.map((stage, i) => {
        let loadingState: JobLoadingState = 'NONE';
        if (activeStep === i && isLoadingJobStatus) {
          loadingState = 'LOADING';
        } else if (activeStep === i && isJobStatusError) {
          loadingState = 'ERROR';
        }
        return (
          <Step key={stage}>
            <CustomStep
              key={stage}
              loadingState={loadingState}
              jobs={relatedJobs}
              stage={stage}
              graphIngestionStatus={
                interview.twinGraphIngestionStatus || 'NOT_STARTED'
              }
            />
          </Step>
        );
      })}
    </Stepper>
  );
}
