/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextFieldBase, {
  TextFieldProps as TextFieldBaseProps,
} from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

type TextFieldProps = TextFieldBaseProps & {
  label?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  customStyles?: React.CSSProperties;
};

const Input = styled(TextFieldBase)(
  ({ theme }) => `
    width: 100%;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.body2.fontSize};
    font-weight: 400;
    border-radius: 8px;
  `,
);

const TextField = React.forwardRef(function CustomInput(
  props: TextFieldProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    placeholder,
    customStyles,
    multiline = false,
    rows,
    size = 'small',
    maxLength = 0,
    ...restProps
  } = props;
  return (
    <Input
      placeholder={placeholder}
      ref={ref}
      style={customStyles}
      fullWidth
      size={size}
      multiline={multiline}
      rows={rows}
      slotProps={{
        ...(restProps.slotProps || {}),
        ...(maxLength ? { htmlInput: { maxLength } } : {}),
      }}
      {...restProps}
    />
  );
});

export default TextField;
