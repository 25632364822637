import { TwinPromptConfig } from '@superfeel/types';

export const checkPromptValid = (prompt: TwinPromptConfig) => {
  const {
    overrideActive,
    conversationalStyle,
    personalBackground,
    personalityTraits,
    startPhrase,
    promptOverride,
  } = prompt;

  return (
    (!!promptOverride?.trim() && overrideActive) ||
    (!!conversationalStyle?.trim() &&
      !!personalBackground?.trim() &&
      !!personalityTraits?.trim() &&
      !!startPhrase?.trim())
  );
};
