import { useState, useEffect, useCallback } from 'react';

export const useQueryParams = () => {
  // Function to get all query parameters as an object
  const getParams = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const result: Record<string, string> = {};
    params.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }, []);

  // React state for query parameters
  const [queryParams, setQueryParams] = useState(getParams);

  useEffect(() => {
    const handlePopState = () => setQueryParams(getParams());
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [getParams]);

  return { queryParams };
};
