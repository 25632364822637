import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import Add from '@mui/icons-material/Add';
import { useCallback, useState } from 'react';
import UserDocumentsModal from './components/user-documents-modal/user-documents-modal';
import UserDocumentsView from './components/user-documents-view';

export default function UserDocuments() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          component="h1"
        >
          Documents
        </Typography>
        <Button
          startIcon={<Add />}
          size="medium"
          onClick={onToggleModal}
          variant="contained"
          color="primary"
          label="Add new document"
        />
      </Box>
      <UserDocumentsView />
      <UserDocumentsModal
        closeModal={onToggleModal}
        isOpen={isModalOpen}
      />
    </Box>
  );
}
