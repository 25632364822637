import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Loading, Modal } from 'components/atoms';
import { useCallback, useMemo, useState } from 'react';
import {
  TwinModelTtsConfig,
  TwinPromptConfig,
  TwinModelConfig,
} from '@superfeel/types';
import {
  useAdminSearchTwinPromptQuery,
  useAdminUpdateTwinConfigMutation,
  useSearchAdminTwinConfigsQuery,
  useSearchAdminTwinVoicesQuery,
} from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';
import { TwinChatComponent } from 'components/twin-chat';
import VoiceEditModal from 'routes/user/voice/components/voice-edit-modal';
import EditPromptModal from 'routes/user/prompts/components/edit-prompt-modal';
import { SwapConfigModal } from 'routes/user/components/SwapConfigModal';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import TwinModelConfigCard from '../twin-card';
import EditTwinConfigForm, {
  EditTwinConfigFormValues,
} from '../../forms/edit-twin-config-form';

type PopupMode =
  | 'NONE'
  | 'EDIT_MAIN'
  | 'CALL'
  | 'EDIT_VOICE'
  | 'EDIT_PROMPT'
  | 'SELECT_VOICE'
  | 'SELECT_PROMPT';

function TwinsContent(): JSX.Element {
  const [twinModelConfig, setTwinModelConfig] = useState<
    TwinModelConfig | undefined
  >(undefined);
  const [ttsConfig, setTtsConfig] = useState<TwinModelTtsConfig | undefined>(
    undefined,
  );
  const [promptConfig, setPromptConfig] = useState<
    TwinPromptConfig | undefined
  >(undefined);
  const [popupMode, setPopupMode] = useState<PopupMode>('NONE');
  const { showSnackbar } = useSnackbar();
  const { user } = useGetTargetUser();

  const { data, isLoading, isError } = useSearchAdminTwinConfigsQuery(
    {
      input: {
        targetUserId: user?.userId,
      },
    },
    {
      skip: !user?.userId,
    },
  );
  const {
    data: ttsConfigs,
    isLoading: isLoadingVoice,
    isError: isVoiceError,
  } = useSearchAdminTwinVoicesQuery(
    {
      input: {
        targetUserId: user?.userId || '',
      },
    },
    { skip: !user?.userId },
  );
  const {
    data: promptConfigs,
    isLoading: isLoadingPrompts,
    isError: isPromptError,
  } = useAdminSearchTwinPromptQuery(
    {
      input: {
        targetUserId: user?.userId,
      },
    },
    {
      skip: !user,
    },
  );

  const [updateAdminTwinConfig, { isLoading: isUpdating }] =
    useAdminUpdateTwinConfigMutation();

  const { configOptions, isLoadingConfigs, isConfigError } = useMemo(() => {
    if (popupMode === 'SELECT_PROMPT') {
      return {
        configOptions: promptConfigs?.adminSearchTwinPrompt || [],
        isLoadingConfigs: isLoadingPrompts,
        isConfigError: isPromptError,
      };
    }
    if (popupMode === 'SELECT_VOICE') {
      return {
        configOptions: ttsConfigs?.adminSearchTwinVoices || [],
        isLoadingConfigs: isLoadingVoice,
        isConfigError: isVoiceError,
      };
    }
    return {
      configOptions: [],
      isLoadingConfigs: false,
      isConfigError: false,
    };
  }, [
    isLoadingPrompts,
    isLoadingVoice,
    isPromptError,
    isVoiceError,
    popupMode,
    promptConfigs?.adminSearchTwinPrompt,
    ttsConfigs?.adminSearchTwinVoices,
  ]);

  const handleDeleteTwin = useCallback(
    (config: TwinModelConfig) => {
      updateAdminTwinConfig({
        input: {
          action: 'DELETE',
          config: {
            targetUserId: config.targetUserId,
            configId: config.modelConfigId,
          },
        },
      })
        // If we create a reusable snackbar component and use some redux state to track, can
        // handle side effects in the onQueryStarted property in redux toolkit. Moves all
        // logic out of here.
        .unwrap()
        .then(() => {
          showSnackbar('Twin config deleted', 'success');
          setPopupMode('NONE');
          setTwinModelConfig(undefined);
        })
        .catch(() => {
          showSnackbar('Error deleting twin configuration', 'error');
        });
    },
    [showSnackbar, updateAdminTwinConfig],
  );

  const onUpdateTwin = useCallback(
    (formData: EditTwinConfigFormValues, configId: string) => {
      updateAdminTwinConfig({
        input: {
          action: 'UPDATE',
          config: {
            targetUserId: user?.userId,
            name: formData.name,
            twinDescription: formData.twinDescription,
            configId,
            visibility: formData.visibility,
          },
        },
      })
        // If we create a reusable snackbar component and use some redux state to track, can
        // handle side effects in the onQueryStarted property in redux toolkit. Moves all
        // logic out of here.
        .unwrap()
        .then(() => {
          showSnackbar('Updated twin configuration successfully', 'success');
          setPopupMode('NONE');
          setTwinModelConfig(undefined);
        })
        .catch(() => {
          showSnackbar('Error updating twin configuration', 'error');
        });
    },
    [showSnackbar, updateAdminTwinConfig, user?.userId],
  );

  const onShowGeneralModal = useCallback(
    (mode: PopupMode, config: TwinModelConfig) => {
      setPopupMode(mode);
      setTwinModelConfig(config);
    },
    [],
  );

  const onShowVoiceEdit = useCallback((tts: TwinModelTtsConfig) => {
    setPopupMode('EDIT_VOICE');
    setTtsConfig(tts);
  }, []);

  const onShowPromptEdit = useCallback((pconfig: TwinPromptConfig) => {
    setPopupMode('EDIT_PROMPT');
    setPromptConfig(pconfig);
  }, []);

  const onCloseModal = useCallback(() => {
    setPopupMode('NONE');
    setTwinModelConfig(undefined);
    setTtsConfig(undefined);
    setPromptConfig(undefined);
  }, []);

  if (isError) return <div>Error fetching Heirs</div>;
  if (isLoading || !user?.userId) return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {!data?.adminSearchTwinModelConfigs?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
          gap={3}
        >
          <Box
            textAlign="center"
            maxWidth="450px"
          >
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
            >
              Create your first Heir
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Press the button in the top-right to get started
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
        >
          {data.adminSearchTwinModelConfigs.map((config) => (
            <Grid
              size={{ sm: 12, md: 12, lg: 6 }}
              key={config.modelConfigId}
            >
              <TwinModelConfigCard
                onEdit={(con) => onShowGeneralModal('EDIT_MAIN', con)}
                onDelete={handleDeleteTwin}
                onCall={(con) => onShowGeneralModal('CALL', con)}
                onEditVoice={onShowVoiceEdit}
                onEditPrompt={onShowPromptEdit}
                onSelectPrompt={(con) =>
                  onShowGeneralModal('SELECT_PROMPT', con)
                }
                onSelectVoice={(con) => onShowGeneralModal('SELECT_VOICE', con)}
                config={config}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!!twinModelConfig && (
        <EditTwinConfigForm
          isOpen={popupMode === 'EDIT_MAIN'}
          closeModal={onCloseModal}
          onSubmitForm={onUpdateTwin}
          initialConfig={twinModelConfig}
          isLoading={isUpdating}
          allConfigs={data?.adminSearchTwinModelConfigs}
        />
      )}
      <Modal
        isOpen={popupMode === 'CALL' && !!twinModelConfig}
        onClose={onCloseModal}
        title={`Chat with ${user?.firstName}`}
        disableBackgroundDismiss
      >
        <TwinChatComponent
          config={twinModelConfig}
          user={user}
          allowCustomServer
        />
      </Modal>
      <VoiceEditModal
        config={ttsConfig}
        closeModal={onCloseModal}
        isOpen={popupMode === 'EDIT_VOICE' && !!ttsConfig}
        targetUser={user}
      />
      <EditPromptModal
        isOpen={popupMode === 'EDIT_PROMPT' && !!promptConfig}
        closeModal={onCloseModal}
        targetUser={user}
        initialConfig={promptConfig}
      />
      <SwapConfigModal
        configType={popupMode === 'SELECT_VOICE' ? 'VOICE' : 'PROMPT'}
        isOpen={popupMode === 'SELECT_VOICE' || popupMode === 'SELECT_PROMPT'}
        isLoading={isLoadingConfigs}
        isError={isConfigError}
        options={configOptions}
        onClose={onCloseModal}
        twinModelConfig={twinModelConfig}
        user={user}
      />
    </Box>
  );
}

export default TwinsContent;
