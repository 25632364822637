import { Box } from '@mui/material';
import Layout from 'layouts';
import { Outlet, Route, Routes } from 'react-router-dom';
import UserHome from './home';
import UserData from './data';
import UserTwins from './twins';
import UserPrompts from './prompts';
import UserVoice from './voice';
import UserDocuments from './documents';

export default function User() {
  return (
    <Layout>
      <Box>
        <Outlet />
        <Routes>
          <Route
            index
            element={<UserHome />}
          />
          <Route
            path="data"
            element={<UserData />}
          />
          <Route
            path="documents"
            element={<UserDocuments />}
          />
          <Route
            path="heirs"
            element={<UserTwins />}
          />
          <Route
            path="voice"
            element={<UserVoice />}
          />
          <Route
            path="prompts"
            element={<UserPrompts />}
          />
        </Routes>
      </Box>
    </Layout>
  );
}
