import { ResourcesConfig } from 'aws-amplify';

const {
  VITE_COGNITO_IDENTITY_POOL_ID,
  VITE_COGNITO_USER_POOL_ID,
  VITE_COGNITO_WIDGET_CLIENT_ID,
  VITE_REST_ENDPOINT,
} = import.meta.env;

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: VITE_COGNITO_IDENTITY_POOL_ID,
      userPoolId: VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: VITE_COGNITO_WIDGET_CLIENT_ID,
      allowGuestAccess: false,
      loginWith: {
        email: true,
        phone: false,
        username: false,
      },
      signUpVerificationMethod: 'link',
    },
  },
  API: {
    REST: {
      main: {
        endpoint: VITE_REST_ENDPOINT,
        region: 'eu-west-2',
        service: 'execute-api',
      },
    },
  },
};
