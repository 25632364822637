import { useCallback, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { PromptCategory, PostType } from '@superfeel/types';
import { SuperFeelTableData } from 'routes/prompt';
import { parseCSV, type CSVParseResult } from 'utils/csv-parser';
import dayjs from 'dayjs';
import { POST_TYPE, CATEGORY } from '../../../../constants';

interface CSVPromptReaderProps {
  onValidData: (
    data: Partial<SuperFeelTableData>[],
    result: CSVParseResult<SuperFeelTableData>,
  ) => void;
}

interface CSVReaderResults {
  data: string[][];
}

interface CSVReaderProps {
  getRootProps?: () => Record<string, unknown>;
  acceptedFile?: boolean;
}

export function UploadPromptsCSV({ onValidData }: CSVPromptReaderProps) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [uploadedData, setUploadedData] = useState<
    Partial<SuperFeelTableData>[] | null
  >(null);

  const validateCategory = useCallback(
    (category: string): PromptCategory | undefined => {
      const foundCategory = CATEGORY.find(
        (c) => c.value.toLowerCase() === category.toLowerCase(),
      );
      return foundCategory?.value;
    },
    [],
  );

  const validateType = useCallback((type: string): PostType | undefined => {
    const foundType = POST_TYPE.find(
      (t) => t.value.toLowerCase() === type.toLowerCase(),
    );
    return foundType?.value;
  }, []);

  const validateIntensity = useCallback(
    (intensity: string): string | undefined => {
      const num = Number(intensity);
      if (Number.isNaN(num) || num < 0 || num > 3) return undefined;
      return intensity;
    },
    [],
  );

  const validatePublishDate = useCallback(
    (publishDate: string): string | undefined => {
      if (dayjs(publishDate, 'YYYY-MM-DD', true).isValid()) {
        return publishDate;
      }
      return undefined;
    },
    [],
  );

  const handleUpload = useCallback(
    (results: CSVReaderResults) => {
      const result = parseCSV<SuperFeelTableData>(results.data, [
        { key: 'body' },
        { key: 'category', validate: validateCategory },
        { key: 'type', validate: validateType },
        { key: 'intensity', validate: validateIntensity },
        { key: 'publishDate', validate: validatePublishDate },
      ]);

      if (result.success) {
        setUploadedData(result.data);
      }
      onValidData(result.success ? result.data : [], result);
    },
    [
      onValidData,
      validateCategory,
      validateType,
      validateIntensity,
      validatePublishDate,
    ],
  );

  if (uploadedData) {
    return (
      <Box
        data-testid="uploaded-data-container"
        mx={2}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Intensity</TableCell>
              <TableCell>Publish Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="uploaded-data-table">
            {uploadedData.map((row) => (
              <TableRow key={`item-${row.body}`}>
                <TableCell>{row.body}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.intensity}</TableCell>
                <TableCell>{row.publishDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    );
  }

  return (
    <CSVReader
      onUploadAccepted={handleUpload}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile }: CSVReaderProps) => (
        <Box
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps?.()}
          data-testid="csv-upload-area"
          sx={{
            alignItems: 'center',
            border: `2px dashed #686868`,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            padding: 6,
            ...(zoneHover && {
              borderColor: '#686868',
            }),
          }}
        >
          {!acceptedFile ? (
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              py={6}
            >
              <Typography
                variant="body1"
                data-testid="upload-text"
              >
                Drop CSV file here or click to upload
              </Typography>
              <Typography
                variant="body2"
                mt={2}
                data-testid="csv-format-text"
              >
                CSV should include columns for body, category, type, and
                intensity
              </Typography>
            </Box>
          ) : null}
        </Box>
      )}
    </CSVReader>
  );
}
