import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { amplifyConfig } from '@api/auth/amplify.config';
import { amplifyConfig as widgetAmplifyconfig } from '@api/auth/widget.amplify.config';
import { Amplify } from 'aws-amplify';
import App from './App';
import Widget from './Widget';
import reportWebVitals from './reportWebVitals';

const {
  VITE_REACT_APP_TWIN_WIDGET_SENTRY_DSN,
  VITE_REACT_APP_ADMIN_SENTRY_DSN,
  VITE_REACT_APP_STAGE,
  MODE,
} = import.meta.env;

Amplify.configure(MODE === 'widget' ? widgetAmplifyconfig : amplifyConfig);

Sentry.init({
  dsn:
    MODE === 'widget'
      ? VITE_REACT_APP_TWIN_WIDGET_SENTRY_DSN
      : VITE_REACT_APP_ADMIN_SENTRY_DSN,
  integrations:
    MODE === 'widget'
      ? [Sentry.browserTracingIntegration()]
      : [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  environment: VITE_REACT_APP_STAGE,
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets:
    MODE === 'widget'
      ? undefined
      : [
          /^https:\/\/admin-dev\.superfeel.com/,
          /^https:\/\/admin\.superfeel.com/,
        ],
  // Session Replay
  replaysSessionSampleRate: MODE === 'widget' ? 0 : 0.1,
  replaysOnErrorSampleRate: MODE === 'widget' ? 0 : 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {MODE === 'widget' ? (
      <Widget
        height="100vh"
        width="100vw"
      />
    ) : (
      <App />
    )}
  </React.StrictMode>,
);

reportWebVitals();
