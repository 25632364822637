import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';

import { useAdminSearchTwinDocumentsQuery } from 'routes/user/api/user';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { Loading } from 'components/atoms';
import UserDocumentCard from '../user-document-card';

export default function UserDocumentsView() {
  const { user } = useGetTargetUser();

  const { data, isLoading, isError } = useAdminSearchTwinDocumentsQuery({
    input: {
      targetUserId: user?.userId,
      sort: 'DATE_CREATED',
      sortDirection: 'DESC',
      limit: 100,
    },
    skip: !user,
  });

  if (isError) return <div>Error fetching documents</div>;
  if (isLoading || !data) return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {!data?.adminSearchTwinDocuments?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
          gap={3}
        >
          <Box
            textAlign="center"
            maxWidth="450px"
          >
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
            >
              Get started with your first document
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Add documents to ingest new data into your Heir graph
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
        >
          {data.adminSearchTwinDocuments &&
            data.adminSearchTwinDocuments.map((document) => (
              <Grid
                size={{ sm: 12, md: 6, lg: 6 }}
                key={document.documentId}
              >
                <UserDocumentCard
                  document={document}
                  targetUser={user}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}
