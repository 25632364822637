import { getSignedS3Url } from 'utils/s3/getSignedS3Url';

export async function uploadVoiceSample(key: string, file: File) {
  try {
    if (!file) {
      throw new Error('No file provided');
    }

    // Get signed URL for upload
    const { url } = await getSignedS3Url({
      key,
      type: 'VOICE_SAMPLE',
      headers: JSON.stringify({
        'Content-Type': file.type,
      }),
      method: 'PUT',
      expiry: 60 * 60 * 24,
    });

    const response = await fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': file.type },
      body: file,
    });

    if (!response.ok) {
      throw new Error('Error uploaidng audio sample');
    }
  } catch (error) {
    console.error('Error uploading voice:', error);
    throw error;
  }
}
