/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, MenuItem, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { TwinModelConfig, TwinType } from 'routes/graphql.generated';
import { Modal, TextField } from 'components/atoms';
import { TwinConfigVisibility } from '@superfeel/types';
import { useMemo } from 'react';
import { checkPromptValid } from 'utils/checkPromptValid';

export interface EditTwinConfigFormValues {
  name: string;
  twinDescription: string;
  twinDescriptionOne?: string;
  twinDescriptionTwo?: string;
  twinDescriptionThree?: string;
  visibility: TwinConfigVisibility;
  twinType: TwinType;
}

interface EditTwinConfigFormProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmitForm: (data: EditTwinConfigFormValues, configId: string) => void;
  initialConfig: TwinModelConfig;
  isLoading: boolean;
  allConfigs?: TwinModelConfig[];
}

export default function EditTwinConfigForm({
  isOpen,
  closeModal,
  onSubmitForm,
  initialConfig,
  isLoading,
  allConfigs = [],
}: EditTwinConfigFormProps) {
  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid },
  } = useForm<EditTwinConfigFormValues>({
    mode: 'onChange',
    defaultValues: {
      name: initialConfig.name || '',
      twinDescriptionOne:
        initialConfig.twinDescription?.split('.')?.[0]?.trim() || '',
      twinDescriptionTwo:
        initialConfig.twinDescription?.split('.')?.[1]?.trim() || '',
      twinDescriptionThree:
        initialConfig.twinDescription?.split('.')?.[2]?.trim() || '',
      twinDescription: initialConfig.twinDescription,
      visibility: initialConfig.visibility || 'PUBLIC',
      twinType: initialConfig.twinType || 'CHAT',
    },
  });

  const isIncomplete = useMemo(
    () =>
      !initialConfig.selectedTtsConfigId ||
      !initialConfig.selectedPromptConfigId ||
      !initialConfig.selectedAsrConfigId ||
      !checkPromptValid(initialConfig.promptConfig),
    [
      initialConfig.promptConfig,
      initialConfig.selectedAsrConfigId,
      initialConfig.selectedPromptConfigId,
      initialConfig.selectedTtsConfigId,
    ],
  );

  const alreadyActive = useMemo(
    () =>
      !!allConfigs.filter(
        (c) =>
          c.visibility !== 'HIDDEN' &&
          c.modelConfigId !== initialConfig.modelConfigId,
      ).length,
    [allConfigs, initialConfig],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Edit twin config"
      aria-labelledby="Edit twin config modal"
      aria-describedby="Edit an existing twin configuration"
    >
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((data) =>
          onSubmitForm(
            {
              ...data,
              twinDescription: [
                data.twinDescriptionOne?.trim() || '',
                data.twinDescriptionTwo?.trim() || '',
                data.twinDescriptionThree?.trim() || '',
              ]
                .filter((el) => !!el)
                .join('. '),
            },
            initialConfig.modelConfigId,
          ),
        )}
        display="flex"
        flexDirection="column"
        gap={3}
        p={3}
        borderRadius={2}
        minWidth={400}
        mx="auto"
        mt={5}
        boxShadow={24}
      >
        <Controller
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              placeholder="Enter name"
              error={!!error}
              helperText={error ? error.message : ''}
              fullWidth
              maxLength={50}
            />
          )}
        />

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Controller
            name="twinDescriptionOne"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Topic 1"
                sx={{ flex: 1 }}
                maxLength={30}
              />
            )}
          />
          <Controller
            name="twinDescriptionTwo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Topic 2"
                sx={{ flex: 1 }}
                maxLength={30}
              />
            )}
          />
          <Controller
            name="twinDescriptionThree"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Topic 3"
                sx={{ flex: 1 }}
                maxLength={30}
              />
            )}
          />
        </Box>

        <Controller
          name="twinType"
          control={control}
          rules={{ required: 'Twin Type is required' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Twin Type"
              select
              error={!!error}
              helperText={error ? error.message : ''}
              fullWidth
            >
              {['CHAT', 'MEDIA'].map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        {!alreadyActive && isIncomplete && (
          <Typography
            variant="body2"
            color="text.secondary"
            px={1}
            py={0}
          >
            Config is incomplete - some visibility options are unavailable
          </Typography>
        )}
        {alreadyActive && (
          <Typography
            variant="body2"
            color="text.secondary"
            px={1}
            py={0}
          >
            Cannot change visibility while another config is active
          </Typography>
        )}
        <Controller
          name="visibility"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Visibility"
              select
              fullWidth
            >
              {['PUBLIC', 'FRIENDS', 'PRIVATE', 'HIDDEN'].map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                  disabled={
                    (isIncomplete && type !== 'PRIVATE' && type !== 'HIDDEN') ||
                    (alreadyActive && type !== 'HIDDEN')
                  }
                >
                  {type}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              closeModal();
              reset();
            }}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={!isValid || isLoading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
