import { DateTime } from 'luxon';

export const formatDate = (dateString?: string, format?: string) => {
  return DateTime.fromISO(dateString).toFormat(format || 'dd-MMM-yy');
};

export function truncateText(text: string, maxWords: number): string {
  const words = text?.split(' ');
  if (words?.length > maxWords) {
    return `${words?.slice(0, maxWords).join(' ')} ...`;
  }
  return text;
}

export const formatFileSize = (size: number) => {
  if (size >= 1024 ** 3) {
    return `${(size / 1024 ** 3).toFixed(2)} GB`;
  }
  if (size >= 1024 ** 2) {
    return `${(size / 1024 ** 2).toFixed(2)} MB`;
  }
  if (size >= 100) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${size.toFixed(2)} B`;
};

export const roundToTwoDecimals = (num: number): number => {
  return parseFloat(num.toFixed(2));
};

export const enumToObject = (
  enumObj: Record<string, string>,
): Record<string, string> => {
  return Object.keys(enumObj)
    .filter((key) => typeof enumObj[key] === 'string')
    .reduce(
      (acc, key) => {
        acc[key] = enumObj[key];
        return acc;
      },
      {} as Record<string, string>,
    );
};

export const menuOptions = (options: string[]) =>
  options.map((value) => ({
    value,
    label: value,
  }));

export function generateRandomString(length = 10): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  return Array.from({ length }, () =>
    characters.charAt(Math.floor(Math.random() * charactersLength)),
  ).join('');
}

export function getFileExtension(mimeType: string): string {
  const mimeMap: { [key: string]: string } = {
    'audio/aac': '.aac',
    'audio/ogg': '.ogg',
    'audio/mp3': '.mp3',
    'audio/x-wav': '.wav',
    'audio/wav': '.wav',
    'audio/mp4': '.m4a',
    'audio/m4a': '.m4a',
    'audio/x-m4a': '.m4a',
  };

  if (!mimeMap[mimeType]) {
    throw new Error(`Unsupported MIME type: ${mimeType}`);
  }

  return mimeMap[mimeType];
}
