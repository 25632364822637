import { ReactNode } from 'react';
import { AppContextProvider } from './app';
import { SnackbarProvider } from './snackBar';

interface ContextProviderProps {
  children: ReactNode;
}

function ContextProvider({ children }: ContextProviderProps): JSX.Element {
  return (
    <AppContextProvider>
      <SnackbarProvider>{children}</SnackbarProvider>
    </AppContextProvider>
  );
}

export default ContextProvider;
