import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Loading } from 'components/atoms';
import { InterviewCollection } from '@superfeel/types';
import {
  useSearchInterviewCollectionsQuery,
  useUpdateInterviewCollectionMutation,
} from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { CollectionCard } from '../collectionCard';
import { CreateModalCollection } from '../createCollectionModal';

interface DataInterviewsProps {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

export type CreateCollectionForm = {
  name?: string;
  interviewerName?: string;
};

const schema: Yup.ObjectSchema<CreateCollectionForm> = Yup.object().shape({
  name: Yup.string()
    .required('Collection name is required')
    .trim()
    .max(255, 'Collection name cannot exceed 255 characters'),
  interviewerName: Yup.string()
    .max(255, 'Interviewer name cannot exceed 255 characters')
    .optional(),
});

function DataInterviews({ isOpen, setIsOpen }: DataInterviewsProps) {
  const { showSnackbar } = useSnackbar();
  const [updateInterviewCollection] = useUpdateInterviewCollectionMutation();
  const { user } = useGetTargetUser();
  const { data, isError, isLoading } = useSearchInterviewCollectionsQuery(
    {
      input: { targetUserId: user?.userId },
    },
    {
      skip: !user,
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CreateCollectionForm>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      interviewerName: '',
    },
  });

  const closeModal = useCallback(() => {
    setIsOpen(false);
    reset();
  }, [reset, setIsOpen]);

  const onSubmit = useCallback(
    async (formValues: CreateCollectionForm) => {
      if (!user?.userId) return;

      try {
        await updateInterviewCollection({
          input: {
            action: 'CREATE',
            collection: {
              name: formValues.name,
              interviewerName: formValues.interviewerName,
              targetUserId: user.userId,
            },
          },
        }).unwrap();

        showSnackbar('Collection created successfully!', 'success');
        closeModal();
      } catch (err) {
        showSnackbar(
          `Failed to create collection: ${(err as Error)?.message}`,
          'error',
        );
      }
    },
    [user?.userId, updateInterviewCollection, showSnackbar, closeModal],
  );

  const onSubmitForm = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(onSubmit)(e);
    },
    [handleSubmit, onSubmit],
  );

  if (isError) return <div>Error fetching collections</div>;
  if (isLoading) return <Loading />;

  return (
    <>
      {!data?.searchInterviewCollections?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
          gap={3}
        >
          <Box
            textAlign="center"
            maxWidth="450px"
          >
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
            >
              Get started with your first collection
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Create a collection to organize and manage your media in one place
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box>
          {data?.searchInterviewCollections?.map(
            (collection: InterviewCollection) => (
              <Box
                mb={4}
                key={collection.collectionId}
              >
                <CollectionCard collection={collection} />
              </Box>
            ),
          )}
        </Box>
      )}

      <CreateModalCollection
        isOpen={isOpen}
        closeModal={closeModal}
        onSubmitForm={onSubmitForm}
        register={register}
        errors={errors}
        isSubmitting={isSubmitting}
        isValid={isValid}
      />
    </>
  );
}

export default DataInterviews;
