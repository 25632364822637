import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Loading } from 'components/atoms';
import { useSnackbar } from 'state/context/snackBar';
import { Button } from 'components/button';
import { SuperfeelUser } from '@superfeel/types';
import { UserAvatar } from 'components/atoms/avatar';
import { userFields } from './local';
import SectionCard from './components/section';
import { EditUserModal } from '../components/UpdateUserModal';
import { useAdminGetUserQuery } from '../api/user';
import { UpdateProfilePictureModal } from '../components/UpdateProfilePictureModal';

export default function UserHome() {
  const { id } = useParams<{ id: string }>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);

  const { showSnackbar } = useSnackbar();

  const { data, isLoading, isError, error } = useAdminGetUserQuery(
    {
      input: {
        username: id || '',
      },
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  );
  const user = data?.adminGetUser?.user as SuperfeelUser;

  if (isLoading) {
    return <Loading minHeight="50vh" />;
  }

  if (isError) {
    showSnackbar(
      error instanceof Error ? error.message : 'Failed to fetch user',
      'error',
    );
    return (
      <Typography
        variant="h6"
        color="error"
      >
        Error loading user data. Please try again later.
      </Typography>
    );
  }

  if (!user) {
    return <Typography variant="h6">No user data available.</Typography>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        gap={1}
        mb={2}
      >
        <UserAvatar
          user={user}
          size="s"
        />
        <Button
          label="Edit Profile Picture"
          onClick={() => setProfileModalOpen(true)}
          size="small"
        />
        <Button
          label="Edit User"
          onClick={() => setIsEditModalOpen(true)}
          size="small"
        />
      </Box>

      <SectionCard
        title="User Information"
        fields={userFields}
        user={{
          ...user,
          membershipType: user?.membership?.type || 'STANDARD',
          isVirtual: !!user?.isVirtual,
        }}
      />

      <EditUserModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        user={user}
      />
      <UpdateProfilePictureModal
        isOpen={isProfileModalOpen}
        user={user}
        onClose={() => setProfileModalOpen(false)}
      />
    </Box>
  );
}
