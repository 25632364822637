import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Edit, Mic, Chat, Visibility } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { TwinModelConfig } from 'routes/graphql.generated';
import { useMemo, useState } from 'react';
import { ConfirmDialog } from 'components/atoms/dialog';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { TwinModelTtsConfig, TwinPromptConfig } from '@superfeel/types';
import { checkPromptValid } from 'utils/checkPromptValid';

interface TwinModelConfigCardProps {
  config: TwinModelConfig;
  onEdit?: (config: TwinModelConfig) => void;
  onDelete?: (config: TwinModelConfig) => void;
  onCall?: (config: TwinModelConfig) => void;
  onEditVoice?: (config: TwinModelTtsConfig) => void;
  onEditPrompt?: (config: TwinPromptConfig) => void;
  onSelectVoice?: (config: TwinModelConfig) => void;
  onSelectPrompt?: (config: TwinModelConfig) => void;
}

export default function TwinModelConfigCard({
  config,
  onEdit = () => {},
  onDelete = () => {},
  onCall = () => {},
  onEditVoice = () => {},
  onEditPrompt = () => {},
  onSelectVoice = () => {},
  onSelectPrompt = () => {},
}: TwinModelConfigCardProps) {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const isIncomplete = useMemo(
    () =>
      !config.selectedTtsConfigId ||
      !config.selectedPromptConfigId ||
      !config.selectedAsrConfigId ||
      !checkPromptValid(config.promptConfig),
    [
      config.promptConfig,
      config.selectedAsrConfigId,
      config.selectedPromptConfigId,
      config.selectedTtsConfigId,
    ],
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <Typography variant="h5">
            {config.name || 'Untitled Twin Config'}
          </Typography>
        }
        subheader={
          <Typography
            variant="body2"
            color="text.secondary"
          >
            Type: {config.twinType}
          </Typography>
        }
        action={
          <Box>
            <ButtonIconTooltip
              icon="call"
              onClick={() => onCall(config)}
              tooltipTitle={
                isIncomplete ? 'Cannot call - config is incomplete' : ''
              }
              tooltipColorVariant="error"
              isDisabled={isIncomplete}
            />
            <IconButton onClick={() => onEdit(config)}>
              <Edit />
            </IconButton>
            <ButtonIconTooltip
              icon="delete"
              isLoading={isDeleting}
              onClick={() => setShowConfirmDelete(true)}
              tooltipTitle={
                config.visibility !== 'HIDDEN'
                  ? 'Must be in a HIDDEN state before deletion'
                  : ''
              }
              tooltipColorVariant="error"
              isDisabled={config.visibility !== 'HIDDEN'}
            />
          </Box>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'flex-row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Description:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {config.twinDescription || 'No description provided.'}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <Tooltip
              title="Current visibility"
              placement="top"
            >
              <Visibility sx={{ mr: 2 }} />
            </Tooltip>
            <Typography>{config.visibility || 'UNKNOWN'}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <Tooltip
              title="Selected voice"
              placement="top"
            >
              <Mic sx={{ mr: 2 }} />
            </Tooltip>
            <Typography
              fontStyle={config.ttsConfig?.ttsConfigId ? 'none' : 'italic'}
            >
              {config.ttsConfig?.voiceId || 'None'}
            </Typography>
            {!!config.ttsConfig?.ttsConfigId && (
              <ButtonIconTooltip
                icon="edit"
                tooltipTitle="Edit voice config"
                tooltipColorVariant="info"
                tooltipPlacement="top"
                onClick={() => onEditVoice(config.ttsConfig)}
                sx={{ ml: 1 }}
              />
            )}
            <ButtonIconTooltip
              icon="swap"
              tooltipTitle="Change voice config"
              tooltipColorVariant="info"
              tooltipPlacement="top"
              onClick={() => onSelectVoice(config)}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <Tooltip
              title="Selected prompt"
              placement="top"
            >
              <Chat sx={{ mr: 2 }} />
            </Tooltip>
            <Typography
              fontStyle={
                config.promptConfig?.promptConfigId ? 'none' : 'italic'
              }
            >
              {config.promptConfig?.configName || 'None'}
            </Typography>
            {!!config.promptConfig?.promptConfigId && (
              <ButtonIconTooltip
                icon="edit"
                tooltipTitle="Edit prompt config"
                tooltipColorVariant="info"
                tooltipPlacement="top"
                onClick={() => onEditPrompt(config.promptConfig)}
                sx={{ ml: 1 }}
              />
            )}
            <ButtonIconTooltip
              icon="swap"
              tooltipTitle="Change prompt config"
              tooltipColorVariant="info"
              tooltipPlacement="top"
              onClick={() => onSelectPrompt(config)}
            />
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Date created:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.dateCreated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Last updated:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.lastUpdated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <ConfirmDialog
        title="Confirm delete"
        message="Are you sure you want to delete this config? This cannot be undone!"
        confirmLabel="Yes, continue"
        isShown={showConfirmDelete}
        onConfirm={() => {
          setShowConfirmDelete(false);
          setIsDeleting(true);
          onDelete(config);
        }}
        onClose={() => setShowConfirmDelete(false)}
        onReject={() => setShowConfirmDelete(false)}
      />
    </Card>
  );
}
