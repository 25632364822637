import { Box, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Button } from 'components/button';
import { useState } from 'react';
import { DataInterviews } from './components';

export default function UserData() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
        >
          User Media
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Button
            startIcon={<Add />}
            size="medium"
            onClick={() => setIsOpen(true)}
            variant="contained"
            color="primary"
            label="Add collection"
          />
        </Box>
      </Box>
      <DataInterviews
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </Box>
  );
}
