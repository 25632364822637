import { useLocation, useParams } from 'react-router-dom';
import { SuperfeelUser } from '@superfeel/types';
import { useAdminGetUserQuery } from '../../api/user';

/**
 * This hook is used to get data about the target user.
 * If user is not present it will perform a fetch to get the data.
 * @returns target user of the /user path.
 */
export default function useGetTargetUser() {
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();

  if (!id) throw new Error('Please provide a username');
  if (!pathname.includes('/user'))
    throw new Error('hook can only be used inside of the user route.');

  const { data, isLoading, error } = useAdminGetUserQuery({
    input: {
      username: id,
    },
  });

  return {
    user: data?.adminGetUser.user as SuperfeelUser,
    isLoadingUser: isLoading,
    error,
  };
}
