/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { TextField } from 'components/atoms';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import * as Yup from 'yup';
import { TwinModelTtsConfig } from 'routes/graphql.generated';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dropzone } from 'components/atoms/dropzone';

export type LanguageOption = 'ENGLISH' | 'SPANISH' | 'FRENCH';
export interface EditVoiceFromValues {
  name: string;
  language: LanguageOption;
  audioFile: File | null;
}

interface EditVoiceFormProps {
  onCloseModal: () => void;
  onSubmit: (data: EditVoiceFromValues) => Promise<void>;
  isSubmitingVoiceForm: boolean;
  status: string;
  initialVoiceConfig: TwinModelTtsConfig | null;
}

// Yup validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .max(255, 'Name cannot exceed 255 characters'), // You can add any other constraints for name length if needed.
  language: Yup.mixed()
    .oneOf(['ENGLISH', 'SPANISH', 'FRENCH'], 'Invalid language selection')
    .required('Language is required'),
  audioFile: Yup.mixed()
    .required('Audio file is required')
    .test('fileSize', 'File size must be between 50 KB and 50 MB', (value) => {
      if (value && (value as File).size) {
        const file = value as File;
        return file.size >= 50 * 1024 && file.size <= 50 * 1024 * 1024; // Size between 50 KB and 50 MB
      }
      return false;
    })
    .test('fileType', 'Invalid file type', (value) => {
      if (value && (value as File).type) {
        const file = value as File;
        return file.type.startsWith('audio/');
      }
      return false;
    }),
});

export default function EditVoiceForm({
  onCloseModal,
  status,
  isSubmitingVoiceForm,
  onSubmit,
}: EditVoiceFormProps) {
  const [audioPreview, setAudioPreview] = useState('');
  const [audioFile, setAudioFile] = useState<File | null>(null);
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<EditVoiceFromValues>({
    // @ts-expect-error - Types complaining but there is nothing wrong with the validation.
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      language: 'ENGLISH',
      audioFile: null,
    },
    mode: 'onChange',
  });

  return (
    <Box
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gap={3}
      p={3}
      borderRadius={2}
      minWidth={400}
      mx="auto"
      mt={5}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Name"
            placeholder="Enter name"
            error={!!error}
            helperText={error ? error.message : ''}
            fullWidth
          />
        )}
      />

      <Controller
        name="language"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            label="Language"
            select
            error={!!error}
            helperText={error ? error.message : ''}
            fullWidth
          >
            {['ENGLISH', 'SPANISH', 'FRENCH'].map((type) => (
              <MenuItem
                key={type}
                value={type}
              >
                {type}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <Controller
        name="audioFile"
        control={control}
        render={({ field, fieldState }) =>
          audioPreview && !fieldState.invalid ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Typography
                variant="body2"
                color="primary.main"
                textAlign="center"
                sx={{ paddingY: 1 }}
              >
                {audioFile.name}
              </Typography>
              <audio controls>
                <track kind="captions" />
                <source
                  src={audioPreview}
                  type="audio/mp3"
                />
                Your browser does not support the audio element.
              </audio>
              <Button
                onClick={() => {
                  field.onChange(null);
                  setAudioFile(null);
                  setAudioPreview('');
                }}
              >
                Discard
              </Button>
            </Box>
          ) : (
            <Dropzone
              acceptFileTypes="audio/*"
              label="Drag voice sample here"
              onFilesChange={(files) => {
                const file = files.item(0);
                if (!file) {
                  return;
                }
                field.onChange(file);
                setAudioFile(file);
                setAudioPreview(URL.createObjectURL(file));
              }}
            />
          )
        }
      />
      {errors.audioFile?.message && (
        <Typography
          variant="body2"
          color="error"
          textAlign="center"
        >
          {errors.audioFile?.message}
        </Typography>
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
      >
        {status && (
          <>
            <Typography>{status}</Typography>
            {isSubmitingVoiceForm && <CircularProgress size={14} />}
          </>
        )}
        <Button
          variant="outlined"
          onClick={onCloseModal}
          disabled={isSubmitingVoiceForm}
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitingVoiceForm}
          disabled={isSubmitingVoiceForm || !isValid}
        >
          Create voice
        </LoadingButton>
      </Box>
    </Box>
  );
}
