import { signUp, resetPassword, CodeDeliveryDetails } from 'aws-amplify/auth';
import { v4 as uuid } from 'uuid';
import { Provider as ReduxProvider } from 'react-redux';
import { Authenticator, Text } from '@aws-amplify/ui-react';
import { ThemeProvider } from '@mui/material';
import {
  DailyAudio,
  DailyProvider,
  useCallObject,
} from '@daily-co/daily-react';
import { FC, useCallback, useState } from 'react';
import { EmbeddedTwinChat } from 'components/widget';
import { widgetStore } from 'store/widget.index';
import { useQueryParams } from 'hooks';
import { darkTheme } from './theme';
import '@aws-amplify/ui-react/styles.css';

type WidgetProps = {
  deploymentId?: string;
  height?: string | number;
  width?: string | number;
};

const Widget: FC<WidgetProps> = function Widget({
  deploymentId = '',
  height = 0,
  width = 0,
}: WidgetProps) {
  const callObject = useCallObject({
    options: { videoSource: false, audioSource: true, startAudioOff: false },
  });
  const { queryParams } = useQueryParams();
  const [isAwaitingConfirm, setIsAwaitingConfirm] = useState(false);
  const [resetPasswordDetails, setResetPasswordDetails] = useState<
    CodeDeliveryDetails | undefined
  >({});

  const customSignupFooter = useCallback(() => {
    return isAwaitingConfirm ? (
      <p style={{ textAlign: 'center' }}>
        A confirmation link has been sent to your email.
        <br />
        Please click it to complete sign in
      </p>
    ) : null;
  }, [isAwaitingConfirm]);

  const customResetPasswordHeader = useCallback(() => {
    if (!resetPasswordDetails) {
      return null;
    }
    return (
      <Text>
        A reset code has been sent to your registered{' '}
        {resetPasswordDetails.attributeName?.replace('_', ' ')}{' '}
        {resetPasswordDetails.destination}.<br />
        Your new password must be at least 8 characters long and contain
        uppercase letters, lowercase letters and numbers.
      </Text>
    );
  }, [resetPasswordDetails]);

  const customSignupFields = useCallback(() => {
    return (
      <>
        <Authenticator.SignUp.FormFields />
        <Text>
          Your password must be at least 8 characters long and contain uppercase
          letters, lowercase letters and numbers.
        </Text>
      </>
    );
  }, []);

  const customAuthHeader = useCallback(() => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '20px',
        }}
      >
        <img
          src="/img/hours-logo.png"
          alt="Logo"
          style={{ width: '100px' }}
        />
      </div>
    );
  }, []);

  return (
    <Authenticator
      signUpAttributes={['name']}
      variation="default"
      services={{
        handleSignUp: async (input) => {
          const res = await signUp({ ...input, username: uuid() });
          setIsAwaitingConfirm(
            res.nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN',
          );
          return res;
        },
        handleForgotPassword: async (input) => {
          const res = await resetPassword(input);
          setResetPasswordDetails(res.nextStep.codeDeliveryDetails);
          return res;
        },
      }}
      components={{
        SignUp: {
          FormFields: customSignupFields,
          Footer: customSignupFooter,
        },
        ConfirmResetPassword: {
          Header: customResetPasswordHeader,
        },
        Header: customAuthHeader,
      }}
      formFields={{
        signUp: {
          name: {
            order: 1,
          },
          email: {
            order: 2,
          },
          password: {
            order: 3,
          },
          confirm_password: {
            order: 4,
          },
        },
      }}
    >
      <ThemeProvider theme={darkTheme}>
        <ReduxProvider store={widgetStore}>
          <DailyProvider callObject={callObject}>
            <DailyAudio />
            <EmbeddedTwinChat
              deploymentId={deploymentId || queryParams?.id}
              height={height}
              width={width}
            />
          </DailyProvider>
        </ReduxProvider>
      </ThemeProvider>
    </Authenticator>
  );
};

export default Widget;
