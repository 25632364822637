import { configureStore } from '@reduxjs/toolkit';
import { restApiSlice } from 'components/widget/api';

export const widgetStore = configureStore({
  reducer: {
    [restApiSlice.reducerPath]: restApiSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling, and other
  // useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(restApiSlice.middleware),
});
