import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useAppContext } from 'state/context/app';
import { useNavigate, useLocation } from 'react-router-dom';
import { SideBarItemProps } from 'types/app';

function SidebarItem({
  title,
  icon,
  path,
  user,
}: SideBarItemProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen } = useAppContext();

  const currentPath = path(user?.username);
  const isSelected =
    location.pathname === currentPath ||
    (title === 'Users' &&
      (location.pathname === '/' || location.pathname.includes('/user/')));

  return (
    <ListItem
      key={title}
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        onClick={() => navigate(currentPath)}
        selected={isSelected}
        sx={{
          minHeight: 48,
          justifyContent: drawerOpen ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: drawerOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          sx={{ opacity: drawerOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default SidebarItem;
