import { AdminGetSignedS3UrlInput, UploadUrl } from '@superfeel/types';
import { usersApi } from 'routes/user/api/user';
import { store } from 'store';

export async function getSignedS3Url({
  key,
  method = 'POST',
  type = 'INTERVIEW',
  expiry = 60 * 60 * 24,
  partParams,
  headers,
}: AdminGetSignedS3UrlInput): Promise<UploadUrl> {
  const { data, isError } = await store.dispatch(
    usersApi.endpoints.AdminGetSignedS3Url.initiate(
      {
        input: {
          key,
          method,
          expiry,
          type,
          headers: headers ? JSON.stringify(headers) : undefined,
          partParams,
        },
      },
      { forceRefetch: true },
    ),
  );

  if (isError) {
    throw new Error('Error could not upload file');
  }

  if (method === 'POST' && !data?.adminGetSignedS3Url?.uploadId) {
    throw new Error('Could not get file upload iD');
  }

  if (method === 'PUT' && !data?.adminGetSignedS3Url?.url) {
    throw new Error('Could not get file upload URL');
  }
  return data?.adminGetSignedS3Url;
}
