import { Box, Typography, Slider, CircularProgress } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SuperfeelUser, TwinModelTtsConfig } from '@superfeel/types';
import { useAdminUpdateVoiceSampleMutation } from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';
import { TextField } from 'components/atoms';
import { Button } from 'components/button';
import { generateSampleText } from 'utils/generateSampleText';

export interface EditVoiceSettingsValues {
  speed: number;
}

interface EditVoiceSettingsFormProps {
  onCloseModal: () => void;
  voiceSampleConfig: TwinModelTtsConfig;
  targetUser: SuperfeelUser;
}

export default function EditVoiceSpeedForm({
  voiceSampleConfig,
  targetUser,
}: EditVoiceSettingsFormProps) {
  const { showSnackbar } = useSnackbar();
  const [sliderValue, setSliderValue] = useState(voiceSampleConfig.speed);
  const [voiceSpeed, setVoiceSpeed] = useState(voiceSampleConfig.speed);
  const [audioUri, setAudioUri] = useState(voiceSampleConfig.voiceSampleUri);
  const [sampleText, setSampleText] = useState(
    voiceSampleConfig.voiceSampleText || generateSampleText(targetUser),
  );
  const [submittedSampleText, setSubmittedSampleText] = useState(sampleText);
  const [updateVoiceSampleMutation, { isLoading }] =
    useAdminUpdateVoiceSampleMutation();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const { control } = useForm<EditVoiceSettingsValues>({
    defaultValues: {
      speed: voiceSampleConfig.speed,
    },
    mode: 'onChange',
  });

  const updateVoiceSample = useCallback(
    (speed: number, text: string) => {
      updateVoiceSampleMutation({
        input: {
          speed,
          targetUserId: targetUser.userId,
          ttsConfigId: voiceSampleConfig.ttsConfigId,
          text,
        },
      })
        .unwrap()
        .then((res) => {
          if (!res.adminUpdateTwinVoiceSample)
            throw new Error('issue saving voice sample');

          const updatedUri = res.adminUpdateTwinVoiceSample.voiceSampleUri;
          // Set the updated audio URI after mutation completes
          setAudioUri(updatedUri);
        })
        .catch((err) => {
          showSnackbar(
            `Error updating voice sample: ${(err as Error)?.message || 'Unknown error'}`,
          );
        });
    },
    [
      showSnackbar,
      targetUser.userId,
      updateVoiceSampleMutation,
      voiceSampleConfig.ttsConfigId,
    ],
  );

  const handleSpeedChange = useCallback(
    (_: Event, val: number | number[]) => {
      const newSpeed = typeof val === 'number' ? val : val[0];
      setVoiceSpeed(newSpeed);

      const text = sampleText.trim();
      setSubmittedSampleText(text);
      updateVoiceSample(newSpeed, text);
    },
    [sampleText, updateVoiceSample],
  );

  // Ensure the audio player pauses when isLoading is true
  useEffect(() => {
    if (audioRef.current) {
      if (isLoading) {
        audioRef.current.pause(); // Pause when loading
      } else {
        audioRef.current.play(); // Play when not loading
      }
    }
  }, [isLoading, audioUri]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      gap={3}
      p={3}
      borderRadius={2}
      minWidth={400}
      mx="auto"
      mt={5}
    >
      <Typography
        variant="body1"
        color="text.primary"
        textAlign="center"
      >
        Drag the slider to change the voice speed
      </Typography>
      <Controller
        name="speed"
        control={control}
        render={({ field }) => (
          <Box sx={{ width: '75%' }}>
            <Typography
              variant="body1"
              color="text.primary"
              textAlign="center"
            >
              Speed: {voiceSpeed.toFixed(2)}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                paddingY: 1,
              }}
            >
              <Typography
                variant="body1"
                color="text.primary"
                textAlign="center"
              >
                Slower
              </Typography>
              <Slider
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                value={sliderValue}
                disabled={isLoading || !sampleText.trim()}
                onChangeCommitted={handleSpeedChange}
                onChange={(_, val) =>
                  setSliderValue(typeof val === 'number' ? val : val[0])
                }
                min={0.8}
                max={1.2}
                step={0.01}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => value.toFixed(2)}
              />
              <Typography
                variant="body1"
                color="text.primary"
                textAlign="center"
              >
                Faster
              </Typography>
            </Box>
          </Box>
        )}
      />

      <Box
        sx={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <TextField
          value={sampleText}
          multiline
          rows={4}
          maxLength={200}
          onChange={(ev) => setSampleText(ev.target.value)}
          label="Sample text"
          placeholder="Enter sample text to generate voice..."
        />
        <Button
          label="Regenerate"
          disabled={
            sampleText.trim() === submittedSampleText.trim() ||
            isLoading ||
            !sampleText.trim()
          }
          onClick={() => updateVoiceSample(voiceSpeed, sampleText)}
        />
      </Box>

      <Box
        sx={{
          paddingY: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        {isLoading ? (
          <>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Regenerating voice...
            </Typography>
            <CircularProgress size={20} />
          </>
        ) : (
          <audio
            ref={audioRef}
            key={audioUri}
            style={{ width: '80%' }}
            controls
          >
            <track kind="captions" />
            <source
              src={audioUri}
              type="audio/mp3"
            />
            Your browser does not support the audio element.
          </audio>
        )}
      </Box>
    </Box>
  );
}
