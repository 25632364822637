import {
  isSupportedCountry,
  parsePhoneNumber,
  getCountryCallingCode,
} from 'libphonenumber-js/mobile';

export function validateAndTransformPhoneNumber(
  raw: string,
): [boolean, string] {
  try {
    let cleanedNumber = raw.replace(/[^\d+]/g, '');
    // Parse number to get phone number.
    const initialParsed = parsePhoneNumber(cleanedNumber);
    const country = initialParsed?.country;
    if (country) {
      const areaCode = getCountryCallingCode(country);
      const areaCodeStr = `+${areaCode}`;
      // Check if there's a leading zero after the area code and remove it.
      if (cleanedNumber.startsWith(areaCodeStr)) {
        const withoutAreaCode = cleanedNumber.slice(areaCodeStr.length);
        if (withoutAreaCode.startsWith('0')) {
          cleanedNumber = areaCodeStr + withoutAreaCode.slice(1);
        }
      }
    }

    const parsed = parsePhoneNumber(cleanedNumber);
    const isValid =
      parsed?.isValid() && isSupportedCountry(parsed.country || '');

    let formattedNumber: string;
    if (isValid) {
      // Remove all spaces.
      formattedNumber = parsed.formatInternational().replace(/\s+/g, '');
    } else {
      formattedNumber = cleanedNumber;
    }

    return [isValid, formattedNumber];
  } catch (err) {
    return [false, raw];
  }
}
