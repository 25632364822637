import TheaterComedy from '@mui/icons-material/TheaterComedy';
import Article from '@mui/icons-material/Article';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import Person from '@mui/icons-material/Person';
import KeyboardVoice from '@mui/icons-material/KeyboardVoice';
import Chat from '@mui/icons-material/Chat';
import Assistant from '@mui/icons-material/Assistant';
import AddReaction from '@mui/icons-material/AddReaction';
import People from '@mui/icons-material/People';

import { SideBarItemProps } from 'types/app';

export const UserSideBarItems: SideBarItemProps[] = [
  { title: 'User', icon: <Person />, path: (id) => `/user/${id}` },
  {
    title: 'Heirs',
    icon: <Assistant />,
    path: (id) => `/user/${id}/heirs`,
  },
  {
    title: 'Voices',
    icon: <KeyboardVoice />,
    path: (id) => `/user/${id}/voice`,
  },
  {
    title: 'System Prompts',
    icon: <Chat />,
    path: (id) => `/user/${id}/prompts`,
  },
  {
    title: 'Media',
    icon: <TheaterComedy />,
    path: (id) => `/user/${id}/data`,
  },
  {
    title: 'Documents',
    icon: <Article />,
    path: (id) => `/user/${id}/documents`,
  },
];

export const MainSideBarItems: SideBarItemProps[] = [
  { title: 'Users', icon: <People />, path: () => '/' },
  { title: 'Invite', icon: <AddReaction />, path: () => '/invite' },
  { title: 'Prompt', icon: <QuestionAnswer />, path: () => '/prompt' },
];
