/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import isEmail from 'validator/es/lib/isEmail';
import { yupResolver } from '@hookform/resolvers/yup';
import { validateAndTransformPhoneNumber } from 'utils/validate-phonenumber';
import * as yup from 'yup';
import { Box, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useSnackbar } from 'state/context/snackBar';
import { Modal, TextField } from 'components/atoms';
import { AdminUserInfoInput } from '@superfeel/types';
import { Button } from 'components/button';
import {
  useAdminCreateUserMutation,
  useAdminSearchUsersQuery,
} from '../api/user';

const schemaValidation = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    username: yup
      .string()
      .required('Username is required')
      .min(3, 'Username must be at least 3 characters')
      .max(15, 'Username must be 15 characters or less')
      .matches(
        /^[a-z0-9_]+$/,
        'Username can only contain lower case letters, numbers, and underscores',
      ),
    phone: yup.string().when('isVirtual', {
      is: false,
      then: (schema) =>
        schema
          .required('Phone number is required')
          .defined()
          .test('valid-phone', 'Invalid phone number', (value) => {
            if (!value) return false;
            const [isPhoneValid] = validateAndTransformPhoneNumber(value);
            return isPhoneValid;
          }),
      otherwise: (schema) => schema.notRequired(),
    }),
    email: yup
      .string()
      .notRequired()
      .test('valid-email', 'Invalid email', (value) => {
        if (!value) return true;
        return isEmail(value);
      }),
    isVirtual: yup.boolean().required().default(false),
    isFlagged: yup.boolean().required().default(false),
    userId: yup.string().notRequired(),
  })
  .required();

interface AddUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function AddUserModal({ isOpen, onClose }: AddUserModalProps) {
  const { showSnackbar } = useSnackbar();
  const [createUser, { isLoading }] = useAdminCreateUserMutation();
  // FIXME: We are manually refetching the query rather than relying on cache
  // invalidation.
  const { refetch } = useAdminSearchUsersQuery({
    input: {
      includeHidden: true,
      limit: 10,
      startIndex: 0,
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, touchedFields },
    reset,
  } = useForm({
    resolver: yupResolver(schemaValidation),
    defaultValues: {
      firstName: '',
      lastName: '',
      username: '',
      phone: '',
      email: '',
      isVirtual: false,
      isFlagged: false,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit: SubmitHandler<AdminUserInfoInput> = useCallback(
    async (data) => {
      try {
        const [isPhoneValid, phone] = data.phone
          ? validateAndTransformPhoneNumber(data.phone)
          : [true, undefined];
        if (!isPhoneValid) {
          throw new Error('Invalid phone number');
        }
        await createUser({
          input: {
            action: 'CREATE',
            user: {
              ...data,
              username: data.username.toLowerCase().trim(),
              firstName: data.firstName.trim(),
              lastName: data.lastName.trim(),
              phone,
            },
          },
        }).unwrap();

        showSnackbar('User created successfully', 'success');
        refetch();
        reset();
        onClose();
      } catch (error) {
        showSnackbar(
          error instanceof Error ? error.message : 'Failed to create user',
          'error',
        );
      }
    },
    [createUser, showSnackbar, reset, onClose, refetch],
  );

  const isProcessing = isLoading || isSubmitting;

  return (
    <Modal
      title="Add New User"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
        sx={{ p: 2 }}
      >
        <Stack spacing={3}>
          <Box
            display="flex"
            gap={2}
          >
            <TextField
              {...register('firstName')}
              label="First Name"
              error={touchedFields.firstName && !!errors.firstName}
              helperText={touchedFields.firstName && errors.firstName?.message}
              fullWidth
              disabled={isProcessing}
            />
            <TextField
              {...register('lastName')}
              label="Last Name"
              error={touchedFields.lastName && !!errors.lastName}
              helperText={touchedFields.lastName && errors.lastName?.message}
              fullWidth
              disabled={isProcessing}
            />
          </Box>

          <Box
            display="flex"
            gap={2}
          >
            <TextField
              {...register('username')}
              label="Username"
              error={touchedFields.username && !!errors.username}
              helperText={touchedFields.username && errors.username?.message}
              fullWidth
              disabled={isProcessing}
            />
            <TextField
              {...register('phone')}
              label="Phone"
              error={touchedFields.phone && !!errors.phone}
              helperText={touchedFields.phone && errors.phone?.message}
              fullWidth
              disabled={isProcessing}
            />
          </Box>

          {/* <TextField
            {...register('email')}
            label="Email"
            error={touchedFields.email && !!errors.email}
            helperText={touchedFields.email && errors.email?.message}
            fullWidth
            disabled={isProcessing}
          /> */}

          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="text.primary"
            >
              Virtual user
            </Typography>
            <Controller
              name="isVirtual"
              control={control}
              render={({ field }) => (
                <Switch
                  color="primary"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
            <Tooltip
              title="Virtual users can have Heirs but do not have a real person controlling their account"
              arrow
            >
              <InfoOutlined
                sx={{ ml: 1, fontSize: 20, color: 'action.active' }}
              />
            </Tooltip>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
          >
            <Button
              onClick={onClose}
              disabled={isProcessing}
              label="Cancel"
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isProcessing}
              label={isProcessing ? 'Adding User...' : 'Add User'}
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}
