import { FileUpload } from '@superfeel/types';
import { finalizeS3Upload } from './finalizeS3Upload';
import { getSignedS3Url } from './getSignedS3Url';

const MAX_CONCURRENT_UPLOADS = 10;
const PART_SIZE = 5 * 1024 * 1024; // 5MB part size
const URL_EXPIRY_SECONDS = 60 * 60 * 24; // 24 hours

interface UploadOptions {
  file: File;
  onProgress: (percentage: number) => void;
  key: string;
  bucketName: string;
  uploadId: string;
}

interface UploadPart {
  ETag: string;
  PartNumber: number;
}

interface FileChunk {
  chunk: Blob;
  partNumber: number;
}

interface ProgressState {
  completed: number;
  total: number;
}

async function uploadSinglePart(
  url: string,
  part: Blob,
  partNumber: number,
  uploadId: string,
): Promise<UploadPart> {
  const headers = new Headers({
    'Content-Length': part.size.toString(),
  });

  const uploadUrl = new URL(url);
  uploadUrl.searchParams.append('partNumber', partNumber.toString());
  uploadUrl.searchParams.append('uploadId', uploadId);

  const response = await fetch(uploadUrl.toString(), {
    method: 'PUT',
    headers,
    body: part,
  });

  if (!response.ok) {
    throw new Error(
      `Failed to upload part ${partNumber}: ${response.status} ${response.statusText}`,
    );
  }

  const etag = response.headers.get('ETag');
  if (!etag) {
    throw new Error(`ETag not found in response for part ${partNumber}`);
  }

  return {
    ETag: etag.replace(/^"|"$/g, ''),
    PartNumber: partNumber,
  };
}

async function uploadFileChunk(
  chunk: Blob,
  partNumber: number,
  key: string,
  uploadId: string,
  onChunkComplete: () => void,
): Promise<UploadPart> {
  const { url } = await getSignedS3Url({
    key,
    method: 'PUT',
    partParams: { partNumber, uploadId },
    expiry: URL_EXPIRY_SECONDS,
    type: 'INTERVIEW',
  });

  const result = await uploadSinglePart(url, chunk, partNumber, uploadId);
  onChunkComplete();
  return result;
}

function createFileChunks(file: File): FileChunk[] {
  const chunkCount = Math.ceil(file.size / PART_SIZE);

  return Array.from({ length: chunkCount }, (_, index) => {
    const start = index * PART_SIZE;
    const end = Math.min(start + PART_SIZE, file.size);
    return {
      chunk: file.slice(start, end),
      partNumber: index + 1,
    };
  });
}

async function uploadPartsInParallel(
  file: File,
  key: string,
  uploadId: string,
  onProgress: (percentage: number) => void,
): Promise<UploadPart[]> {
  const chunks = createFileChunks(file);
  const completedParts: UploadPart[] = [];
  const progressState: ProgressState = {
    completed: 0,
    total: chunks.length,
  };

  async function processChunkBatch(
    remainingChunks: FileChunk[],
    activeUploads: Map<number, Promise<UploadPart>>,
  ): Promise<void> {
    if (remainingChunks.length === 0 && activeUploads.size === 0) {
      return;
    }

    while (
      activeUploads.size < MAX_CONCURRENT_UPLOADS &&
      remainingChunks.length > 0
    ) {
      const chunk = remainingChunks.shift();
      const uploadPromise = uploadFileChunk(
        chunk.chunk,
        chunk.partNumber,
        key,
        uploadId,
        () => {
          progressState.completed += 1;
          onProgress((progressState.completed / progressState.total) * 100);
        },
      ).then((result) => {
        activeUploads.delete(chunk.partNumber);
        completedParts.push(result);
        return result;
      });

      activeUploads.set(chunk.partNumber, uploadPromise);
    }

    if (activeUploads.size > 0) {
      await Promise.race(Array.from(activeUploads.values()));
      await processChunkBatch(remainingChunks, activeUploads);
    }
  }

  await processChunkBatch([...chunks], new Map());
  return completedParts.sort((a, b) => a.PartNumber - b.PartNumber);
}

export async function fileUpload({
  file,
  onProgress,
  key,
  uploadId,
}: UploadOptions): Promise<FileUpload> {
  try {
    const uploadedParts = await uploadPartsInParallel(
      file,
      key,
      uploadId,
      onProgress,
    );
    return await finalizeS3Upload(key, uploadId, uploadedParts);
  } catch (error) {
    throw new Error(
      `Error uploading file: ${error instanceof Error ? error.message : String(error)}`,
    );
  }
}
