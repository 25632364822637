import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import { TwinMedia } from '@superfeel/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTwinConversation } from 'hooks';
import { useDailyEvent } from '@daily-co/daily-react';
import { useAdminSearchTwinMediaQuery } from 'routes/user/api/user';

type MediaPlayerInput = {
  modelConfigId: string;
  userId: string;
};

const DEFAULT_SELECT_VALUE = '_default';

export function TwinMediaPlayer({ modelConfigId, userId }: MediaPlayerInput) {
  const { sendAppMessage, callDuration, callState } = useTwinConversation();

  const { data, isLoading } = useAdminSearchTwinMediaQuery(
    {
      input: { targetModelConfigId: modelConfigId, targetUserId: userId },
    },
    { skip: !userId || !modelConfigId },
  );

  const [isPlaying, setPlaying] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState<TwinMedia | undefined>(
    undefined,
  );
  const [selectedMediaId, setSelectedMediaId] = useState(DEFAULT_SELECT_VALUE);
  const debouncedSeek = debounce(setPlaying, 200);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const twinMedia = useMemo(
    () => data?.adminSearchTwinMedia || [],
    [data?.adminSearchTwinMedia],
  );

  const handleMediaUpdate = useCallback(
    (positionSecs: number) => {
      if (!selectedMedia || callState !== 'STATE_ACTIVE') {
        return;
      }
      sendAppMessage('MEDIA_UPDATE', {
        media_id: selectedMedia.mediaId,
        timestamp: positionSecs * 1000,
      });
    },
    [callState, selectedMedia, sendAppMessage],
  );

  const handleMediaSelect = useCallback(
    (selectedId: string) => {
      setSelectedMediaId(selectedId);
      const selectedOption = twinMedia.find(
        ({ mediaId }) => mediaId === selectedId,
      );
      setSelectedMedia(selectedOption);
      handleMediaUpdate(0);
    },
    [handleMediaUpdate, twinMedia],
  );

  const handleActionMessage = useCallback(
    (msg: { action: string; parameters: Record<string, unknown> }) => {
      if (!msg.action || !audioRef.current) {
        return;
      }
      const { action } = msg;
      if (action === 'PLAY') {
        audioRef.current.play();
      }
      if (action === 'RESTART') {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
    },
    [],
  );

  useDailyEvent('app-message', (ev) =>
    handleActionMessage(
      ev.data as { action: string; parameters: Record<string, unknown> },
    ),
  );

  useEffect(() => {
    // send media update as call starts
    if (callDuration === 1 && callState === 'STATE_ACTIVE' && selectedMedia) {
      handleMediaUpdate(0);
    }
  }, [callState, callDuration, selectedMedia, handleMediaUpdate]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingY: 3,
        }}
      >
        <CircularProgress size={48} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Select
        autoWidth
        value={selectedMediaId}
        onChange={(ev) => handleMediaSelect(ev.target.value)}
        disabled={!twinMedia.length}
      >
        <MenuItem
          key="loading"
          value={DEFAULT_SELECT_VALUE}
          disabled
        >
          {twinMedia.length ? 'Select a chapter' : 'No media found'}
        </MenuItem>
        {twinMedia.map((el) => (
          <MenuItem
            key={el.mediaId}
            value={el.mediaId}
          >
            {el.mediaName}
          </MenuItem>
        ))}
      </Select>
      <Box
        sx={{
          width: '100%',
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <audio
          ref={audioRef}
          src={selectedMedia?.contentUri || undefined}
          controls
          onPlay={() => {
            setPlaying(true);
            handleMediaUpdate(audioRef.current?.currentTime);
          }}
          onPause={() => {
            setPlaying(false);
            handleMediaUpdate(audioRef.current?.currentTime);
          }}
          onEnded={() => setPlaying(false)}
          onSeeked={() => debouncedSeek(isPlaying)}
        >
          <track
            kind="captions"
            label="English"
            lang="en"
          />
        </audio>
      </Box>
    </Box>
  );
}
