import { Button } from 'components/button';
import { Box, Stack, Typography } from '@mui/material';
import { Modal } from 'components/atoms';

interface AbortUploadModalProps {
  isOpen: boolean;
  handleCancelNavigation: () => void;
  handleConfirmNavigation: () => void;
}

export function AbortUploadModal({
  isOpen,
  handleCancelNavigation,
  handleConfirmNavigation,
}: AbortUploadModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancelNavigation}
      title="Upload in Progress"
      minHeight={100}
    >
      <Box
        px={3}
        pb={3}
      >
        <Typography
          mb={3}
          variant="body2"
        >
          An upload is in progress. Are you sure you want to navigate away?
        </Typography>

        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-end"
          spacing={2}
        >
          <Button
            variant="contained"
            color="primary"
            label="Stay"
            onClick={handleCancelNavigation}
            width={100}
          />
          <Button
            variant="contained"
            color="secondary"
            label="Leave"
            onClick={handleConfirmNavigation}
            width={100}
          />
        </Stack>
      </Box>
    </Modal>
  );
}
