/* eslint-disable react/no-unstable-nested-components */
import { memo, useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Box, Chip, Button, Typography } from '@mui/material';
import * as Yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add } from '@mui/icons-material';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { executeGraphqlOperation } from 'api';
import { useSnackbar } from 'state/context/snackBar';
import { TableCustomized } from 'components/molecules';
import { adminSearchPrompts } from 'graphql/queries/prompt';
import { Column } from 'components/molecules/table';
import Layout from 'layouts';
import { useDebounce } from 'hooks';
import { PostType, PromptCategory, SuperfeelPrompt } from '@superfeel/types';
import { adminUpdatePromptMutation } from 'graphql/mutations/prompt';
import dayjs from 'dayjs';
import { CSVParseResult } from 'utils/csv-parser';
import { UploadPromptsModal } from './components';
import { CATEGORY, POST_TYPE } from '../../constants';
import { FilterType } from './components/filter-type';
import { FilterCategory } from './components/filter-category';
import { FilterPublishDate } from './components/filter-publish-date';
import formatDateFilter from './utils/formatDateFilter';

export type SuperFeelTableData = {
  body: string;
  category: PromptCategory;
  type: PostType;
  promptId: string;
  dateCreated: string;
  intensity: string;
  publishDate?: string;
};

export type PromptFilters = {
  type?: PostType;
  categories?: PromptCategory;
  fromDate?: string;
  toDate?: string;
};

type TableSort = {
  sort: 'dateCreated' | 'publishDate';
  sortDirection: 'asc' | 'desc';
};

export type FormFieldData = {
  prompts: Array<{
    body: string;
    category: PromptCategory;
    type: PostType;
    intensity: string;
    publishDate?: string;
    promptId?: string;
  }>;
};

export const CSVFormField = {
  body: 'body' as const,
  category: 'category' as const,
  type: 'type' as const,
  dateCreated: 'dateCreated' as const,
  intensity: 'intensity' as const,
  publishDate: 'publishDate' as const,
};

export type ModeType = 'VIEW' | 'UPDATE' | 'CREATE';

const formSchema = Yup.object().shape({
  prompts: Yup.array()
    .of(
      Yup.object().shape({
        body: Yup.string()
          .required('Body is required')
          .min(2, 'Body must be at least 2 characters')
          .max(255, 'Body must not exceed 255 characters'),
        category: Yup.string()
          .required('Category is required')
          .oneOf(
            CATEGORY.map((option) => option.value),
            'Invalid category',
          ) as Yup.StringSchema<PromptCategory>,
        type: Yup.string()
          .required('Type is required')
          .oneOf(
            POST_TYPE.map((option) => option.value),
            'Invalid type',
          ) as Yup.StringSchema<PostType>,
        intensity: Yup.string()
          .required('Intensity is required')
          .test(
            'is-number',
            'Intensity must be a number',
            (value) => !Number.isNaN(Number(value)),
          )
          .test(
            'is-between-0-and-3',
            'Intensity must be between 0 and 3',
            (value) => {
              const numberValue = Number(value);
              return numberValue >= 0 && numberValue <= 3;
            },
          ),
        promptId: Yup.string().optional(),
        publishDate: Yup.string()
          .nullable()
          .test('validDate', 'Invalid date format (mm-dd-yyyy)', (value) => {
            if (!value) return true;
            return (
              dayjs(value, 'MM-DD-YYYY').isValid() || dayjs(value).isValid()
            );
          }),
      }),
    )
    .required('At least one prompt is required')
    .min(1, 'At least one prompt is required'),
}) as Yup.ObjectSchema<FormFieldData>;

function Prompt() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prompts, setPrompts] = useState<SuperFeelTableData[] | null>(null);
  const [showCSVUpload, setShowCSVUpload] = useState<boolean>(false);
  const [mode, setMode] = useState<ModeType | null>('VIEW');
  const [selectedPrompt, setSelectedPrompt] =
    useState<SuperFeelTableData | null>(null);
  const [paginationState, setPaginationState] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
  });
  const [filters, setFilters] = useState<PromptFilters>({});
  const [tableSort, setTableSort] = useState<TableSort>({
    sort: 'publishDate',
    sortDirection: 'asc',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    formState: { isValid, errors, isSubmitting },
  } = useForm<FormFieldData>({
    defaultValues: {
      prompts: [
        {
          body: '',
          category: 'EMOTION',
          type: 'FOTD',
          intensity: '1',
          publishDate: undefined,
        },
      ],
    },
    // @ts-expect-error yupResolver form validation
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    shouldFocusError: true,
  });

  const categoryMapping = useMemo(
    () =>
      CATEGORY.reduce(
        (acc, { value, label }) => ({ ...acc, [value]: label }),
        {} as Record<PromptCategory, string>,
      ),
    [],
  );

  const postTypeMapping = useMemo(
    () =>
      POST_TYPE.reduce(
        (acc, { value, label }) => ({ ...acc, [value]: label }),
        {} as Record<PostType, string>,
      ),
    [],
  );

  const closeModal = useCallback(() => {
    setSelectedPrompt(null);
    setMode('VIEW');
    navigate('/prompt');
    setShowCSVUpload(false);
    reset({
      prompts: [
        {
          body: '',
          category: 'EMOTION',
          type: 'FOTD',
          intensity: '1',
          publishDate: undefined,
        },
      ],
    });
  }, [navigate, reset]);

  const handleCreatePrompt = useCallback(() => {
    setSelectedPrompt(null);
    setMode('CREATE');
    setShowCSVUpload(false);
    reset({
      prompts: [
        {
          body: '',
          category: 'EMOTION',
          type: 'FOTD',
          intensity: '1',
          publishDate: undefined,
        },
      ],
    });
    navigate('/prompt/new');
  }, [navigate, reset]);

  const fetchPrompts = useCallback(async () => {
    setIsLoading(true);
    try {
      const input = {
        limit: paginationState.rowsPerPage,
        startIndex: paginationState.page * paginationState.rowsPerPage,
        sortDirection: tableSort.sortDirection.toUpperCase(),
        search: debouncedSearchTerm || undefined,
        ...filters,
      };
      const { jwt } = await getCurrentAuthCredentials();
      const { data } = await executeGraphqlOperation<{
        adminSearchPrompts: {
          prompts: SuperFeelTableData[];
          totalCount: number;
        };
      }>(adminSearchPrompts, { input }, jwt);

      const result = data?.adminSearchPrompts ?? null;
      if (result) {
        setPaginationState((prev) => ({
          ...prev,
          totalCount: data?.adminSearchPrompts?.totalCount,
        }));
        setPrompts(result?.prompts);
      }
      return result;
    } catch (e) {
      showSnackbar(`Failed to fetch prompts: ${e as string}`);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [
    paginationState.rowsPerPage,
    paginationState.page,
    tableSort.sortDirection,
    debouncedSearchTerm,
    filters,
    showSnackbar,
  ]);

  const handleSort = useCallback(
    (_property: keyof SuperFeelTableData, order: 'asc' | 'desc') => {
      setTableSort({
        sort: 'dateCreated',
        sortDirection: order,
      });
    },
    [],
  );

  const handlePageChange = useCallback((newPage: number) => {
    setPaginationState((prev) => ({ ...prev, page: newPage }));
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setPaginationState((prev) => ({
      ...prev,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const handlePromptClick = useCallback(
    (prompt: SuperFeelTableData) => {
      setSelectedPrompt(prompt);
      setMode('UPDATE');
      navigate(`/prompt/${prompt.promptId}`);
      reset({
        prompts: [
          {
            body: prompt.body,
            category: prompt.category,
            type: prompt.type,
            intensity: prompt.intensity,
            publishDate: prompt.publishDate
              ? DateTime.fromISO(prompt.publishDate).toFormat('MM-DD-YYYY')
              : undefined,
            promptId: prompt.promptId,
          },
        ],
      });
    },
    [navigate, reset],
  );

  const onFilter = useCallback((values: PromptFilters) => {
    setFilters((prev) => ({
      ...prev,
      ...values,
    }));
  }, []);

  const addFilterIfExists = useCallback(
    (filterMap: PromptFilters, key: keyof PromptFilters, label: string) => {
      if (filterMap[key]) {
        let value = filterMap[key];
        if (key === 'type') {
          value = POST_TYPE.find((pt) => pt.value === value)?.label || value;
        } else if (key === 'categories') {
          value = CATEGORY.find((cat) => cat.value === value)?.label || value;
        }
        return [{ label, value }];
      }
      return [];
    },
    [],
  );

  const handleRemoveFilter = useCallback((label: string) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (label === 'Type') {
        delete newFilters.type;
        delete newFilters.fromDate;
        delete newFilters.toDate;
      }
      if (label === 'Category') delete newFilters.categories;
      if (label === 'Published') {
        delete newFilters.fromDate;
        delete newFilters.toDate;
      }
      return newFilters;
    });
  }, []);

  const getTableFilters = useMemo(() => {
    const filterArray = [
      ...addFilterIfExists(filters, 'type', 'Type'),
      ...addFilterIfExists(filters, 'categories', 'Category'),
    ];

    if (filters.type === 'FOTD' && (filters.fromDate || filters.toDate)) {
      filterArray.push({
        label: 'Published',
        value: formatDateFilter(filters.fromDate, filters.toDate),
      });
    }

    return filterArray;
  }, [filters, addFilterIfExists]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPaginationState((prev) => ({ ...prev, page: 0 }));
  };

  const handleCSVData = useCallback(
    async (
      data: Partial<SuperFeelTableData>[],
      result: CSVParseResult<SuperFeelTableData>,
    ) => {
      if (result.success) {
        const formattedPrompts = data.map((row) => ({
          body: row.body,
          category: row.category,
          type: row.type,
          intensity: row.intensity,
          publishDate: row.publishDate,
        }));

        setValue('prompts', formattedPrompts);
        await trigger();
        showSnackbar('CSV data uploaded successfully!', 'success');
      } else {
        setShowCSVUpload(false);
        const error = result?.error || 'Failed to parse CSV data';
        showSnackbar(error, 'error');
      }
    },
    [setValue, trigger, showSnackbar],
  );

  const onSubmit: SubmitHandler<FormFieldData> = useCallback(
    async (formValues) => {
      if (isLoading || isSubmitting || !isValid) return null;

      try {
        const { jwt } = await getCurrentAuthCredentials();
        const { data } = await executeGraphqlOperation<{
          adminUpdatePrompts: SuperfeelPrompt[];
        }>(
          adminUpdatePromptMutation,
          {
            input: {
              action: mode === 'CREATE' ? 'CREATE' : 'UPDATE',
              prompts: formValues.prompts.map((promptData) => ({
                body: promptData.body,
                category: promptData.category,
                promptId: mode === 'CREATE' ? undefined : promptData.promptId,
                type: promptData.type,
                intensity: Number(promptData.intensity),
                publishDate: promptData.publishDate
                  ? DateTime.fromISO(promptData.publishDate).toISODate()
                  : null,
              })),
            },
          },
          jwt,
        );

        if (data?.adminUpdatePrompts) {
          await fetchPrompts();
          const count = data.adminUpdatePrompts.length;
          showSnackbar(
            `${count} prompt${count !== 1 ? 's' : ''} successfully ${
              mode === 'CREATE' ? 'created' : 'updated'
            }`,
            'success',
          );
          closeModal();
        } else {
          showSnackbar(
            'No prompts were processed. Please try again.',
            'warning',
          );
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : 'Something went wrong!';
        showSnackbar(errorMessage, 'error');
      }

      return null;
    },
    [
      closeModal,
      fetchPrompts,
      isLoading,
      isSubmitting,
      isValid,
      mode,
      showSnackbar,
    ],
  );

  useEffect(() => {
    fetchPrompts();
  }, [fetchPrompts]);

  useEffect(() => {
    if (id && id !== 'new' && prompts) {
      const prompt = prompts.find((p) => p.promptId === id);
      if (prompt) {
        setSelectedPrompt(prompt);
        setMode('VIEW');
        reset({
          prompts: [
            {
              body: prompt.body,
              category: prompt.category,
              type: prompt.type,
              intensity: prompt.intensity,
              publishDate: prompt.publishDate
                ? DateTime.fromISO(prompt.publishDate).toFormat('MM-DD-YYYY')
                : undefined,
              promptId: prompt.promptId,
            },
          ],
        });
      }
    } else if (id === 'new') {
      setMode('CREATE');
      reset({
        prompts: [
          {
            body: '',
            category: 'EMOTION',
            type: 'FOTD',
            intensity: '1',
            publishDate: undefined,
          },
        ],
      });
    } else {
      setSelectedPrompt(null);
      setMode('VIEW');
    }
  }, [id, prompts, reset]);

  const columns: Column<SuperFeelTableData, keyof SuperFeelTableData>[] =
    useMemo(
      () => [
        {
          header: 'Question',
          accessorKey: 'body',
          cell: (value) => <Typography variant="body2">{value}</Typography>,
        },
        {
          header: (
            <FilterCategory
              filters={filters}
              onFilter={onFilter}
            />
          ),
          accessorKey: 'category',
          cell: (value) => (
            <Chip
              label={categoryMapping[value as PromptCategory] || value}
              variant="outlined"
              size="small"
            />
          ),
        },
        {
          header: (
            <FilterType
              filters={filters}
              onFilter={onFilter}
            />
          ),
          accessorKey: 'type',
          cell: (value) => (
            <Typography variant="body2">
              {value === 'CUSTOM'
                ? 'Custom'
                : postTypeMapping[value as PostType] || value}
            </Typography>
          ),
        },
        {
          header: 'Created',
          accessorKey: 'dateCreated',
          cell: (value) => {
            if (!value) return '';
            return DateTime.fromISO(value).toFormat('M/d/yyyy');
          },
          sortable: true,
          sortField: 'dateCreated',
        },
        {
          header: (
            <FilterPublishDate
              onFilter={onFilter}
              filters={filters}
              disabled={filters.type !== 'FOTD'}
            />
          ),
          accessorKey: 'publishDate',
          cell: (value) => {
            if (!value) return '';
            return DateTime.fromISO(value).toFormat('M/d/yyyy');
          },
        },
      ],
      [categoryMapping, filters, onFilter, postTypeMapping],
    );

  return (
    <Layout>
      <Box
        marginBottom={2}
        component="header"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <Button
          startIcon={<Add />}
          type="button"
          size="small"
          variant="contained"
          color="primary"
          onClick={handleCreatePrompt}
        >
          Create Prompt
        </Button>
      </Box>
      <TableCustomized<SuperFeelTableData, keyof SuperFeelTableData>
        isLoading={isLoading}
        columns={columns}
        data={prompts ?? []}
        count={paginationState.totalCount}
        page={paginationState.page}
        rowsPerPage={paginationState.rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        noDataMessage="Could not find prompts that match the specified criteria"
        onSort={handleSort}
        sort={{
          sortField: tableSort.sort,
          sortDirection: tableSort.sortDirection,
        }}
        onRowClick={handlePromptClick}
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
        filters={getTableFilters}
        onRemoveFilter={handleRemoveFilter}
      />
      <UploadPromptsModal
        mode={mode}
        isOpen={!!id || mode === 'CREATE'}
        closeModal={closeModal}
        onSubmit={handleSubmit(onSubmit)}
        setMode={setMode}
        selectedPrompt={selectedPrompt}
        showCSVUpload={showCSVUpload}
        setShowCSVUpload={setShowCSVUpload}
        isSubmitting={isSubmitting}
        register={register}
        control={control}
        errors={errors}
        reset={reset}
        isValid={isValid}
        handleCSVDrop={handleCSVData}
      />
    </Layout>
  );
}

export default memo(Prompt);
