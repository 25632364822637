import { Box, SxProps, Theme } from '@mui/material';
import { Loading } from 'components/atoms';
import { useMemo } from 'react';
import { TwinDocument } from 'routes/graphql.generated';
import { useGetDocument } from 'routes/user/hooks/useGetDocument';

interface UserDocumentPreviewProps {
  document: TwinDocument;
}

export default function UserDocumentPreview({
  document,
}: UserDocumentPreviewProps) {
  const { content, isLoading, error } = useGetDocument(document.contentUri);

  const styles: SxProps<Theme> = useMemo(
    () => ({
      minWidth: 400,
      height: 500,
      overflow: 'scroll',
      padding: 4,
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      ...(isLoading && {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }),
    }),
    [isLoading],
  );

  if (error) return <div>Error fetching content</div>;

  return <Box sx={styles}>{isLoading || !content ? <Loading /> : content}</Box>;
}
