import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Switch from '@mui/material/Switch';
import { SuperfeelUser, TwinModelConfig } from '@superfeel/types';
import { UserAvatar } from 'components/atoms/avatar';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Mic from '@mui/icons-material/Mic';
import MicOff from '@mui/icons-material/MicOff';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { useTwinConversation } from 'hooks';
import { useDailyEvent } from '@daily-co/daily-react';
import Close from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import { TwinMediaPlayer } from './twinMediaPlayer';

type TwinChatProps = {
  user?: SuperfeelUser;
  config?: TwinModelConfig;
  title?: string;
  deploymentId?: string;
  isLoading?: boolean;
  allowCustomServer?: boolean;
};

function formatCallDuration(duration: number) {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}

const BASE_DIAMETER = 135;
const normalisedVolume = (input: number) => 1 + Math.max(0.01, input);

export function TwinChatComponent({
  user,
  config,
  deploymentId,
  title = '',
  isLoading = false,
  allowCustomServer = false,
}: TwinChatProps) {
  const {
    callState,
    isMuted,
    callDuration,
    error,
    remoteVolume,
    remainingSecs,
    toggleMicMute,
    initialiseRoom,
    initialiseRoomFromWidget,
    exitCall,
    setCustomTwinServerDomain,
  } = useTwinConversation();

  const [useCustomServer, setCustomServer] = useState(false);
  const [customServerValue, setCustomServerValue] = useState('');
  const [showProcessing, setShowProcessing] = useState(false);

  const callTooltip = useMemo(() => {
    if (callState === 'STATE_IDLE') {
      return 'Start call';
    }
    if (callState === 'STATE_ACTIVE') {
      return 'End call';
    }
    return '';
  }, [callState]);

  const callInterface = useMemo(() => {
    let message = '';
    switch (callState) {
      case 'STATE_ACTIVE':
        message = formatCallDuration(callDuration);
        break;
      case 'STATE_CREATING':
        message = 'Starting call...';
        break;
      case 'STATE_INVITING':
        message = `${user?.firstName || 'Heir'} is joining...`;
        break;
      default:
        message = error || '';
    }
    if (message) {
      return (
        <Typography
          align="center"
          variant="body2"
          color={error ? 'error' : undefined}
        >
          {message}
        </Typography>
      );
    }
    return <Box height="20px" />;
  }, [callState, error, callDuration, user?.firstName]);

  const remainingTimeText = useMemo(() => {
    if (remainingSecs <= 0) {
      return '';
    }
    if (remainingSecs <= 10) {
      return `${remainingSecs} seconds remaining`;
    }
    if (remainingSecs <= 60) {
      return 'Less than a minute remaining';
    }
    if (remainingSecs <= 60 * 5) {
      return `${Math.ceil(remainingSecs / 60)} minutes remaining`;
    }
    return '';
  }, [remainingSecs]);

  const handleCallButtonPress = useCallback(() => {
    if (callState === 'STATE_IDLE' && config?.modelConfigId) {
      initialiseRoom(config.modelConfigId);
    } else if (callState === 'STATE_IDLE' && deploymentId) {
      initialiseRoomFromWidget(deploymentId);
    } else if (callState === 'STATE_ACTIVE') {
      exitCall();
    }
  }, [
    callState,
    exitCall,
    initialiseRoom,
    initialiseRoomFromWidget,
    config?.modelConfigId,
    deploymentId,
  ]);

  const handleUpdateCustomServer = useCallback(
    (value: string, isActive: boolean) => {
      setCustomServerValue(value);
      setCustomTwinServerDomain(isActive ? value.trim() : '');
    },
    [setCustomTwinServerDomain],
  );

  const handleAppMessage = useCallback((msg: { action: string }) => {
    console.debug('APP MESSAGE RECEIVED', msg);
    if (!msg.action) {
      return;
    }
    if (msg.action === 'GRAG_START') {
      setShowProcessing(true);
    }
    if (msg.action === 'GRAG_FINISH') {
      setShowProcessing(false);
    }
  }, []);

  useDailyEvent('app-message', (ev) =>
    handleAppMessage(ev.data as { action: string }),
  );

  useEffect(() => {
    if (callState !== 'STATE_IDLE' && !config?.modelConfigId && !deploymentId) {
      exitCall();
    }
    if (callState === 'STATE_IDLE') {
      setShowProcessing(false);
    }
  }, [callState, exitCall, config?.modelConfigId, deploymentId]);

  if (isLoading) {
    return (
      <Box
        key="main-chat-container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size={64} />
      </Box>
    );
  }

  return (
    <Box
      key="main-chat-container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      {title && (
        <Typography
          align="center"
          variant="body2"
          sx={{ paddingX: 1, whiteSpace: 'pre-line' }}
        >
          {title}
        </Typography>
      )}
      <Box
        key="chat-avatar-container"
        sx={{
          height: BASE_DIAMETER * 1.8,
          width: '100%',
          display: 'flex',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: BASE_DIAMETER * normalisedVolume(remoteVolume),
            height: BASE_DIAMETER * normalisedVolume(remoteVolume),
            backgroundColor: 'white',
            borderRadius: 9999,
            transition: 'height 0.2s ease, width 0.2s ease',
            position: 'absolute',
            zIndex: 1,
          }}
        />
        <UserAvatar
          user={user}
          size="l"
          sx={{ position: 'absolute', zIndex: 10 }}
        />
        {showProcessing && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              align="center"
              variant="body2"
              sx={{ paddingX: 1 }}
            >
              Thinking...
            </Typography>
            <CircularProgress size={14} />
          </Box>
        )}
      </Box>
      <Typography
        align="center"
        variant="body2"
        sx={{ paddingX: 1, color: 'white' }}
      >
        {remainingTimeText}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          p: 1,
          height: 60,
        }}
      >
        {callState === 'STATE_ACTIVE' ? (
          <>
            <Tooltip
              title={isMuted ? 'Microphone muted' : 'Microphone active'}
              placement="top"
              arrow
            >
              <IconButton
                size="large"
                onClick={toggleMicMute}
              >
                {isMuted ? <MicOff color="disabled" /> : <Mic color="info" />}
              </IconButton>
            </Tooltip>
            <Tooltip
              title={callTooltip}
              placement="top"
              arrow
            >
              <IconButton
                size="large"
                onClick={handleCallButtonPress}
                color="primary"
              >
                <Close />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <LoadingButton
            loading={
              callState === 'STATE_CREATING' || callState === 'STATE_INVITING'
            }
            onClick={handleCallButtonPress}
            style={{ padding: 10 }}
          >
            Start call
          </LoadingButton>
        )}
      </Box>
      <Box sx={{ paddingBottom: 2 }}>{callInterface}</Box>
      {config?.twinType === 'MEDIA' && !!config?.modelConfigId && (
        <TwinMediaPlayer
          modelConfigId={config.modelConfigId}
          userId={user?.userId}
        />
      )}
      {allowCustomServer && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingY: 1,
            paddingX: 2,
            width: '100%',
          }}
        >
          <Typography variant="body2">Use custom server</Typography>
          <Switch
            checked={useCustomServer}
            onChange={() => {
              const newValue = !useCustomServer;
              setCustomServer(newValue);
              handleUpdateCustomServer(customServerValue, newValue);
            }}
            color="info"
            disabled={callState !== 'STATE_IDLE'}
          />
          {useCustomServer && (
            <Input
              sx={{ flexGrow: 1 }}
              value={customServerValue}
              placeholder="Enter custom server domain..."
              onChange={(ev) =>
                handleUpdateCustomServer(ev.target.value, useCustomServer)
              }
              disabled={callState !== 'STATE_IDLE'}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
