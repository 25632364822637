import { FileUpload } from '@superfeel/types';
import { ObjectType } from 'routes/graphql.generated';
import { usersApi } from 'routes/user/api/user';
import { store } from 'store';

export async function finalizeS3Upload(
  key: string,
  uploadId: string,
  parts: { ETag: string; PartNumber: number }[],
  type: ObjectType = 'INTERVIEW',
): Promise<FileUpload> {
  const { data } = await store.dispatch(
    usersApi.endpoints.CompleteFileUpload.initiate({
      input: {
        type,
        key,
        uploadId,
        parts,
      },
    }),
  );

  if (!data?.completeFileUpload?.key) {
    throw new Error('Failed to complete file upload');
  }

  return data.completeFileUpload;
}
