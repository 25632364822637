/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import Article from '@mui/icons-material/Article';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { TextField } from 'components/atoms';
import { LoadingButton } from '@mui/lab';
import useUploadTwinDocument from 'routes/user/hooks/useUploadTwinDocument';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { Dropzone } from 'components/atoms/dropzone';
import { formatFileSize } from 'common/utils';

export interface EditDocumentFormValues {
  name?: string;
  accessLevel?: 'PRIVATE' | 'FRIENDS' | 'PUBLIC';
  mode?: 'FILE' | 'TEXT';
  text?: string;
  file?: File;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  accessLevel: Yup.string()
    .oneOf(['PRIVATE', 'FRIENDS', 'PUBLIC'])
    .required('Access level is required'),
  mode: Yup.string()
    .oneOf(['FILE', 'TEXT'])
    .required('Access level is required'),
  text: Yup.string().when('mode', {
    is: 'TEXT',
    then: (schema) =>
      schema.required('Text input is required').min(100, 'Text is too short'),
    otherwise: (schema) => schema.notRequired(),
  }),
  file: Yup.mixed().when('mode', {
    is: 'FILE',
    then: (schema) =>
      schema
        .required('A file is required')
        .test('fileSize', 'File size must be less than 10MB', (value) => {
          if (value && (value as File).size) {
            const file = value as File;
            return file.size <= 10 * 1024 * 1024;
          }
          return false;
        })
        .test(
          'fileType',
          'Invalid file type - only .txt supported',
          (value) => {
            if (value && (value as File).type) {
              const file = value as File;
              return file.type === 'text/plain';
            }
            return false;
          },
        ),
    otherwise: (schema) => schema.notRequired(),
  }),
});

interface EditDocumentFormProps {
  closeModal: () => void;
}

export default function EditDocumentForm({
  closeModal,
}: EditDocumentFormProps) {
  const { user } = useGetTargetUser();

  const { handleUploadDocument, isUploading } = useUploadTwinDocument(user, {
    onSuccess: closeModal,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    watch,
  } = useForm<EditDocumentFormValues>({
    // @ts-expect-error - Types complaining but there is nothing wrong with the validation.
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      accessLevel: 'PRIVATE',
      mode: 'FILE',
      text: '',
      file: null,
    },
    mode: 'onChange',
  });
  const currentMode = watch('mode');
  const uploadedFile = watch('file');

  return (
    <Box
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(handleUploadDocument)}
      display="flex"
      flexDirection="column"
      gap={3}
      px={2}
      borderRadius={2}
      minWidth={400}
      mx="auto"
      mt={5}
      boxShadow={24}
    >
      <Controller
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label="Name"
            error={!!errors.name}
            helperText={errors.name?.message}
            required
          />
        )}
      />

      <Controller
        name="accessLevel"
        control={control}
        render={({ field }) => (
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <TextField
              {...field}
              select
              label="Access Level"
              error={!!errors.accessLevel}
              helperText={errors.accessLevel?.message}
              required
            >
              <MenuItem value="PRIVATE">Private</MenuItem>
              <MenuItem value="FRIENDS">Friends</MenuItem>
              <MenuItem value="PUBLIC">Public</MenuItem>
            </TextField>
            <ButtonIconTooltip
              icon="info"
              onClick={() => {}}
              isDisabled
              isLoading={false}
              tooltipTitle="Determines who can see the document data in the users graph"
              tooltipColorVariant="info"
              tooltipPlacement="right"
            />
          </Box>
        )}
      />

      <Controller
        name="mode"
        control={control}
        render={({ field }) => (
          <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Select upload type
            </FormLabel>
            <RadioGroup {...field}>
              <FormControlLabel
                value="FILE"
                control={<Radio />}
                label="Upload text document"
              />
              <FormControlLabel
                value="TEXT"
                control={<Radio />}
                label="Enter free text"
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      {currentMode === 'TEXT' && (
        <Controller
          name="text"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Free text input"
              error={!!errors.text}
              helperText={errors.text?.message}
              multiline
              rows={8}
              required
              maxLength={2500}
            />
          )}
        />
      )}
      {currentMode === 'FILE' && (
        <Controller
          name="file"
          control={control}
          render={({ field, fieldState }) =>
            uploadedFile ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  height: 200,
                }}
              >
                <Article fontSize="large" />
                <Typography
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  {uploadedFile.name}
                </Typography>
                <Typography
                  variant="body1"
                  color="primary.main"
                  textAlign="center"
                >
                  {formatFileSize(uploadedFile.size)}
                </Typography>
                <Typography
                  variant="body1"
                  color="error"
                  textAlign="center"
                  py={1}
                >
                  {fieldState.error?.message}
                </Typography>
                <Button
                  onClick={() => {
                    field.onChange(null);
                  }}
                >
                  Discard
                </Button>
              </Box>
            ) : (
              <Dropzone
                acceptFileTypes="text/plain"
                label="Drag text file here"
                sx={{ height: 200 }}
                onFilesChange={(files) => {
                  const file = files.item(0);
                  if (!file?.size) {
                    return;
                  }
                  field.onChange(file);
                }}
              />
            )
          }
        />
      )}

      <Box
        display="flex"
        justifyContent="flex-end"
        gap={2}
        marginBottom={2}
      >
        <Button
          variant="outlined"
          onClick={() => {
            reset();
            closeModal();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={isUploading}
          type="submit"
          variant="contained"
          disabled={!isValid || !isDirty}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}
