import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Edit from '@mui/icons-material/Edit';
import Language from '@mui/icons-material/Language';
import Speed from '@mui/icons-material/Speed';
import { DateTime } from 'luxon';
import { TwinModelTtsConfig } from 'routes/graphql.generated';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { useCallback, useState } from 'react';
import { useAdminUpdateTwinVoiceMutation } from 'routes/user/api/user';
import { AdminUpdateTwinVoiceInput } from '@superfeel/types';
import { useSnackbar } from 'state/context/snackBar';
import { ConfirmDialog } from 'components/atoms/dialog';

interface VoiceCardProps {
  config: TwinModelTtsConfig;
  onEdit?: (config: TwinModelTtsConfig) => void;
}

export default function VoiceCard({
  config,
  onEdit = () => {},
}: VoiceCardProps) {
  const { showSnackbar } = useSnackbar();
  const [adminUpdateVoice, { isLoading: isUpdating }] =
    useAdminUpdateTwinVoiceMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const handleDeleteVoice = useCallback(() => {
    if (!config || isUpdating) {
      return;
    }
    const input: AdminUpdateTwinVoiceInput = {
      action: 'DELETE',
      targetUserId: config.targetUserId,
      ttsConfigId: config.ttsConfigId,
    };
    setIsDeleteModalOpen(false);
    adminUpdateVoice({ input })
      .unwrap()
      .then(() => showSnackbar('Voice config deleted', 'success'))
      .catch((err) =>
        showSnackbar(
          `Error deleting voice: ${(err as Error)?.message || 'Unknown error'}`,
          'error',
        ),
      );
  }, [adminUpdateVoice, config, isUpdating, showSnackbar]);
  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <>
            <Typography variant="h5">
              {config.voiceId || 'Untitled Voice Config'}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {config.activeModelConfigIds?.length
                ? `Used by ${config.activeModelConfigIds?.length} Heir config${config.activeModelConfigIds?.length > 1 ? 's' : ''}`
                : 'Not in use'}
            </Typography>
          </>
        }
        action={
          <Box>
            <IconButton onClick={() => onEdit(config)}>
              <Edit />
            </IconButton>
            <ButtonIconTooltip
              icon="delete"
              onClick={() => setIsDeleteModalOpen(true)}
              isDisabled={!!config.activeModelConfigIds?.length}
              isLoading={isUpdating}
              tooltipColorVariant="error"
              tooltipTitle={
                config.activeModelConfigIds?.length
                  ? 'Cannot delete while voice is in use'
                  : ''
              }
            />
          </Box>
        }
      />
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack spacing={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Voice speed">
              <Speed />
            </Tooltip>
            <Typography
              variant="body2"
              color="text.primary"
              ml={1}
            >
              {config.speed || 'N/A'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Language">
              <Language />
            </Tooltip>
            <Typography
              variant="body2"
              color="text.primary"
              ml={1}
            >
              {config.language.toUpperCase() || 'UNKNOWN'}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {config.voiceSampleUri ? (
              <audio
                src={config.voiceSampleUri}
                controls
              >
                <track kind="captions" />
              </audio>
            ) : (
              <Typography
                variant="body2"
                color="text.primary"
              >
                No voice sample available
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Date Created:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.dateCreated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Last Updated:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.lastUpdated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <ConfirmDialog
        isShown={isDeleteModalOpen}
        title="Delete voice?"
        message="This is irreversible and cannot be undone"
        onConfirm={handleDeleteVoice}
        onReject={() => setIsDeleteModalOpen(false)}
        onClose={() => setIsDeleteModalOpen(false)}
      />
    </Card>
  );
}
