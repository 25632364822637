/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import { memo, useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';
import { executeGraphqlOperation } from 'api';
import { Menu, Modal, TextField } from 'components/atoms';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'state/context/snackBar';
import { Add, Remove, Upload } from '@mui/icons-material';
import { validateAndTransformPhoneNumber } from 'utils/validate-phonenumber';
import {
  adminInviteUsersMutation,
  adminDeleteInviteMutation,
} from 'graphql/mutations/invite';
import { TableCustomized } from 'components/molecules';
import { adminGetUserInvitesQuery } from 'graphql/queries/invite';
import { useDebounce } from 'hooks';
import {
  AdminGetUserInvitesInput,
  AdminGetUserInvitesResponse,
  InviteStatus,
  UserInvitation,
  UserInvitesSort,
} from '@superfeel/types';
import { Column } from 'components/molecules/table';
import StatusChip from './status-chip';
import { UploadCSV } from '../upload-csv/upload-csv';

type InviteUsersRes = {
  adminInviteUsers: {
    success: boolean;
    message: string;
  };
};

type DeleteInviteRes = {
  adminDeleteInvite: {
    success: boolean;
    message: string;
  };
};

type TableSort = {
  sort: UserInvitesSort;
  sortDirection: 'asc' | 'desc';
};

export type Invitee = {
  firstName: string;
  lastName: string;
  phone: string;
};

export type InviteFormProps = {
  invitees: Invitee[];
};

export function InviteUsers() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [invites, setInvites] = useState<AdminGetUserInvitesResponse | null>(
    null,
  );
  const [paginationState, setPaginationState] = useState({
    page: 0,
    rowsPerPage: 10,
    totalCount: 0,
  });
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [tableSort, setTableSort] = useState<TableSort>({
    sort: 'DATE_CREATED',
    sortDirection: 'desc',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [csvUpload, setCSVUpload] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { showSnackbar } = useSnackbar();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues,
    formState: { errors, isValid, isLoading, isSubmitting, isSubmitted },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        invitees: Yup.array()
          .of(
            Yup.object().shape({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last name is required'),
              phone: Yup.string()
                .required('Phone number is required')
                .test('valid-phone', 'Invalid phone number', (value) => {
                  if (!value) return false;
                  const [isPhoneValid] = validateAndTransformPhoneNumber(value);
                  return isPhoneValid;
                }),
            }),
          )
          .test(
            'non-empty-rows',
            'At least one row must be filled',
            (rows: Invitee[] | undefined) => {
              if (!rows) return false;
              const nonEmptyRows = rows.filter(
                (row) => row.firstName || row.lastName || row.phone,
              );
              return nonEmptyRows.length > 0;
            },
          )
          .transform((rows: Invitee[]) =>
            rows.filter((row) => row.firstName || row.lastName || row.phone),
          )
          .required('At least one invitee is required'),
      }),
    ),
    defaultValues: {
      invitees: [
        { firstName: '', lastName: '', phone: '' },
        { firstName: '', lastName: '', phone: '' },
      ],
    },
    mode: 'onChange',
    shouldFocusError: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'invitees',
  });

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setCSVUpload(false);
    reset();
  }, [reset]);

  const fetchUserInvites = useCallback(async () => {
    setTableLoading(true);
    const input: AdminGetUserInvitesInput = {
      limit: paginationState.rowsPerPage,
      startIndex: paginationState.page * paginationState.rowsPerPage,
      sort: tableSort.sort,
      sortDirection:
        tableSort.sortDirection.toLocaleUpperCase() as AdminGetUserInvitesInput['sortDirection'],
      search: debouncedSearchTerm || undefined,
    };
    try {
      const { jwt } = await getCurrentAuthCredentials();
      const { data } = await executeGraphqlOperation<{
        adminGetUserInvites: AdminGetUserInvitesResponse;
      }>(adminGetUserInvitesQuery, { input }, jwt);

      const result = data?.adminGetUserInvites ?? null;
      if (result) {
        setPaginationState((prev) => ({
          ...prev,
          totalCount: result.totalCount,
        }));
        setInvites(result);
      }
      return result;
    } catch (e) {
      showSnackbar(`Failed to fetch user invites: ${e as string}`, 'error');
      return null;
    } finally {
      setTableLoading(false);
    }
  }, [
    debouncedSearchTerm,
    paginationState.page,
    paginationState.rowsPerPage,
    showSnackbar,
    tableSort.sort,
    tableSort.sortDirection,
  ]);

  const handleSort = useCallback(
    (property: UserInvitesSort, order: 'asc' | 'desc') => {
      setTableSort({
        sort: property,
        sortDirection: order,
      });
    },
    [],
  );

  const onDeletePendingInvite = useCallback(
    async (invitationId: string) => {
      try {
        const { jwt } = await getCurrentAuthCredentials();
        const { data } = await executeGraphqlOperation<DeleteInviteRes>(
          adminDeleteInviteMutation,
          { input: { invitationId } },
          jwt,
        );

        if (data?.adminDeleteInvite.success) {
          showSnackbar('Invitation successfully revoked!', 'success');
          fetchUserInvites();
        } else {
          showSnackbar(
            data?.adminDeleteInvite.message || 'Failed to revoke invitation',
            'error',
          );
        }
      } catch (err) {
        console.warn(err);
        showSnackbar(
          'An error occurred while revoking the invitation',
          'error',
        );
      }
    },
    [fetchUserInvites, showSnackbar],
  );

  const onSubmit: SubmitHandler<InviteFormProps> = useCallback(
    async (formValues: InviteFormProps) => {
      if (isLoading || isSubmitting || !isValid) return null;
      try {
        const nonEmptyInvitees = formValues.invitees.filter(
          (invitee) => invitee.firstName || invitee.lastName || invitee.phone,
        );
        const { jwt } = await getCurrentAuthCredentials();
        const { data } = await executeGraphqlOperation<InviteUsersRes>(
          adminInviteUsersMutation,
          { input: { users: nonEmptyInvitees } },
          jwt,
        );

        if (data?.adminInviteUsers) {
          showSnackbar('Invitations successfully sent!', 'success');
          closeModal();
          reset();
        } else {
          showSnackbar('Something went wrong!', 'error');
        }
      } catch (err) {
        console.warn(err);
        showSnackbar('Something went wrong!', 'error');
      } finally {
        fetchUserInvites();
      }

      return null;
    },
    [
      isLoading,
      isSubmitting,
      isValid,
      showSnackbar,
      closeModal,
      reset,
      fetchUserInvites,
    ],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPaginationState((prev) => ({ ...prev, page: 0 }));
  };

  const handleCSVData = (data: Invitee[], hasErrors: boolean) => {
    setValue('invitees', data);
    if (hasErrors) {
      setCSVUpload(false);
      trigger();
      showSnackbar(
        'Some entries have invalid data. Please correct them.',
        'error',
      );
    } else {
      showSnackbar('CSV data uploaded successfully!', 'success');
    }
  };

  const handleFocus = useCallback(
    (index: number) => {
      return () => {
        const currentValues = getValues('invitees');
        const isLastRow = index === currentValues.length - 1;

        if (isLastRow && currentValues.length < 10) {
          append(
            { firstName: '', lastName: '', phone: '' },
            { shouldFocus: false },
          );
        }
      };
    },
    [getValues, append],
  );

  const handlePageChange = useCallback((newPage: number) => {
    setPaginationState((prev) => ({ ...prev, page: newPage }));
  }, []);

  const handleRowsPerPageChange = useCallback((newRowsPerPage: number) => {
    setPaginationState((prev) => ({
      ...prev,
      rowsPerPage: newRowsPerPage,
      page: 0,
    }));
  }, []);

  const columns: Column<UserInvitation, UserInvitesSort>[] = useMemo(
    () => [
      {
        header: 'First Name',
        accessorKey: 'firstName',
        sortable: true,
        sortField: 'FIRST_NAME',
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        sortable: true,
        sortField: 'LAST_NAME',
      },
      {
        header: 'Phone Number',
        accessorKey: 'phoneNumber',
      },
      {
        header: 'Date Sent',
        accessorKey: 'dateCreated',
        cell: (value: string | boolean | undefined) => {
          if (typeof value !== 'string' || !value) return '';
          return DateTime.fromISO(value).toFormat('M/d/yyyy');
        },
        sortable: true,
        sortField: 'DATE_CREATED',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (value: string | boolean | undefined) => (
          <StatusChip value={value as InviteStatus} />
        ),
      },
      {
        header: '',
        accessorKey: 'invitationId',
        cell: (value: string | boolean | undefined, row: UserInvitation) => {
          if (row.status !== 'PENDING' || typeof value !== 'string')
            return null;
          return (
            <Menu
              options={[{ label: 'Revoke invite', value: 'revoke' }]}
              onChange={(selectedValue) => {
                if (selectedValue === 'revoke') {
                  onDeletePendingInvite(value);
                }
              }}
            />
          );
        },
      },
    ],
    [onDeletePendingInvite],
  );

  useEffect(() => {
    fetchUserInvites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableSort,
    debouncedSearchTerm,
    paginationState.page,
    paginationState.rowsPerPage,
  ]);

  return (
    <>
      <Box
        marginBottom={2}
        component="header"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <Button
          startIcon={<Add />}
          sx={{ fontWeight: 'bold' }}
          type="button"
          size="small"
          variant="contained"
          onClick={() => setIsOpen(true)}
        >
          Invite
        </Button>
      </Box>
      <TableCustomized<UserInvitation, UserInvitesSort>
        isLoading={tableLoading}
        columns={columns}
        data={invites?.invitees ?? []}
        count={paginationState.totalCount}
        page={paginationState.page}
        rowsPerPage={paginationState.rowsPerPage}
        rowsPerPageOptions={[5, 10]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        noDataMessage="No user matches the specified search term"
        onSort={handleSort}
        sort={{
          sortField: tableSort.sort,
          sortDirection: tableSort.sortDirection,
        }}
        searchValue={searchTerm}
        onSearchChange={handleSearchChange}
      />
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        aria-labelledby="Invite user modal"
        aria-describedby="Invite users to superfeel"
        title="Invite users"
      >
        <Box
          component="form"
          ref={formRef}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)(e);
          }}
          minHeight={260}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          {csvUpload ? (
            <Box mx={2}>
              <UploadCSV onValidData={handleCSVData} />
            </Box>
          ) : (
            <Box
              component="ul"
              width="100%"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                listStyle: 'none',
                padding: 0,
                margin: 0,
                alignItems: 'center',
              }}
            >
              {fields.map((item, index) => (
                <Box
                  key={item.id}
                  sx={{ display: 'flex', flexDirection: 'row', mt: 2, px: 2 }}
                >
                  <Box component="li">
                    <TextField
                      inputProps={{
                        'data-testid': `first-name-input-${index}`,
                      }}
                      label="First Name"
                      {...register(`invitees.${index}.firstName` as const)}
                      placeholder="First Name"
                      error={
                        isSubmitted && !!errors.invitees?.[index]?.firstName
                      }
                      onFocus={handleFocus(index)}
                    />
                  </Box>
                  <Box
                    component="li"
                    sx={{ mx: 2 }}
                  >
                    <TextField
                      label="Last Name"
                      inputProps={{ 'data-testid': `last-name-input-${index}` }}
                      {...register(`invitees.${index}.lastName` as const)}
                      placeholder="Last Name"
                      error={
                        isSubmitted && !!errors.invitees?.[index]?.lastName
                      }
                      onFocus={handleFocus(index)}
                    />
                  </Box>
                  <Box
                    component="li"
                    sx={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <TextField
                      label="Phone number"
                      inputProps={{ 'data-testid': `phone-input-${index}` }}
                      {...register(`invitees.${index}.phone` as const)}
                      placeholder="i.e. +64496768893"
                      error={isSubmitted && !!errors.invitees?.[index]?.phone}
                      onFocus={handleFocus(index)}
                      onBlur={(e) => {
                        e.target.value = e.target.value.replace(/\s/g, '');
                        const [, formattedNumber] =
                          validateAndTransformPhoneNumber(e.target.value);
                        setValue(`invitees.${index}.phone`, formattedNumber);
                      }}
                    />
                    {fields.length > 1 && (
                      <Box
                        display="flex"
                        justifyItems="center"
                        alignItems="center"
                      >
                        <IconButton
                          aria-label="remove user"
                          size="small"
                          onClick={() => remove(index)}
                          sx={{ ml: 1 }}
                        >
                          <Remove fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}

          <Box
            component="footer"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 4,
              p: 2,
            }}
          >
            {csvUpload ? (
              <Button
                type="button"
                onClick={() => setCSVUpload(false)}
                variant="outlined"
              >
                Cancel
              </Button>
            ) : (
              <IconButton
                type="button"
                onClick={() => setCSVUpload(true)}
                sx={{
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <Upload fontSize="inherit" />
                <Typography
                  fontSize={14}
                  ml={0.5}
                >
                  Upload from CSV
                </Typography>
              </IconButton>
            )}
            <LoadingButton
              loading={isSubmitting || isLoading}
              disabled={(isSubmitted && !isValid) || isSubmitting || isLoading}
              type="submit"
              sx={{ fontWeight: 'bold' }}
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default memo(InviteUsers);
