import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CardHeader,
  Card,
  Typography,
  CardContent,
  Box,
  Stack,
  Grid2 as Grid,
} from '@mui/material';
import {
  Interview,
  InterviewCollection,
  InterviewInput,
  UpdateInterviewCollectionInput,
} from '@superfeel/types';
import { formatFileSize } from 'common/utils';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { useSnackbar } from 'state/context/snackBar';
import generateId from 'utils/generateId';
import {
  useUpdateInterviewCollectionMutation,
  useUpdateInterviewMutation,
} from 'routes/user/api/user';
import { getSignedS3Url } from 'utils/s3/getSignedS3Url';
import { fileUpload } from 'utils/s3/fileUpload';
import { useBlocker } from 'react-router-dom';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { ConfirmDialog } from 'components/atoms/dialog';
import { DateTime } from 'luxon';
import { AbortUploadModal } from '../abortUploadModal';
import { InterviewCard } from '../interviewCard';

interface CollectionProps {
  collection: InterviewCollection;
}

interface ProgressState {
  progress: number;
  status: string;
}

export interface ProgressTracker {
  [fileId: string]: ProgressState;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024 * 1024; // 10GB

const validateFile = (file: File) => {
  const validTypes = [
    'audio/mp3',
    'audio/wav',
    'audio/x-wav',
    'audio/aac',
    'audio/ogg',
    'audio/flac',
    'audio/avr',
    'audio/cdda',
    'audio/vms',
    'audio/mpeg',
    'video/mp4',
    'video/quicktime',
  ];

  if (!validTypes.includes(file.type)) {
    throw new Error(`Invalid file type: ${file.type}`);
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error(`File size exceeds ${formatFileSize(MAX_FILE_SIZE)}`);
  }
};

export function CollectionCard({ collection }: CollectionProps) {
  const { user } = useGetTargetUser();
  const [placeholders, setPlaceholders] = useState<Interview[]>([]);
  const [isAbortModalOpen, setIsAbortModalOpen] = useState<boolean>(false);
  const [progressTracker, setProgressTracker] =
    useState<ProgressTracker | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [updateInterview, { isLoading: isFileMetaSubmitting }] =
    useUpdateInterviewMutation();
  const [updateCollection, { isLoading: isUpdatingCollection }] =
    useUpdateInterviewCollectionMutation();
  const { showSnackbar } = useSnackbar();
  const isFileUploading = useMemo(
    () =>
      progressTracker
        ? Object.values(progressTracker).some(
            (state) => state.progress > 0 && state.progress < 100,
          )
        : false,
    [progressTracker],
  );
  const blocker = useBlocker(isFileUploading);

  const allInterviews = useMemo(
    () =>
      [...(collection.interviews || []), ...placeholders].sort((a, b) =>
        a.dateCreated.localeCompare(b.dateCreated),
      ),
    [collection.interviews, placeholders],
  );

  const canDeleteCollection = useMemo(() => {
    if (!collection.interviews?.length) {
      return true;
    }
    const ingestedInterviews = collection.interviews.filter(
      (int) =>
        int.processingStage === 'GRAPH' &&
        int.twinGraphIngestionStatus !== 'NOT_STARTED',
    );
    return !ingestedInterviews.length;
  }, [collection.interviews]);

  const handleDeleteCollection = useCallback(() => {
    if (isUpdatingCollection || !collection?.collectionId) {
      return;
    }
    const input: UpdateInterviewCollectionInput = {
      action: 'DELETE',
      collection: {
        collectionId: collection.collectionId,
        targetUserId: collection.targetUserId,
      },
    };
    updateCollection({ input })
      .unwrap()
      .then(() => {
        showSnackbar('Deleted Collection', 'success');
      })
      .catch((err) => {
        showSnackbar(
          `Error deleting collection: ${(err as Error)?.message}`,
          'error',
        );
      });
  }, [
    collection.collectionId,
    collection.targetUserId,
    isUpdatingCollection,
    showSnackbar,
    updateCollection,
  ]);

  const uploadFile = useCallback(
    async (file: File) => {
      if (!user?.userId) {
        return null;
      }
      const interviewId = generateId();
      // ffmpeg cant deal with spaces in file names
      const modifiedFileName = file.name.replace(/\s/g, '_');
      const key = `${user.userId}/${collection.collectionId}/${interviewId}/${modifiedFileName}`;
      const awsRegion =
        (import.meta.env.VITE_AWS_REGION as string) || 'eu-west-2';
      const bucketName = `superfeel-twins-raw-${import.meta.env.VITE_STAGE}-${awsRegion}`;

      setProgressTracker((prev) => ({
        ...(prev || {}),
        [interviewId]: { progress: 0, status: 'Preparing file…' },
      }));

      try {
        // 1). Add interview card placeholder.
        const interview: Interview = {
          dateCreated: new Date().toISOString(),
          fileName: modifiedFileName,
          fileSize: file.size,
          interviewId,
          lastUpdated: new Date().toISOString(),
          parentCollectionId: collection.collectionId,
          s3Key: key,
          durationSecs: 0,
          targetUserId: user.userId,
          uploaderUserId: interviewId,
        };
        setPlaceholders((prev) => [...prev, interview]);

        // 2). Get uplaod ID.
        const { uploadId } = await getSignedS3Url({
          key,
          type: 'INTERVIEW',
          expiry: 60 * 60 * 24,
          method: 'POST',
          headers: JSON.stringify({
            'Content-Type': file.type,
          }),
        });

        // 3. Upload file.
        const { key: s3Key } = await fileUpload({
          file,
          onProgress: (val: number) => {
            setProgressTracker((prev) => ({
              ...(prev || {}),
              [interviewId]: {
                ...(prev?.[interviewId] || {}),
                progress: val,
                status: val === 100 ? 'Processing' : 'Uploading file',
              },
            }));
          },
          key,
          bucketName,
          uploadId,
        });

        return { s3Key, interviewId };
      } catch (err) {
        setProgressTracker((prev) => ({
          ...(prev || {}),
          [interviewId]: {
            ...(prev?.[interviewId] || {}),
            status: 'Upload failed',
            progress: 0,
          },
        }));
        showSnackbar(
          `Error uploading file: ${
            err instanceof Error ? err.message : String(err)
          }`,
          'error',
        );
        throw err;
      }
    },
    [collection.collectionId, showSnackbar, user?.userId],
  );

  const saveFileMeta = useCallback(
    async (file: File, s3Key: string, tempInterviewId: string) => {
      const { name: fileName = 'Untitled file', size: fileSize } = file || {};
      if (!collection.creatorUserId || !(fileSize >= 0) || !user?.userId) {
        throw new Error('Missing creator ID or invalid file');
      }

      if (isFileMetaSubmitting) return;

      const interview: InterviewInput = {
        fileName,
        fileSize,
        durationSecs: 0,
        parentCollectionId: collection.collectionId,
        s3Key,
        targetUserId: user.userId,
      };

      await updateInterview({
        input: {
          action: 'CREATE',
          interview,
        },
      })
        .unwrap()
        .catch((err) =>
          showSnackbar(
            `Error creating new file ${(err as Error)?.message || 'Unknown error'}`,
            'error',
          ),
        );

      // Update the collection interview.
      setPlaceholders((prev) =>
        prev.filter((el) => el.interviewId !== tempInterviewId),
      );
    },
    [
      collection.creatorUserId,
      collection.collectionId,
      user.userId,
      isFileMetaSubmitting,
      updateInterview,
      showSnackbar,
    ],
  );

  const onFileUploadChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      let files: File[] = [];
      try {
        files = Array.from(event.target.files || []);

        files.forEach(validateFile);
        if (files.length > 5) {
          throw new Error('Can not upload more than 5 files at any moment');
        }

        await Promise.all(
          files.map(async (file) => {
            const result = await uploadFile(file);
            if (result?.s3Key && result?.interviewId) {
              await saveFileMeta(file, result.s3Key, result.interviewId);
              return result.s3Key;
            }

            throw new Error(`Failed to upload ${file.name}`);
          }),
        );

        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        showSnackbar(
          `${files.length} file(s) successfully uploaded`,
          'success',
        );
      } catch (error) {
        console.error('Upload error:', error);
        showSnackbar(
          error instanceof Error ? error.message : 'Failed to upload files',
          'error',
        );
      } finally {
        setProgressTracker(null);
      }
    },
    [uploadFile, saveFileMeta, showSnackbar],
  );

  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onFileUploadChange(event).catch(console.error);
    },
    [onFileUploadChange],
  );

  const onFileUploadClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsAbortModalOpen(true);
    }
  }, [blocker.state]);

  const handleConfirmNavigation = useCallback(() => {
    blocker.proceed();
    setIsAbortModalOpen(false);
  }, [blocker]);

  const handleCancelNavigation = useCallback(() => {
    blocker.reset();
    setIsAbortModalOpen(false);
  }, [blocker]);

  const handleBrowserStateNavigation = useCallback(() => {
    if (!isFileUploading) return () => {};

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Chrome requires returnValue to be set
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFileUploading]);

  useEffect(() => {
    handleBrowserStateNavigation();
  }, [handleBrowserStateNavigation, isFileUploading]);

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          sx={{ pb: 0 }}
          title={
            <Typography variant="h4">
              {collection.name || 'Untitled Collection'}
            </Typography>
          }
          subheader={
            <Box>
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ my: 1 }}
              >
                {collection.interviewerName &&
                  `Interviewer: ${collection.interviewerName}`}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  Created:{' '}
                  {DateTime.fromISO(collection.dateCreated).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  position="relative"
                  left="28px"
                >
                  Updated:{' '}
                  {DateTime.fromISO(collection.lastUpdated).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Typography>
              </Stack>
            </Box>
          }
          action={
            <>
              <ButtonIconTooltip
                isLoading={isUpdatingCollection}
                onClick={() => setShowConfirmDelete(true)}
                tooltipTitle={
                  canDeleteCollection
                    ? 'Delete collection'
                    : 'Unable to delete once interviews have been ingested'
                }
                icon="delete"
                tooltipColorVariant="error"
                isDisabled={!canDeleteCollection}
              />
              <ButtonIconTooltip
                isLoading={false}
                onClick={onFileUploadClick}
                tooltipTitle="Upload new file"
                icon="add"
                tooltipColorVariant="info"
              />
            </>
          }
        />
        <CardContent>
          <Box>
            {!allInterviews.length ? (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                  textAlign: 'center',
                  py: 4,
                }}
              >
                This collection has no files.
              </Typography>
            ) : (
              <Grid
                container
                spacing={2}
              >
                {allInterviews.map((interview) => (
                  <Grid
                    size={{ sm: 12, md: 6, lg: 4 }}
                    key={interview.interviewId}
                  >
                    <InterviewCard
                      progressTracker={progressTracker}
                      interview={interview}
                      targetUser={user}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </CardContent>
      </Card>
      <AbortUploadModal
        isOpen={isAbortModalOpen}
        handleCancelNavigation={handleCancelNavigation}
        handleConfirmNavigation={handleConfirmNavigation}
      />
      <input
        ref={fileInputRef}
        id="ingestable-file"
        type="file"
        accept=".mp3,.wav,.aac,.ogg,.flac,.avr,.cdda,.vms,.mp4,.mov"
        style={{ display: 'none' }}
        multiple
        onChange={handleFileChange}
        disabled={isFileUploading}
      />
      <ConfirmDialog
        title="Confirm delete"
        message="Are you sure you want to delete this collection? This cannot be undone!"
        confirmLabel="Yes, continue"
        isShown={showConfirmDelete}
        onConfirm={() => {
          setShowConfirmDelete(false);
          handleDeleteCollection();
        }}
        onClose={() => setShowConfirmDelete(false)}
        onReject={() => setShowConfirmDelete(false)}
      />
    </>
  );
}
