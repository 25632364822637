import { DocumentNode } from 'graphql';
import { GraphQLClient, ClientError } from 'graphql-request';
import {
  BaseQueryFn,
  createApi,
  BaseQueryApi,
} from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getCurrentAuthCredentials } from 'api/auth/auth.api';

interface GraphQLRequestParams {
  document: DocumentNode | string;
  variables?: Record<string, unknown> | void;
}

interface GraphQLErrorItem {
  message: string;
  path?: readonly (string | number)[];
  extensions?: Record<string, unknown>;
}

interface GraphQLError {
  message: string;
  errors?: GraphQLErrorItem[];
}

type GraphQLBaseQueryFn = BaseQueryFn<
  GraphQLRequestParams,
  unknown,
  GraphQLError
>;

const appsyncEndpoint: string = import.meta.env
  .VITE_REACT_APP_ADMIN_APPSYNC_API_ENDPOINT;
const client: GraphQLClient = new GraphQLClient(appsyncEndpoint);

let cachedJWT: string | null = null;
let lastJWTUpdate = 0;
const JWT_CACHE_DURATION = 10 * 60 * 1000; // 10 minutes in milliseconds

const getJWTWithCache = async (): Promise<string | null> => {
  const currentTime = Date.now();
  if (!cachedJWT || currentTime - lastJWTUpdate > JWT_CACHE_DURATION) {
    try {
      const authCredentials = await getCurrentAuthCredentials();
      cachedJWT = authCredentials.jwt;
      lastJWTUpdate = currentTime;
    } catch (err) {
      console.error('Failed to get authentication credentials:', err);
      cachedJWT = null;
    }
  }
  return cachedJWT;
};

export const graphqlBaseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: async (headers: Headers) => {
    try {
      const jwt = await getJWTWithCache();
      if (jwt) {
        headers.set('Authorization', jwt);
      }
    } catch (err) {
      console.error('Failed to get authentication credentials:', err);
      throw new Error('Failed to get authentication credentials');
    }
    return headers;
  },
});

export const baseQueryWithGraphQLAuth: GraphQLBaseQueryFn = async (
  { document, variables }: GraphQLRequestParams,
  api: BaseQueryApi,
  extraOptions?: Partial<Pick<ClientError, 'request' | 'response'>>,
) => {
  try {
    const response = await graphqlBaseQuery(
      { document, variables },
      api,
      extraOptions,
    );
    return response;
  } catch (error) {
    const graphqlError: GraphQLError = {
      message:
        error instanceof Error ? error.message : 'Unknown error occurred',
      errors: error instanceof ClientError ? error.response.errors : undefined,
    };
    console.error(error);
    return { error: graphqlError };
  }
};

export const baseApiWithGraphQL = createApi({
  baseQuery: baseQueryWithGraphQLAuth,
  endpoints: () => ({}),
  tagTypes: [
    'Users',
    'User',
    'InterviewCollections',
    'InterviewSegments',
    'TwinConfigs',
    'Voices',
    'TwinPrompts',
    'TwinDocuments',
    'TwinJobs',
  ],
});
