import { DateTime } from 'luxon';
import { useState } from 'react';
import {
  AdminUpdateTwinDocumentInput,
  SuperfeelUser,
} from 'routes/graphql.generated';
import { useAdminUpdateTwinDocumentMutation } from 'routes/user/api/user';
import { EditDocumentFormValues } from 'routes/user/documents/forms/edit-document-form';
import { useSnackbar } from 'state/context/snackBar';
import { getSignedS3Url } from 'utils/s3/getSignedS3Url';

interface UseUploadTwinDocumentOptions {
  onSuccess: () => void;
}

export default function useUploadTwinDocument(
  targetUser: SuperfeelUser,
  { onSuccess }: UseUploadTwinDocumentOptions,
) {
  const { showSnackbar } = useSnackbar();
  const [isUploading, setIsUploading] = useState(false);

  const [updateTwinDocument] = useAdminUpdateTwinDocumentMutation();

  const handleUploadDocument = async (formData: EditDocumentFormValues) => {
    const { file, text, mode, name, accessLevel } = formData;
    try {
      if (!text.trim() && !file)
        throw new Error('Provide either text or File to upload');

      const now = DateTime.now().toMillis();
      const fileName =
        mode === 'FILE'
          ? file.name.replace('.txt', `-${now}.txt`)
          : `${name.toLowerCase().replace(' ', '_')}-${now}.txt`;
      const fileToUpload =
        mode === 'FILE'
          ? file
          : new File([text.trim()], fileName, {
              type: 'text/plain',
            });

      setIsUploading(true);
      const { url } = await getSignedS3Url({
        key: fileName,
        type: 'TWIN_DOCUMENT',
        headers: JSON.stringify({
          'Content-Type': fileToUpload.type,
        }),
        method: 'PUT',
        expiry: 60 * 60 * 24,
      });

      const response = await fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': fileToUpload.type },
        body: fileToUpload,
      });

      if (!response.ok) throw new Error('Issue uploading document');

      const input: AdminUpdateTwinDocumentInput = {
        s3Key: fileName,
        targetUserId: targetUser.userId,
        documentName: name.trim(),
        audience: accessLevel,
      };

      const res = await updateTwinDocument({ input }).unwrap();
      if (!res.adminUpdateTwinDocument) {
        throw new Error('Failed to upload document.');
      }
      showSnackbar('Document Uploaded', 'success');
      setIsUploading(false);
      onSuccess();
    } catch (error) {
      showSnackbar('Error Uploading Document', 'error');
      setIsUploading(false);
    }
  };

  return {
    handleUploadDocument,
    isUploading,
  };
}
