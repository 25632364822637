import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { Add } from '@mui/icons-material';
import { useCallback } from 'react';
import { useSnackbar } from 'state/context/snackBar';
import useGetTargetUser from '../hooks/useGetTargetUser';
import {
  useAdminUpdateTwinConfigMutation,
  useSearchAdminTwinConfigsQuery,
} from '../api/user';
import TwinsContent from './components/twins';

const MAX_CONFIGS_PER_USER = 5;

export default function UserTwins() {
  const { showSnackbar } = useSnackbar();

  const { user } = useGetTargetUser();

  const { data } = useSearchAdminTwinConfigsQuery(
    {
      input: {
        targetUserId: user?.userId,
      },
    },
    {
      skip: !user?.userId,
    },
  );
  const [updateAdminTwinConfig, { isLoading: isUpdating }] =
    useAdminUpdateTwinConfigMutation();

  const handleCreateTwin = useCallback(() => {
    updateAdminTwinConfig({
      input: {
        action: 'CREATE',
        config: {
          targetUserId: user.userId,
          name: `New ${user.firstName} ${user.lastName} Heir`,
        },
      },
    })
      // If we create a reusable snackbar component and use some redux state to track, can
      // handle side effects in the onQueryStarted property in redux toolkit. Moves all
      // logic out of here.
      .unwrap()
      .then(() => {
        showSnackbar('Created twin configuration successfully', 'success');
      })
      .catch(() => {
        showSnackbar('Error creating twin configuration', 'error');
      });
  }, [showSnackbar, updateAdminTwinConfig, user]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
        >
          Heirs
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {data?.adminSearchTwinModelConfigs?.length >=
            MAX_CONFIGS_PER_USER && (
            <Typography
              p={1}
              variant="body2"
              color="error"
            >
              Max {MAX_CONFIGS_PER_USER} Heirs per user
            </Typography>
          )}
          <Button
            startIcon={<Add />}
            size="medium"
            onClick={handleCreateTwin}
            variant="contained"
            color="primary"
            label="Add new Heir"
            disabled={
              data?.adminSearchTwinModelConfigs?.length >= MAX_CONFIGS_PER_USER
            }
            loading={isUpdating}
          />
        </Box>
      </Box>
      <TwinsContent />
    </Box>
  );
}
