/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useMemo } from 'react';
import { Modal } from 'components/atoms/modal';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormReset,
} from 'react-hook-form';
import { Upload } from '@mui/icons-material';
import { Button, Typography, IconButton, Box } from '@mui/material';
import { Calendar, Select, TextField } from 'components/atoms';
import { FormFieldData, ModeType, SuperFeelTableData } from 'routes/prompt';
import { CSVParseResult } from 'utils/csv-parser';
import dayjs from 'dayjs';
import { CATEGORY, POST_TYPE } from '../../../../constants';
import { PromptView } from '../prompt-view';
import { UploadPromptsCSV } from '../upload-prompt-csv';

interface UploadPromptsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  mode: ModeType;
  onSubmit: () => Promise<void>;
  selectedPrompt: SuperFeelTableData | null;
  setMode: (mode: ModeType) => void;
  showCSVUpload: boolean;
  setShowCSVUpload: (show: boolean) => void;
  isSubmitting: boolean;
  isValid: boolean;
  register: UseFormRegister<FormFieldData>;
  control: Control<FormFieldData>;
  errors: FieldErrors<FormFieldData>;
  reset: UseFormReset<FormFieldData>;
  handleCSVDrop: (
    data: Partial<SuperFeelTableData>[],
    result: CSVParseResult<SuperFeelTableData>,
  ) => Promise<void>;
}

export function UploadPromptsModal({
  isOpen,
  closeModal,
  mode,
  onSubmit,
  selectedPrompt,
  setMode,
  showCSVUpload,
  setShowCSVUpload,
  isSubmitting,
  isValid,
  register,
  control,
  errors,
  handleCSVDrop,
}: UploadPromptsModalProps) {
  const title = useMemo(
    () =>
      mode === 'VIEW'
        ? 'Prompt details'
        : mode === 'CREATE'
          ? 'Create Prompt'
          : 'Edit Prompt',
    [mode],
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={title}
    >
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
        noValidate
        sx={{ display: 'flex', flexDirection: 'column', minHeight: 260 }}
      >
        <Box flex={1}>
          {mode === 'VIEW' ? (
            selectedPrompt ? (
              <PromptView
                prompt={selectedPrompt}
                onConfirm={closeModal}
                onEdit={() => setMode('UPDATE')}
              />
            ) : null
          ) : showCSVUpload ? (
            <Box mx={2}>
              <UploadPromptsCSV
                onValidData={(data, result) => {
                  handleCSVDrop(data, result);
                }}
              />
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              px={2}
            >
              <TextField
                {...register('prompts.0.body')}
                label="Prompt text"
                placeholder="i.e. What's been on your mind lately?"
                error={!!errors.prompts?.[0]?.body}
                helperText={errors.prompts?.[0]?.body?.message}
                multiline
                rows={2}
              />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                my={3}
              >
                <Box
                  mr={2}
                  width="100%"
                >
                  <Controller
                    name="prompts.0.category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Category"
                        options={CATEGORY}
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        error={!!errors.prompts?.[0]?.category}
                      />
                    )}
                  />
                </Box>

                <Controller
                  name="prompts.0.type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label="Prompt type"
                      options={POST_TYPE}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={!!errors.prompts?.[0]?.type}
                    />
                  )}
                />
              </Box>

              <Box
                mb={3}
                display="flex"
                flexDirection="row"
              >
                <TextField
                  {...register('prompts.0.intensity')}
                  label="Intensity"
                  type="number"
                  inputProps={{ min: 0, max: 3 }}
                  error={!!errors.prompts?.[0]?.intensity}
                  helperText={errors.prompts?.[0]?.intensity?.message}
                  sx={{ mr: 2 }}
                />
                <Controller
                  name="prompts.0.publishDate"
                  control={control}
                  render={({ field }) => (
                    <Calendar
                      label="Prompt publish date"
                      value={field.value ? dayjs(field.value) : null}
                      disablePast
                      onChange={(date: dayjs.Dayjs | null) => {
                        field.onChange(
                          date && dayjs(date).isValid()
                            ? date.toISOString()
                            : null,
                        );
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          )}
        </Box>

        {mode !== 'VIEW' && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            borderTop="1px solid #333333"
            px={2}
            py={1}
            mt={2}
          >
            <Box>
              {showCSVUpload ? (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setShowCSVUpload(false);
                  }}
                >
                  Cancel Upload
                </Button>
              ) : (
                <IconButton
                  type="button"
                  onClick={() => setShowCSVUpload(true)}
                  sx={{
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <Upload fontSize="inherit" />
                  <Typography
                    fontSize={14}
                    ml={0.5}
                  >
                    Upload from CSV
                  </Typography>
                </IconButton>
              )}
            </Box>
            <Box>
              {!showCSVUpload && (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={closeModal}
                  sx={{ mr: 2 }}
                >
                  Cancel
                </Button>
              )}
              <Button
                type="submit"
                disabled={isSubmitting || !isValid}
                variant="contained"
                size="small"
              >
                {isSubmitting
                  ? 'Submitting...'
                  : mode === 'CREATE'
                    ? 'Create'
                    : 'Update'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
