import { useEffect, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import { Loading, TableHeaderCell, TextField } from 'components/atoms';
import { useSnackbar } from 'state/context/snackBar';
import { AddUserModal } from 'routes/user/components/AddUserModal';
import { Button } from 'components/button';
import { UserAvatar } from 'components/atoms/avatar';
import { useAdminSearchUsersQuery } from 'routes/user/api/user';

export default function Users() {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [search, setSearch] = useState<string>('');
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const searchInput = search
    ? {
        exactMatch: false,
        properties: ['username', 'first_name', 'last_name'],
        terms: [search],
      }
    : undefined;

  const { data, isLoading, isError, error } = useAdminSearchUsersQuery({
    input: {
      includeHidden: true,
      limit: search ? 5 : rowsPerPage,
      startIndex: search ? 0 : page * rowsPerPage,
      search: searchInput,
    },
  });

  const users = data?.adminSearchUsers?.users ?? [];
  const totalUsers = data?.adminSearchUsers?.totalCount || 0;

  useEffect(() => {
    if (isError) {
      showSnackbar(`Failed to fetch users: ${error.message}`, 'error');
    }
  }, [isError, error, showSnackbar]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    if (newPage !== page && !isLoading) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleRowClick = (username: string) => {
    navigate(`/user/${username}`);
  };

  return (
    <Paper>
      <Box
        display="flex"
        justifyContent="flex-end"
        padding={2}
      >
        <Button
          onClick={() => setIsAddModalOpen(true)}
          label="Add New User"
          size="small"
        />
      </Box>

      <TextField
        variant="filled"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        fullWidth
        label="Search"
        size="small"
        disabled={isLoading}
      />

      <TableContainer>
        {isLoading ? (
          <Loading minHeight="82vh" />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableHeaderCell>Username</TableHeaderCell>
                <TableHeaderCell>First Name</TableHeaderCell>
                <TableHeaderCell>Last Name</TableHeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow
                  key={uuid()}
                  hover
                  onClick={() => handleRowClick(user?.username || '')}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      gap: 1,
                    }}
                  >
                    <UserAvatar
                      user={user}
                      size="s"
                    />
                    {user?.username}
                  </TableCell>
                  <TableCell>{user?.firstName}</TableCell>
                  <TableCell>{user?.lastName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <AddUserModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />
    </Paper>
  );
}
