import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  AdminUpdateTwinModelConfigInput,
  SuperfeelUser,
  TwinModelConfig,
  TwinModelTtsConfig,
  TwinPromptConfig,
} from '@superfeel/types';
import { Modal } from 'components/atoms';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'components/button';
import { LoadingButton } from '@mui/lab';
import { CircularProgress, Link, Typography } from '@mui/material';
import { useSnackbar } from 'state/context/snackBar';
import { useAdminUpdateTwinConfigMutation } from '../api/user';

type ConfigTypeMap = {
  VOICE: TwinModelTtsConfig;
  PROMPT: TwinPromptConfig;
};

type SwapConfigInput<T extends 'VOICE' | 'PROMPT'> = {
  configType: T;
  twinModelConfig?: TwinModelConfig;
  user?: SuperfeelUser;
  isOpen?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  options?: ConfigTypeMap[T][];
  onClose?: () => void;
};

const DEFAULT_SELECT_VALUE = '_default';
export function SwapConfigModal<T extends 'VOICE' | 'PROMPT'>({
  isOpen = false,
  isLoading = false,
  isError = false,
  configType,
  twinModelConfig,
  user,
  options = [],
  onClose = () => {},
}: SwapConfigInput<T>) {
  const configTypeLabel = configType.toLowerCase();

  const { showSnackbar } = useSnackbar();
  const [updateAdminTwinConfig, { isLoading: isUpdating }] =
    useAdminUpdateTwinConfigMutation();

  const [selectedConfigId, setSelectedConfigId] = useState(
    configType === 'PROMPT'
      ? twinModelConfig?.selectedPromptConfigId || DEFAULT_SELECT_VALUE
      : twinModelConfig?.selectedTtsConfigId || DEFAULT_SELECT_VALUE,
  );
  const [selectedTtsConfig, setSelectedTtsConfig] = useState<
    TwinModelTtsConfig | undefined
  >(twinModelConfig?.ttsConfig);
  const [selectedPromptConfig, setSelectedPromptConfig] = useState<
    TwinPromptConfig | undefined
  >(twinModelConfig?.promptConfig);

  const audioRef = useRef<HTMLAudioElement>(null);

  const isSameId = useMemo(
    () =>
      (configType === 'PROMPT' &&
        twinModelConfig?.selectedPromptConfigId === selectedConfigId) ||
      (configType === 'VOICE' &&
        twinModelConfig?.selectedTtsConfigId === selectedConfigId),
    [
      configType,
      selectedConfigId,
      twinModelConfig?.selectedPromptConfigId,
      twinModelConfig?.selectedTtsConfigId,
    ],
  );

  const handleSelect = useCallback(
    (selectedId: string) => {
      setSelectedConfigId(selectedId);
      const selectedConfig = options.find((opt) =>
        'ttsConfigId' in opt
          ? opt.ttsConfigId === selectedId
          : opt.promptConfigId === selectedId,
      );
      if (!selectedConfig) {
        return;
      }
      if ('ttsConfigId' in selectedConfig) {
        setSelectedTtsConfig(selectedConfig);
      } else {
        setSelectedPromptConfig(selectedConfig);
      }
    },
    [options],
  );

  const handleUpdateConfig = useCallback(() => {
    if (!user?.userId || !twinModelConfig?.modelConfigId || !selectedConfigId) {
      return;
    }
    const input: AdminUpdateTwinModelConfigInput = {
      action: 'UPDATE',
      config: {
        configId: twinModelConfig.modelConfigId,
        targetUserId: user.userId,
      },
    };
    if (configType === 'PROMPT') {
      input.config.selectedPromptConfigId = selectedConfigId;
    }
    if (configType === 'VOICE') {
      input.config.selectedTtsConfigId = selectedConfigId;
    }
    updateAdminTwinConfig({ input })
      .unwrap()
      .then(() => {
        showSnackbar('Updated twin configuration successfully', 'success');
        onClose();
      })
      .catch(() => {
        showSnackbar('Error updating twin configuration', 'error');
      });
  }, [
    configType,
    onClose,
    selectedConfigId,
    showSnackbar,
    twinModelConfig?.modelConfigId,
    updateAdminTwinConfig,
    user?.userId,
  ]);

  useEffect(() => {
    if (isOpen && !selectedConfigId) {
      const selectedId =
        configType === 'PROMPT'
          ? twinModelConfig?.selectedPromptConfigId || DEFAULT_SELECT_VALUE
          : twinModelConfig?.selectedTtsConfigId || DEFAULT_SELECT_VALUE;
      setSelectedConfigId(selectedId);
      setSelectedTtsConfig(twinModelConfig?.ttsConfig);
      setSelectedPromptConfig(twinModelConfig?.promptConfig);
    } else if (!isOpen) {
      setSelectedConfigId('');
      setSelectedTtsConfig(undefined);
      setSelectedPromptConfig(undefined);
    }
  }, [
    selectedConfigId,
    configType,
    isOpen,
    twinModelConfig?.selectedPromptConfigId,
    twinModelConfig?.selectedTtsConfigId,
    twinModelConfig?.ttsConfig,
    twinModelConfig?.promptConfig,
  ]);

  useEffect(() => {
    if (selectedTtsConfig?.voiceSampleUri) {
      audioRef.current?.play();
    }
  }, [selectedTtsConfig?.voiceSampleUri]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Select a ${configTypeLabel} config`}
      aria-labelledby="Update config modal"
      aria-describedby="Select a new voice or prompt config"
      disableBackgroundDismiss
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Box width="50%">
            <Select
              fullWidth
              value={selectedConfigId}
              onChange={(ev) => handleSelect(ev.target.value)}
              disabled={!options.length || isError}
            >
              <MenuItem
                key="loading"
                value={DEFAULT_SELECT_VALUE}
                disabled
              >
                {options.length
                  ? `Select a ${configTypeLabel}...`
                  : `No ${configTypeLabel} configs exist`}
              </MenuItem>
              {options.map((el) =>
                'ttsConfigId' in el ? (
                  <MenuItem
                    key={el.ttsConfigId}
                    value={el.ttsConfigId}
                  >
                    {el.voiceId}
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={el.promptConfigId}
                    value={el.promptConfigId}
                  >
                    {el.configName}
                  </MenuItem>
                ),
              )}
            </Select>
          </Box>
        )}
        {isError && <Typography>Error loading configs</Typography>}
      </Box>
      <Box
        sx={{
          paddingY: 2,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: 2,
          height: configType === 'PROMPT' ? 200 : 100,
        }}
      >
        {configType === 'VOICE' && !!selectedTtsConfig?.voiceSampleUri && (
          <audio
            ref={audioRef}
            key={selectedTtsConfig?.voiceSampleUri}
            style={{ width: '80%' }}
            controls
          >
            <track kind="captions" />
            <source
              src={selectedTtsConfig?.voiceSampleUri}
              type="audio/mp3"
            />
            Your browser does not support the audio element.
          </audio>
        )}
        {configType === 'VOICE' && !selectedTtsConfig?.voiceSampleUri && (
          <Typography p={1}>
            {selectedTtsConfig?.ttsConfigId
              ? 'Voice sample unavailable'
              : 'Select a voice to hear a sample'}
          </Typography>
        )}
        {configType === 'PROMPT' &&
          !!selectedPromptConfig?.promptConfigId &&
          !selectedPromptConfig?.overrideActive && (
            <>
              <Typography
                variant="body1"
                color="text.primary"
              >
                Start phrase
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {selectedPromptConfig.startPhrase}
              </Typography>
            </>
          )}
        {configType === 'PROMPT' &&
          !!selectedPromptConfig?.promptConfigId &&
          selectedPromptConfig?.overrideActive && (
            <>
              <Typography
                variant="body1"
                color="text.primary"
              >
                Custom prompt
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                  width: '80%',
                  lineHeight: '1.5em',
                  maxHeight: '4.5em',
                }}
              >
                {selectedPromptConfig.promptOverride}
              </Typography>
            </>
          )}
        {configType === 'PROMPT' && (
          <Typography
            variant="body1"
            color="text.primary"
            width="80%"
            textAlign="center"
          >
            To view the full prompt config, go to the Edit modal or the{' '}
            <Link
              href={`/user/${user.username}/prompts`}
              color="rgb(255,255,255)"
            >
              Prompts section
            </Link>
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Button
          label="Cancel"
          variant="outlined"
          onClick={onClose}
          disabled={isUpdating}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          loading={isUpdating}
          disabled={isSameId}
          onClick={handleUpdateConfig}
        >
          Update
        </LoadingButton>
      </Box>
    </Modal>
  );
}
