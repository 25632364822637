import { Modal } from 'components/atoms/modal';
import { useAdminGetUserQuery } from 'routes/user/api/user';
import { useParams } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import { Slide, Box } from '@mui/material';
import { TwinModelTtsConfig } from 'routes/graphql.generated';
import EditVoiceSpeedForm from '../../forms/edit-voice-settings-form';
import useSubmitVoiceForm from '../../hooks/useSubmitVoiceFrom';
import EditVoiceForm from '../../forms/edit-voice-form';

interface VoiceModalProps {
  isOpen: boolean;
  closeModal: () => void;
  initialVoiceConfig: TwinModelTtsConfig | null;
}

export default function VoiceModal({
  isOpen,
  closeModal,
  initialVoiceConfig,
}: VoiceModalProps) {
  const [step, setStep] = useState<'step-one' | 'step-two'>('step-one');
  const { id } = useParams<{ id: string }>();
  const { data: userData } = useAdminGetUserQuery({
    input: {
      username: id,
    },
  });

  const { status, isSubmitingVoiceForm, onSubmit, voiceSampleConfig } =
    useSubmitVoiceForm(userData?.adminGetUser.user, {
      onVoiceSubmittionComplete: () => {
        setStep('step-two');
      },
    });

  const close = useCallback(() => {
    setStep('step-one');
    closeModal();
  }, [closeModal]);

  useEffect(() => {
    if (isOpen) {
      setStep('step-one'); // Reset to step one when modal opens
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={step === 'step-one' ? 'Add/Edit a Voice' : 'Edit voice speed'}
      aria-labelledby="Edit twin config modal"
      aria-describedby="Edit an existing twin configuration"
      disableBackgroundDismiss
    >
      <Box sx={{ width: '100%' }}>
        {/* Slide transition only for step change */}
        <Box sx={{ width: '100%' }}>
          {step === 'step-one' && (
            <EditVoiceForm
              isSubmitingVoiceForm={isSubmitingVoiceForm}
              onSubmit={onSubmit}
              status={status}
              onCloseModal={close}
              initialVoiceConfig={initialVoiceConfig}
            />
          )}
        </Box>

        <Slide
          direction="left"
          in={step === 'step-two'}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ width: '100%' }}>
            {step === 'step-two' && voiceSampleConfig && (
              <EditVoiceSpeedForm
                onCloseModal={closeModal}
                voiceSampleConfig={voiceSampleConfig}
                targetUser={userData?.adminGetUser?.user}
              />
            )}
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
}
