import { Box, Container } from '@mui/material';
import React from 'react';
import MiniDrawer from '../components/organisms/drawer';

export default function Layout({ children }: { children: React.ReactNode }) {
  return (
    <Box>
      <MiniDrawer>
        <Container>{children}</Container>
      </MiniDrawer>
    </Box>
  );
}
