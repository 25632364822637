export const adminUpdatePromptMutation = `
  mutation adminUpdatePrompts($input: AdminUpdatePromptInput!) {
    adminUpdatePrompts(input: $input) {
      body
      category
      dateCreated
      type
    }
  }
`;
