import { SuperfeelUser } from '@superfeel/types';
import { wordCapitaliser } from './wordCapitaliser';

export function nameStandardiser(
  user?: SuperfeelUser,
  display: 'SINGLE_NAME' | 'SHORT_NAME' | 'FULL_NAME' = 'SINGLE_NAME',
) {
  if (!user) {
    return '';
  }
  const firstName = user?.firstName?.trim();
  const lastName = user?.lastName?.trim() || '';

  if (display === 'SINGLE_NAME') {
    return wordCapitaliser(firstName) || wordCapitaliser(lastName) || 'User';
  }
  if (display === 'SHORT_NAME') {
    return [
      wordCapitaliser(firstName),
      lastName.length ? lastName[0].toUpperCase() : '',
    ]
      .filter((n) => !!n)
      .join(' ');
  }

  // dont want to restyle name when its full name
  return [firstName, lastName].filter((n) => !!n).join(' ');
}
