import { Modal } from 'components/atoms/modal';
import { TwinModelTtsConfig } from 'routes/graphql.generated';
import { SuperfeelUser } from '@superfeel/types';
import EditVoiceSpeedForm from '../../forms/edit-voice-settings-form';

interface VoiceModalProps {
  isOpen: boolean;
  closeModal: () => void;
  config: TwinModelTtsConfig;
  targetUser: SuperfeelUser;
}

export default function VoiceEditModal({
  isOpen,
  closeModal,
  config,
  targetUser,
}: VoiceModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Add/Edit a Voice"
      aria-labelledby="Edit twin config modal"
      aria-describedby="Edit an existing twin configuration"
    >
      <EditVoiceSpeedForm
        onCloseModal={closeModal}
        voiceSampleConfig={config}
        targetUser={targetUser}
      />
    </Modal>
  );
}
