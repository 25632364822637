export function wordCapitaliser(word: string) {
  if (!word?.trim()) {
    return '';
  }
  const chars = word.split('');
  return chars.reduce(
    (combined, current, i) =>
      combined + (i ? current.toLowerCase() : current.toUpperCase()),
    '',
  );
}
