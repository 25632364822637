import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LogOut from '@mui/icons-material/Logout';
import { TwinChatComponent } from 'components/twin-chat';
import { nameStandardiser } from 'utils/nameStandardiser';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCurrentUserAttributes } from '@api/auth/auth.api';
import { signOut } from 'aws-amplify/auth';
import { useGetTwinInfoQuery } from './api';

type EmbeddedChatProps = {
  deploymentId?: string;
  width?: string | number;
  height?: string | number;
};

export function EmbeddedTwinChat({
  deploymentId,
  width = 0,
  height = 0,
}: EmbeddedChatProps) {
  const {
    isLoading: isLoadingTwin,
    data: twinData,
    isError,
  } = useGetTwinInfoQuery(
    {
      deploymentId,
    },
    { skip: !deploymentId },
  );
  const [name, setName] = useState('');

  const widgetTitle = useMemo(() => {
    if (!twinData) {
      return '';
    }
    const displayText = twinData?.twinModelConfigs?.[0]?.displayText || '';
    const message =
      displayText ||
      `Hi {{name}}, \nPress Start Call to chat with ${nameStandardiser(twinData)}`;
    return message.replaceAll('{{name}}', name);
  }, [name, twinData]);

  const getUserName = useCallback(() => {
    getCurrentUserAttributes().then((res) => {
      setName(res.name.split(' ')[0]);
    });
  }, []);

  const handleExit = useCallback(() => {
    signOut({ global: true });
  }, []);

  useEffect(() => {
    getUserName();
  }, [getUserName]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
      }}
    >
      <Tooltip
        title="Exit"
        arrow
      >
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0, m: 2 }}
          onClick={handleExit}
        >
          <LogOut />
        </IconButton>
      </Tooltip>
      {deploymentId && !isError && (
        <TwinChatComponent
          isLoading={isLoadingTwin}
          user={twinData}
          title={widgetTitle}
          deploymentId={deploymentId}
        />
      )}
      {deploymentId && isError && (
        <Typography
          color="white"
          textAlign="center"
        >
          Could not load chat for ID {deploymentId}
          <br />
          Please try again
        </Typography>
      )}
      {!deploymentId && (
        <Typography
          color="white"
          textAlign="center"
        >
          No chat ID provided
          <br />
          Please try again
        </Typography>
      )}
    </Box>
  );
}
