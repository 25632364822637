import { Duration } from 'luxon';

export function secsToRelativeTime(seconds: number): string {
  if (!seconds && seconds !== 0) return '-';

  const duration = Duration.fromObject({ seconds });

  const hours = Math.floor(duration.as('hours'));
  const mins = Math.floor(duration.as('minutes') % 60);
  const secs = Math.round(duration.as('seconds') % 60);

  if (hours >= 1) {
    return `${hours}h ${mins}m ${secs}s`;
  }

  if (mins >= 1) {
    return `${mins}m ${secs}s`;
  }

  return `${secs}s`;
}
