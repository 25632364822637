/**
 * A utility function to convert an enum value into a readable string.
 * @param str
 * @returns a reable string
 */
export function convertEnumToReadableString(str: string): string {
  return str
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
