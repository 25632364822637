import { DailyRoomInfo } from '@daily-co/daily-js';
import { useDaily, useDailyEvent } from '@daily-co/daily-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import {
  usePostInviteTwinMutation,
  usePostNewRoomMutation,
  usePutGuestUserMutation,
} from 'components/widget/api';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useAdminCreateRoomMutation,
  useAdminInviteTwinToRoomMutation,
} from 'routes/user/api/user';

type CallState =
  | 'STATE_IDLE'
  | 'STATE_CREATING'
  | 'STATE_INVITING'
  | 'STATE_ACTIVE'
  | 'STATE_LEAVING';

type SentAppMessageAction = 'MEDIA_UPDATE' | 'IDENTITY_UPDATE' | 'TOPIC_UPDATE';

type InviteTwinRequest = {
  model: 'basic';
  room: string;
  config: {
    model_config_id: string;
    topic_id?: string;
  };
};

type InviteTwinLocalParams = {
  roomUrl: string;
  modelConfigId: string;
  domain: string;
  topicId?: string;
};

async function inviteTwinDirect({
  roomUrl,
  modelConfigId,
  domain,
  topicId,
}: InviteTwinLocalParams) {
  const body: InviteTwinRequest = {
    model: 'basic',
    room: roomUrl,
    config: {
      model_config_id: modelConfigId,
    },
  };

  if (topicId) {
    body.config.topic_id = topicId;
  }

  const auth = await fetchAuthSession({ forceRefresh: false });
  const jwt = auth.tokens?.idToken?.toString();
  const res = await fetch(`${domain}/call/twins/v1`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt}`,
    },
    body: JSON.stringify(body),
  });
  if (!res.ok) {
    throw new Error(res.statusText);
  }
}

async function checkCustomServerActive(domain: string) {
  const validProtocol =
    domain.startsWith('http://') || domain.startsWith('https://');
  if (!validProtocol) {
    return false;
  }
  try {
    const url = new URL(`${domain}/health`);
    const res = await fetch(url);
    console.warn('HEALTH CHECK', res);
    return res.ok;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export const useTwinConversation = () => {
  const callObject = useDaily();

  const [adminCreateRoom] = useAdminCreateRoomMutation();
  const [adminInviteTwinToRoom] = useAdminInviteTwinToRoomMutation();

  // For use with widget only
  const [postNewRoom] = usePostNewRoomMutation();
  const [postInviteTwin] = usePostInviteTwinMutation();
  const [putGuestUser] = usePutGuestUserMutation();

  const [error, setError] = useState('');
  const [callState, setCallState] = useState<CallState>('STATE_IDLE');
  const [timeJoined, setTimeJoined] = useState(0);
  const [callDuration, setCallDuration] = useState(0);
  const [localVolume, setLocalVolume] = useState(0);
  const [remoteVolume, setRemoteVolume] = useState(0);
  const [isMuted, setMuted] = useState(false);
  const [customServerDomain, setCustomServerDomain] = useState('');
  const [roomExpiryUnix, setRoomExpiryUnix] = useState(0);
  const [remainingSecs, setRemainingSecs] = useState(0);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  const toggleMicMute = useCallback(() => {
    // Handle callObject separately to allow toggling whilst room is initialising
    setMuted(!isMuted);
  }, [isMuted]);

  const setCustomTwinServerDomain = useCallback((input: string) => {
    setCustomServerDomain(input.trim());
  }, []);

  const setRoomTimer = useCallback(() => {
    callObject
      .room()
      .then((res) => {
        if (!res) {
          return;
        }
        const data = res as DailyRoomInfo;
        const { exp = 0 } = data.config;
        setRoomExpiryUnix(exp);
        setRemainingSecs(exp - DateTime.now().toSeconds());
      })
      .catch((err) => console.warn(err));
  }, [callObject]);

  const initialiseRoom = useCallback(
    async (modelConfigId: string, topicId?: string) => {
      setError('');
      if (customServerDomain) {
        const isServerActive =
          await checkCustomServerActive(customServerDomain);
        if (!isServerActive) {
          setError('Custom server not found');
          return;
        }
      }
      abortControllerRef.current = new AbortController();
      try {
        setCallState('STATE_CREATING');
        const roomResult = await adminCreateRoom({}).unwrap();
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        const url = roomResult?.adminCreateRoom?.url;
        if (!url) {
          throw new Error('No room URL returned');
        }
        await callObject.preAuth({ url });
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        await callObject.join({
          url,
          startVideoOff: true,
          startAudioOff: false,
          audioSource: 'default',
        });
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        setCallState('STATE_INVITING');
        callObject.setLocalAudio(!isMuted);
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        if (customServerDomain) {
          await inviteTwinDirect({
            roomUrl: url,
            domain: customServerDomain,
            modelConfigId,
            topicId,
          });
        } else {
          await adminInviteTwinToRoom({
            input: {
              modelConfigId,
              topicId,
              roomUrl: url,
            },
          }).unwrap();
        }
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        setRoomTimer();
      } catch (err) {
        console.warn(err);
        if (!abortControllerRef.current?.signal?.aborted) {
          setError('Error starting call - please try again');
        }
        abortControllerRef.current = null;
        setCallState('STATE_IDLE');
        await callObject.leave();
      }
    },
    [
      adminCreateRoom,
      adminInviteTwinToRoom,
      setRoomTimer,
      callObject,
      customServerDomain,
      isMuted,
    ],
  );

  const initialiseRoomFromWidget = useCallback(
    async (deploymentId: string) => {
      setError('');
      abortControllerRef.current = new AbortController();
      try {
        setCallState('STATE_CREATING');
        await putGuestUser({ deploymentId }).unwrap();
        const roomResult = await postNewRoom({ deploymentId }).unwrap();
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        const url = roomResult?.url;
        if (!url) {
          throw new Error('No room URL returned');
        }
        await callObject.preAuth({ url });
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        await callObject.join({
          url,
          startVideoOff: true,
          startAudioOff: false,
          audioSource: 'default',
        });
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        setCallState('STATE_INVITING');
        callObject.setLocalAudio(!isMuted);
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        await postInviteTwin({
          deploymentId,
          url,
        }).unwrap();
        if (abortControllerRef.current.signal.aborted) {
          throw new Error('Cancel initialise room');
        }
        setRoomTimer();
      } catch (err) {
        console.warn(err);
        if (!abortControllerRef.current?.signal?.aborted) {
          setError('Error starting call - please try again');
        }
        abortControllerRef.current = null;
        setCallState('STATE_IDLE');
        await callObject.leave();
      }
    },
    [
      callObject,
      isMuted,
      postInviteTwin,
      postNewRoom,
      putGuestUser,
      setRoomTimer,
    ],
  );

  const exitCall = useCallback(async () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setCallDuration(0);
    setCallState('STATE_LEAVING');
    setRemoteVolume(0);
    setLocalVolume(0);
    setTimeJoined(0);
    setRoomExpiryUnix(0);
    setRemainingSecs(0);
    try {
      await callObject.leave();
    } catch (err) {
      console.warn(err);
      setError('Error leaving call');
    } finally {
      setCallState('STATE_IDLE');
    }
  }, [callObject]);

  const handleTwinJoined = useCallback(() => {
    setCallState('STATE_ACTIVE');
    setTimeJoined(Date.now());
    callObject.startRemoteParticipantsAudioLevelObserver(100);
  }, [callObject]);

  const sendAppMessage = useCallback(
    (event: SentAppMessageAction, data: Record<string, unknown>) => {
      try {
        console.debug('SENDING APP MESSAGE', { event, data });
        callObject.sendAppMessage({ event, data });
      } catch (err) {
        console.warn(err);
      }
    },
    [callObject],
  );

  const checkRemainingTime = useCallback(() => {
    const remainingTime = Math.ceil(
      roomExpiryUnix - DateTime.now().toSeconds(),
    );
    setRemainingSecs(remainingTime);
    if (remainingTime <= 0) {
      exitCall();
    }
  }, [exitCall, roomExpiryUnix]);

  const submitIdentityUpdate = useCallback(async () => {
    const auth = await fetchAuthSession({ forceRefresh: false });
    const jwt = auth.tokens?.idToken?.toString();
    if (!jwt) {
      return;
    }
    // required to make requests to retrieval api from call server
    sendAppMessage('IDENTITY_UPDATE', { jwt });
  }, [sendAppMessage]);

  const handleRemoteVolumeChange = useCallback((vol: number) => {
    // Safari reports volume on a logarithmic scale
    const isSafari = /^((?!chrome|chromium|android).)*safari/i.test(
      navigator.userAgent,
    );
    const normalised = isSafari
      ? (Math.log10(vol) + 5) / 10
      : Math.min(vol * 3, 1);
    setRemoteVolume(normalised);
  }, []);

  useDailyEvent('participant-joined', () => {
    handleTwinJoined();
  });
  useDailyEvent('participant-left', () => {
    exitCall();
  });
  useDailyEvent(
    'remote-participants-audio-level',
    ({ participantsAudioLevel }) => {
      const allVolumes = Object.values(participantsAudioLevel);
      const vol = allVolumes.length ? allVolumes[0] : 0;
      handleRemoteVolumeChange(vol);
    },
  );
  useDailyEvent('local-audio-level', ({ audioLevel }) => {
    setLocalVolume(audioLevel);
  });

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (timeJoined && callState === 'STATE_ACTIVE') {
      timeoutRef.current = setInterval(() => {
        const duration = Math.floor((Date.now() - timeJoined) / 1000);
        setCallDuration(duration);
        checkRemainingTime();

        // update jwt on server every 60 secs
        if (!!duration && duration % 60 === 0) {
          submitIdentityUpdate();
        }
      }, 1000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [callState, submitIdentityUpdate, timeJoined, checkRemainingTime]);

  useEffect(() => {
    if (!callObject || callState !== 'STATE_ACTIVE') {
      return;
    }
    const isMicActive = callObject.localAudio();
    if (isMuted && isMicActive) {
      callObject.setLocalAudio(false);
      console.debug('Microphone muted');
    }
    if (!isMuted && !isMicActive) {
      callObject.setLocalAudio(true);
      console.debug('Microphone activated');
    }
  }, [callObject, callState, isMuted]);

  return {
    callState,
    callDuration,
    isMuted,
    localVolume,
    remoteVolume,
    customServerDomain,
    remainingSecs,
    roomExpiryUnix,
    error,
    initialiseRoom,
    initialiseRoomFromWidget,
    exitCall,
    sendAppMessage,
    toggleMicMute,
    setCustomTwinServerDomain,
  };
};
