import { IconButton, CircularProgress, SxProps } from '@mui/material';
import {
  Close,
  Add,
  Edit,
  Info,
  ArrowForward,
  Settings,
  MapsUgc,
  ArrowBack,
  Tune,
  Speed,
  CopyAll,
  Launch,
  Download,
  Delete,
  Warning,
  AutoAwesome,
  Undo,
  SwapHoriz,
  Call,
  Preview,
  RecordVoiceOver,
} from '@mui/icons-material';
import { ColorVariant, Tooltip } from 'components/tooltip';

type NewType = ColorVariant;
export type IconType =
  | 'close'
  | 'add'
  | 'delete'
  | 'edit'
  | 'info'
  | 'go'
  | 'settings'
  | 'posts'
  | 'back'
  | 'tune'
  | 'speed'
  | 'copy'
  | 'launch'
  | 'download'
  | 'sparkle'
  | 'warning'
  | 'undo'
  | 'swap'
  | 'call'
  | 'preview'
  | 'recordvoiceover';

interface ButtonIconTooltipProps {
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltipTitle: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  tooltipColorVariant?: NewType;
  icon?: IconType;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
}

export function ButtonIconTooltip({
  onClick = () => {},
  isLoading = false,
  isDisabled = false,
  tooltipTitle,
  tooltipPlacement = 'top',
  tooltipColorVariant = 'primary',
  icon = 'close',
  size = 'medium',
  sx = {},
}: ButtonIconTooltipProps) {
  const getIcon = () => {
    switch (icon) {
      case 'add':
        return <Add fontSize={size} />;
      case 'edit':
        return <Edit />;
      case 'info':
        return <Info />;
      case 'go':
        return <ArrowForward />;
      case 'settings':
        return <Settings />;
      case 'posts':
        return <MapsUgc />;
      case 'back':
        return <ArrowBack />;
      case 'tune':
        return <Tune />;
      case 'speed':
        return <Speed />;
      case 'copy':
        return <CopyAll />;
      case 'launch':
        return <Launch />;
      case 'download':
        return <Download />;
      case 'delete':
        return <Delete />;
      case 'close':
        return <Close />;
      case 'warning':
        return <Warning />;
      case 'sparkle':
        return <AutoAwesome />;
      case 'undo':
        return <Undo />;
      case 'swap':
        return <SwapHoriz />;
      case 'call':
        return <Call />;
      case 'preview':
        return <Preview />;
      case 'recordvoiceover':
        return <RecordVoiceOver />;
      default:
        return <Close />;
    }
  };

  return (
    <Tooltip
      title={tooltipTitle}
      placement={tooltipPlacement}
      colorVariant={tooltipColorVariant}
    >
      <IconButton
        size={size}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        sx={sx}
      >
        {isLoading ? <CircularProgress size={24} /> : getIcon()}
      </IconButton>
    </Tooltip>
  );
}
