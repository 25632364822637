import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Stack,
  Box,
  IconButton,
} from '@mui/material';
import { Edit, Check, Close } from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { ConfirmDialog } from 'components/atoms/dialog';
import { TwinPromptConfig } from 'routes/graphql.generated';
import { useAdminUpdateTwinPromptMutation } from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';
import { checkPromptValid } from 'utils/checkPromptValid';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { SuperfeelUser } from '@superfeel/types';

interface TwinPromptConfigCardProps {
  config: TwinPromptConfig;
  targetUser: SuperfeelUser;
  onEdit?: (config: TwinPromptConfig) => void;
}

export default function TwinPromptConfigCard({
  config,
  targetUser,
  onEdit = () => {},
}: TwinPromptConfigCardProps) {
  const { showSnackbar } = useSnackbar();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [onUpdateTwinPrompt, { isLoading: isDeleting }] =
    useAdminUpdateTwinPromptMutation();

  const onDeletePrompt = useCallback(() => {
    onUpdateTwinPrompt({
      input: {
        action: 'DELETE',
        prompt: {
          promptConfigId: config.promptConfigId,
          targetUserId: targetUser.userId,
        },
      },
    })
      .unwrap()
      .then(() => {
        showSnackbar('Deleted Prompt config', 'success');
      })
      .catch((err) => {
        showSnackbar(
          `Error deleteing config: ${(err as Error)?.message}`,
          'error',
        );
      });
  }, [
    config.promptConfigId,
    onUpdateTwinPrompt,
    showSnackbar,
    targetUser.userId,
  ]);

  return (
    <Card variant="outlined">
      <CardHeader
        title={
          <>
            <Typography variant="h5">{config.configName}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {config.activeModelConfigIds?.length
                ? `Used by ${config.activeModelConfigIds?.length} Heir config${config.activeModelConfigIds?.length > 1 ? 's' : ''}`
                : 'Not in use'}
            </Typography>
          </>
        }
        action={
          <Box>
            <IconButton
              disabled={isDeleting}
              onClick={() => onEdit(config)}
            >
              <Edit />
            </IconButton>
            <ButtonIconTooltip
              icon="delete"
              isDisabled={isDeleting || !!config.activeModelConfigIds?.length}
              isLoading={isDeleting}
              onClick={() => setShowConfirmDelete(true)}
              tooltipTitle={
                config.activeModelConfigIds?.length
                  ? 'Cannot delete while prompt config is in use'
                  : ''
              }
              tooltipColorVariant="error"
            />
          </Box>
        }
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'flex-row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <Stack
          spacing={2}
          maxWidth="80%"
        >
          {!config.overrideActive && config.startPhrase && (
            <Box>
              <Typography
                variant="body1"
                color="text.primary"
              >
                Start Phrase:
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {config.startPhrase || '-'}
              </Typography>
            </Box>
          )}
          {config.overrideActive && config.promptOverride && (
            <Box>
              <Typography
                variant="body1"
                color="text.primary"
              >
                Custom prompt:
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  display: 'block',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  lineHeight: '1.5em',
                  maxHeight: '3em',
                  width: '80%',
                }}
              >
                {config.promptOverride || '-'}
              </Typography>
            </Box>
          )}
          {config.namePronunciation && (
            <Box>
              <Typography
                variant="body1"
                color="text.primary"
              >
                Name Pronunciation:
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
              >
                {config.namePronunciation || '-'}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mr: 2 }}
            >
              Complete:
            </Typography>
            {checkPromptValid(config) ? <Check /> : <Close />}
          </Box>
          <Box
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ mr: 2 }}
            >
              Override Active:
            </Typography>
            {config.overrideActive ? <Check /> : <Close />}
          </Box>
        </Stack>
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Date created:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.dateCreated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body1"
              color="text.primary"
            >
              Last updated:
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {DateTime.fromISO(config.lastUpdated).toLocaleString(
                DateTime.DATETIME_SHORT,
              )}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
      <ConfirmDialog
        title="Confirm delete"
        message="Are you sure you want to delete this prompt config? This cannot be undone!"
        confirmLabel="Yes, continue"
        isShown={showConfirmDelete}
        onConfirm={() => {
          setShowConfirmDelete(false);
          onDeletePrompt();
        }}
        onClose={() => setShowConfirmDelete(false)}
        onReject={() => setShowConfirmDelete(false)}
      />
    </Card>
  );
}
