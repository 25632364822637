import { LanguageOption } from './forms/edit-voice-form';

export function languageStringToSymbol(option: LanguageOption) {
  switch (option) {
    case 'SPANISH':
      return 'es';
    case 'FRENCH':
      return 'fr';
    default:
      return 'en';
  }
}
