import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import CloudUpload from '@mui/icons-material/CloudUpload';
import PauseCircle from '@mui/icons-material/PauseCircle';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Save from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import { DateTime } from 'luxon';
import { SuperfeelUser, TwinDocument } from '@superfeel/types';
import { useCallback, useMemo, useState } from 'react';
import { convertEnumToReadableString } from 'utils/convertEnumToReadableString';
import { ButtonIconTooltip } from 'components/button-icon-tooltip';
import { formatFileSize } from 'common/utils';
import { Modal } from 'components/atoms';
import { Button } from 'components/button';
import { useAdminUpdateTwinJobMutation } from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';
import UserDocumentPreview from '../user-document-preview';

interface TwinDocumentCardProps {
  document: TwinDocument;
  targetUser?: SuperfeelUser;
}

const extractFileNameFromUri = (uri: string) => {
  const { pathname } = new URL(uri);
  return pathname.slice(1);
};

export default function UserDocumentCard({
  document,
  targetUser,
}: TwinDocumentCardProps) {
  const { showSnackbar } = useSnackbar();
  const [previewModal, setPreviewModal] = useState(false);
  const [updateTwinJob, { isLoading }] = useAdminUpdateTwinJobMutation();

  const ingestionIcon = useMemo(() => {
    const { twinGraphIngestionStatus } = document;
    if (!twinGraphIngestionStatus) {
      return null;
    }
    if (twinGraphIngestionStatus === 'NOT_STARTED') {
      return <RadioButtonUnchecked color="primary" />;
    }
    if (twinGraphIngestionStatus === 'SUCCEEDED') {
      return <CheckCircle color="success" />;
    }
    if (twinGraphIngestionStatus === 'FAILED') {
      return <Cancel color="error" />;
    }
    if (twinGraphIngestionStatus === 'QUEUED') {
      return <PauseCircle color="secondary" />;
    }
    if (
      twinGraphIngestionStatus === 'PENDING' ||
      twinGraphIngestionStatus === 'STARTING' ||
      twinGraphIngestionStatus === 'SUBMITTED'
    ) {
      return <RadioButtonChecked color="info" />;
    }
    return <RadioButtonChecked color="warning" />;
  }, [document]);

  const onToggleModal = useCallback(() => {
    setPreviewModal(!previewModal);
  }, [previewModal]);

  const handleSubmitJob = useCallback(() => {
    if (isLoading || !document?.documentId || !targetUser?.userId) {
      return;
    }
    updateTwinJob({
      input: {
        index: 'demo',
        documentIds: [document.documentId],
        targetUserId: targetUser.userId,
      },
    })
      .then(() => showSnackbar('Document submitted', 'success'))
      .catch((err) => {
        console.error(err);
        showSnackbar('Error submitting document', 'error');
      });
  }, [
    document.documentId,
    isLoading,
    showSnackbar,
    targetUser?.userId,
    updateTwinJob,
  ]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{ marginBottom: 2 }}
      >
        <CardHeader
          title={
            <>
              <Typography
                variant="h4"
                color="text.primary"
                noWrap
              >
                {document.documentName}
              </Typography>
              <Typography
                variant="body2"
                color="text.primary"
                noWrap
              >
                {extractFileNameFromUri(document.contentUri)}
              </Typography>
            </>
          }
          action={
            <ButtonIconTooltip
              icon="preview"
              tooltipTitle="Show preview"
              onClick={onToggleModal}
              tooltipColorVariant="info"
            />
          }
        />
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'flex-row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <Stack spacing={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Audience">
                <Visibility />
              </Tooltip>
              <Typography
                variant="body2"
                color="text.secondary"
                ml={2}
              >
                {document.audience}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Tooltip title="File size">
                <Save />
              </Tooltip>
              <Typography
                variant="body2"
                color="text.secondary"
                ml={2}
              >
                {formatFileSize(document.fileSize)}
              </Typography>
            </Box>
            {document.twinGraphIngestionStatus && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Tooltip title="Graph ingestion status">
                  <CloudUpload sx={{ mr: 2 }} />
                </Tooltip>
                <Tooltip
                  title={convertEnumToReadableString(
                    document.twinGraphIngestionStatus,
                  )}
                >
                  {ingestionIcon}
                </Tooltip>
                {document.twinGraphIngestionStatus === 'FAILED' && (
                  <Button
                    size="small"
                    label="Retry"
                    sx={{ ml: 2 }}
                    loading={isLoading}
                    onClick={handleSubmitJob}
                  />
                )}
                {document.twinGraphIngestionStatus === 'NOT_STARTED' && (
                  <Button
                    size="small"
                    label="Start Ingestion"
                    sx={{ ml: 2 }}
                    loading={isLoading}
                    onClick={handleSubmitJob}
                  />
                )}
              </Box>
            )}
          </Stack>
          <Stack spacing={2}>
            {document.dateCreated && (
              <Box>
                <Typography
                  variant="body1"
                  color="text.primary"
                >
                  Date Created:
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {DateTime.fromISO(document.dateCreated).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Typography>
              </Box>
            )}
            {document.lastUpdated && (
              <Box>
                <Typography
                  variant="body1"
                  color="text.primary"
                >
                  Last Updated:
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >
                  {DateTime.fromISO(document.lastUpdated).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Typography>
              </Box>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Modal
        onClose={onToggleModal}
        title={`Preview of ${extractFileNameFromUri(document.contentUri)}`}
        isOpen={previewModal}
      >
        <UserDocumentPreview document={document} />
      </Modal>
    </>
  );
}
