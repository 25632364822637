export type ValidationFunction<T> = (value: string) => T | undefined;

export interface HeaderConfig<T> {
  key: keyof T;
  validate?: ValidationFunction<T[keyof T]>;
}

export type CSVParseResult<T> =
  | { success: true; data: Partial<T>[]; error?: string }
  | { success: false; error: string };

export const parseCSV = <T extends Record<string, unknown>>(
  csvData: string[][],
  headerConfig: HeaderConfig<T>[],
): CSVParseResult<T> => {
  if (csvData.length < 2) {
    return {
      success: false,
      error: 'CSV file must contain headers and at least one row of data',
    };
  }

  const headers = csvData[0].map((header) => header.toLowerCase().trim());
  const requiredHeaders = headerConfig.map((config) => config.key as string);
  const missingHeaders = requiredHeaders.filter(
    (required) => !headers.includes(required.toLowerCase()),
  );

  if (missingHeaders.length > 0) {
    return {
      success: false,
      error: `CSV is missing required headers: ${missingHeaders.join(', ')}`,
    };
  }

  try {
    const headerIndices = requiredHeaders.reduce(
      (acc, header) => ({
        ...acc,
        [header]: headers.indexOf(header.toLowerCase()),
      }),
      {} as Record<string, number>,
    );

    const transformedData = csvData.slice(1).map((row, rowIndex) => {
      return headerConfig.reduce((acc, { key, validate }) => {
        const value = row[headerIndices[key as string]]?.trim() ?? '';
        if (validate) {
          const validatedValue = validate(value);
          if (validatedValue === undefined) {
            throw new Error(
              `Row ${rowIndex + 2}: Invalid value for ${key.toString()} - ${value}`,
            );
          }
          return { ...acc, [key]: validatedValue };
        }
        return { ...acc, [key]: value };
      }, {} as Partial<T>);
    });

    return { success: true, data: transformedData };
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to parse CSV',
    };
  }
};
