import { SxProps } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import { SuperfeelUser } from '@superfeel/types';
import { useMemo } from 'react';

type AvatarSize = 'xs' | 's' | 'm' | 'l' | 'xl';

type AvatarProps = {
  user?: SuperfeelUser;
  size?: AvatarSize;
  colour?: string;
  sx?: SxProps;
};

const sizeMap: Record<AvatarSize, number> = {
  xs: 24,
  s: 32,
  m: 64,
  l: 128,
  xl: 256,
};

function stringToColor(str: string) {
  if (!str) {
    return '#111111';
  }
  let hash = 0;
  let i = 0;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function getUserInitials(user?: SuperfeelUser) {
  return [user?.firstName || '', user?.lastName || '']
    .filter((el) => !!el)
    .reduce((prev, current) => prev + (current[0] || '').toUpperCase(), '');
}

function getProfilePicture(user: SuperfeelUser, size: AvatarSize) {
  if (!user?.profilePictureUri) {
    return null;
  }
  if (size === 'xl') {
    return (
      user?.profilePictureUri1024 ||
      user?.profilePictureUri512 ||
      user?.profilePictureUri
    );
  }
  if (size === 'l' || size === 'm') {
    return user?.profilePictureUri512 || user?.profilePictureUri;
  }
  return user.profilePictureUri;
}

export function UserAvatar({ user, colour, size = 's', sx }: AvatarProps) {
  const style: SxProps = useMemo(
    () => ({
      height: sizeMap[size],
      width: sizeMap[size],
      backgroundColor: colour || stringToColor(user.username),
      fontSize: Math.ceil(sizeMap[size] / 2.5),
      ...sx,
    }),
    [colour, size, user.username, sx],
  );

  if (user?.profilePictureUri) {
    return (
      <Avatar
        src={getProfilePicture(user, size)}
        sx={style}
      />
    );
  }
  return <Avatar sx={style}>{getUserInitials(user)}</Avatar>;
}
