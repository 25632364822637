import { Modal } from 'components/atoms';
import { SuperfeelUser, TwinPromptConfig } from 'routes/graphql.generated';
import EditPromptForm from '../../forms/edit-prompt-form';

interface EditPromptModalProps {
  isOpen: boolean;
  closeModal: () => void;
  targetUser: SuperfeelUser;
  initialConfig?: TwinPromptConfig;
}

export default function EditPromptModal({
  isOpen,
  closeModal,
  targetUser,
  initialConfig,
}: EditPromptModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Edit/Add Prompt config"
      aria-labelledby="Edit prompt config modal"
      aria-describedby="Edit an existing prompt configuration"
      disableBackgroundDismiss
    >
      <EditPromptForm
        targetUser={targetUser}
        initialConfig={initialConfig}
        closeModal={closeModal}
      />
    </Modal>
  );
}
