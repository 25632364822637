import { Box, Typography } from '@mui/material';
import { Button } from 'components/button';
import { Add } from '@mui/icons-material';
import { useState } from 'react';
import UserPromptsView from './components/user-prompts';
import useGetTargetUser from '../hooks/useGetTargetUser';
import { useAdminSearchTwinPromptQuery } from '../api/user';
import EditPromptModal from './components/edit-prompt-modal';

const MAX_CONFIGS_PER_USER = 5;

export default function UserPrompts() {
  const { user } = useGetTargetUser();
  const { data } = useAdminSearchTwinPromptQuery(
    {
      input: {
        targetUserId: user?.userId,
      },
    },
    {
      skip: !user,
    },
  );

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
        >
          System Prompts
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {data?.adminSearchTwinPrompt?.length >= MAX_CONFIGS_PER_USER && (
            <Typography
              p={1}
              variant="body2"
              color="error"
            >
              Max {MAX_CONFIGS_PER_USER} prompts per user
            </Typography>
          )}
          <Button
            startIcon={<Add />}
            size="medium"
            onClick={() => setIsEditModalOpen(true)}
            variant="contained"
            color="primary"
            label="Add prompt"
            disabled={
              data?.adminSearchTwinPrompt?.length >= MAX_CONFIGS_PER_USER
            }
          />
        </Box>
      </Box>
      <UserPromptsView />
      <EditPromptModal
        isOpen={isEditModalOpen}
        closeModal={() => setIsEditModalOpen(false)}
        targetUser={user}
        initialConfig={null}
      />
    </Box>
  );
}
