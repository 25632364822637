import {
  fetchUserAttributes,
  FetchUserAttributesOutput,
} from 'aws-amplify/auth';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

interface AppContextState {
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
  accountSettingsOpen: boolean;
  setAccountSettingsOpen: (value: boolean) => void;
  userAttributes: FetchUserAttributesOutput | undefined;
}

const AppContext = createContext<AppContextState | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};

interface AppContextProviderProps {
  children: ReactNode;
}

export function AppContextProvider({
  children,
}: AppContextProviderProps): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [accountSettingsOpen, setAccountSettingsOpen] = useState(true);
  const [userAttributes, setUserAttributes] = useState<
    FetchUserAttributesOutput | undefined
  >(undefined);

  const getUserAttributes = useCallback(() => {
    fetchUserAttributes()
      .then((res) => setUserAttributes(res))
      .catch((err) => {
        console.warn(err);
        setUserAttributes(undefined);
      });
  }, []);

  useEffect(() => {
    getUserAttributes();
  }, [getUserAttributes]);

  const contextValue = useMemo(
    () => ({
      drawerOpen,
      setDrawerOpen,
      accountSettingsOpen,
      setAccountSettingsOpen,
      userAttributes,
    }),
    [
      drawerOpen,
      setDrawerOpen,
      accountSettingsOpen,
      setAccountSettingsOpen,
      userAttributes,
    ],
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}
