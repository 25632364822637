import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { useCallback, useState } from 'react';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { useAdminSearchTwinPromptQuery } from 'routes/user/api/user';
import { Loading } from 'components/atoms';
import { TwinPromptConfig } from '@superfeel/types';
import TwinPromptConfigCard from '../twin-prompt-config';
import EditPromptModal from '../edit-prompt-modal';

export default function UserPrompts() {
  const [initialConfig, setInitialConfig] = useState<TwinPromptConfig | null>(
    null,
  );
  const { user } = useGetTargetUser();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { data, isLoading, isError } = useAdminSearchTwinPromptQuery(
    {
      input: {
        targetUserId: user?.userId,
      },
    },
    {
      skip: !user,
    },
  );

  const onCloseEditModal = useCallback(() => {
    setIsEditModalOpen(false);
    setInitialConfig(null);
  }, []);

  const onOpenModal = useCallback((config?: TwinPromptConfig) => {
    if (config && config.promptConfigId) setInitialConfig(config || null);
    setIsEditModalOpen(true);
  }, []);

  if (isError) return <div>Error fetching prompts</div>;
  if (isLoading || !data) return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {!data?.adminSearchTwinPrompt?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
          gap={3}
        >
          <Box
            textAlign="center"
            maxWidth="450px"
          >
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
            >
              Get started with your first prompt
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Add prompts to give your Heir personality
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
        >
          {data.adminSearchTwinPrompt &&
            data.adminSearchTwinPrompt.map((config) => (
              <Grid
                size={{ sm: 12, md: 12, lg: 6 }}
                key={config.promptConfigId}
              >
                <TwinPromptConfigCard
                  key={config.promptConfigId}
                  config={config}
                  onEdit={onOpenModal}
                  targetUser={user}
                />
              </Grid>
            ))}
        </Grid>
      )}
      <EditPromptModal
        isOpen={isEditModalOpen}
        closeModal={onCloseEditModal}
        targetUser={user}
        initialConfig={initialConfig}
      />
    </Box>
  );
}
