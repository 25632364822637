import React from 'react';
import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

export type ColorVariant =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'info'
  | 'success';

interface TooltipProps {
  title: string;
  placement?: MUITooltipProps['placement'];
  colorVariant?: ColorVariant;
  children: React.ReactElement;
}

export function Tooltip({
  title,
  placement = 'top',
  colorVariant = 'primary',
  children,
}: TooltipProps) {
  const theme = useTheme();

  return (
    <MUITooltip
      title={title}
      placement={placement}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontWeight: 600,
            fontSize: '0.75rem',
            bgcolor: alpha(theme.palette[colorVariant].main, 0.8),
            '& .MuiTooltip-arrow': {
              color: alpha(theme.palette[colorVariant].main, 0.8),
            },
          },
        },
      }}
    >
      <span>{children}</span>
    </MUITooltip>
  );
}
