import { baseApiWithGraphQL } from './baseApi';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: string; output: string; }
  AWSDateTime: { input: string; output: string; }
  AWSJSON: { input: any; output: any; }
};

export type AdminConnectionProperties = {
  status: ConnectionStatus;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminDeleteInviteInput = {
  invitationId: Scalars['String']['input'];
};

export type AdminDeleteUserOptions = {
  isPermanent: Scalars['Boolean']['input'];
  reason: DeleteAccountReason;
};

export type AdminGetSignedS3UrlInput = {
  expiry: Scalars['Int']['input'];
  headers?: InputMaybe<Scalars['AWSJSON']['input']>;
  key: Scalars['String']['input'];
  method: HttpMethod;
  partParams?: InputMaybe<AdminPartParams>;
  type: ObjectType;
};

export type AdminGetTwinDigestInput = {
  getConversationalStyle?: InputMaybe<Scalars['Boolean']['input']>;
  getKeyExperiences?: InputMaybe<Scalars['Boolean']['input']>;
  getPersonality?: InputMaybe<Scalars['Boolean']['input']>;
  getTwinDescription?: InputMaybe<Scalars['Boolean']['input']>;
  targetUserId: Scalars['String']['input'];
  updatePromptConfigId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetUserInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AdminGetUserInvitesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<UserInvitesSort>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<InputMaybe<InviteStatus>>>;
};

export type AdminGetUserInvitesResponse = {
  __typename?: 'AdminGetUserInvitesResponse';
  invitees: Array<UserInvitation>;
  totalCount: Scalars['Int']['output'];
};

export type AdminGetUserResponse = {
  __typename?: 'AdminGetUserResponse';
  totalCount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<SuperfeelUser>;
};

export type AdminInviteTwinToRoomInput = {
  modelConfigId: Scalars['String']['input'];
  roomUrl: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminInviteUsersInput = {
  users: Array<AdminUserInviteInput>;
};

export type AdminPartParams = {
  partNumber?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminPromptInput = {
  body: Scalars['String']['input'];
  category: PromptCategory;
  intensity: Scalars['Int']['input'];
  promptId?: InputMaybe<Scalars['ID']['input']>;
  publishDate?: InputMaybe<Scalars['AWSDate']['input']>;
  type: PostType;
};

export type AdminSearchPostsInput = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPromptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<Array<PostType>>;
};

export type AdminSearchPostsResponse = {
  __typename?: 'AdminSearchPostsResponse';
  posts: Array<SuperfeelPost>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AdminSearchPromptsInput = {
  categories?: InputMaybe<Array<PromptCategory>>;
  fromDate?: InputMaybe<Scalars['AWSDate']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  promptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['AWSDate']['input']>;
  type?: InputMaybe<PostType>;
};

export type AdminSearchPromptsResponse = {
  __typename?: 'AdminSearchPromptsResponse';
  prompts?: Maybe<Array<SuperfeelPrompt>>;
  totalCount: Scalars['Int']['output'];
};

export type AdminSearchProperties = {
  exactMatch: Scalars['Boolean']['input'];
  properties: Array<Scalars['String']['input']>;
  terms: Array<Scalars['String']['input']>;
};

export type AdminSearchSamplesInput = {
  interviewId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  speakerLabel?: InputMaybe<SpeakerLabel>;
  speakerRole?: InputMaybe<InterviewRole>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminSearchTwinDocumentsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SearchDocumentsSort>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserId: Scalars['ID']['input'];
};

export type AdminSearchTwinMediaInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  mediaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetModelConfigId?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminSearchTwinModelConfigsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  modelConfigId?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminSearchTwinPromptInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  promptConfigIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserId: Scalars['ID']['input'];
};

export type AdminSearchTwinVoicesInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserId: Scalars['ID']['input'];
};

export type AdminSearchUsersInput = {
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  relationship?: InputMaybe<AdminConnectionProperties>;
  search?: InputMaybe<AdminSearchProperties>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type AdminSearchUsersResponse = {
  __typename?: 'AdminSearchUsersResponse';
  totalCount: Scalars['Int']['output'];
  users?: Maybe<Array<Maybe<SuperfeelUser>>>;
};

export type AdminStartInterviewProcessInput = {
  interviewId: Scalars['String']['input'];
};

export type AdminUpdatePromptInput = {
  action: UpdateType;
  prompts: Array<AdminPromptInput>;
};

export type AdminUpdateTwinDocumentInput = {
  audience: AudienceType;
  documentName: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  targetUserId: Scalars['ID']['input'];
};

export type AdminUpdateTwinJobInput = {
  documentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  index: Scalars['String']['input'];
  interviewIds?: InputMaybe<Array<Scalars['String']['input']>>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminUpdateTwinModelConfigInput = {
  action: UpdateType;
  config: TwinModelConfigInput;
};

export type AdminUpdateTwinPromptInput = {
  action: UpdateType;
  prompt?: InputMaybe<TwinPromptInput>;
};

export type AdminUpdateTwinVoiceInput = {
  action: UpdateType;
  language?: InputMaybe<Scalars['String']['input']>;
  speed?: InputMaybe<Scalars['Float']['input']>;
  targetUserId: Scalars['String']['input'];
  ttsConfigId?: InputMaybe<Scalars['String']['input']>;
  voiceId?: InputMaybe<Scalars['String']['input']>;
  voiceSampleUri?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpdateTwinVoiceSampleInput = {
  speed: Scalars['Float']['input'];
  targetUserId: Scalars['String']['input'];
  text: Scalars['String']['input'];
  ttsConfigId: Scalars['String']['input'];
};

export type AdminUpdateUserInput = {
  action: UpdateType;
  deleteOptions?: InputMaybe<AdminDeleteUserOptions>;
  user: AdminUserInfoInput;
};

export type AdminUserInfoInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isFlagged?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  membershipType?: InputMaybe<MembershipType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  profilePictureUri?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUserInviteInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type AnalyticsEventType =
  | 'GESTURE'
  | 'INPUT'
  | 'NAVIGATION'
  | 'NOTIFICATION'
  | 'PRESS'
  | 'TIMER'
  | 'VIDEO';

export type AnalyticsEvents = {
  GESTURE: Array<GestureEventInput>;
  INPUT: Array<InputEventInput>;
  NAVIGATION: Array<NavigationEventInput>;
  PRESS: Array<PressEventInput>;
  TIMER: Array<TimerEventInput>;
  VIDEO: Array<VideoEventInput>;
};

export type AppPublisher =
  | 'APPLE'
  | 'GOOGLE';

export type AppReleaseHistory = {
  __typename?: 'AppReleaseHistory';
  id?: Maybe<Scalars['Int']['output']>;
  publicationDate: Scalars['AWSDateTime']['output'];
  stage: AppStage;
  store: AppPublisher;
  url?: Maybe<Scalars['String']['output']>;
  version: Scalars['String']['output'];
};

export type AppStage =
  | 'DEV'
  | 'PROD';

export type AudienceType =
  | 'CIRCLE'
  | 'FRIENDS'
  | 'PRIVATE'
  | 'PUBLIC';

export type BackgroundMusicInput = {
  songId: Scalars['String']['input'];
  startTimestamp: Scalars['Float']['input'];
  volume: Scalars['Float']['input'];
};

export type CompleteFileUploadInput = {
  key: Scalars['String']['input'];
  parts: Array<FilePartInput>;
  type: ObjectType;
  uploadId: Scalars['String']['input'];
};

export type ConnectionProperties = {
  status: ConnectionStatus;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type ConnectionStatus =
  | 'CIRCLE'
  | 'CONNECTED'
  | 'FOLLOWED_BY'
  | 'FOLLOWS'
  | 'HIDDEN'
  | 'NONE'
  | 'SAVED';

export type ConversationInput = {
  addMembers?: InputMaybe<Array<Scalars['String']['input']>>;
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  conversationName: Scalars['String']['input'];
  conversationType?: InputMaybe<ConversationType>;
  removeMembers?: InputMaybe<Array<Scalars['String']['input']>>;
  thumbnailUri?: InputMaybe<Scalars['String']['input']>;
};

export type ConversationSearchProperties = {
  target: ConversationSearchTarget;
  terms: Array<Scalars['String']['input']>;
};

export type ConversationSearchTarget =
  | 'CONVERSATION'
  | 'MESSAGE';

export type ConversationSettingsInput = {
  dateArchived?: InputMaybe<Scalars['AWSDateTime']['input']>;
  dateUserDeleted?: InputMaybe<Scalars['AWSDateTime']['input']>;
  dateUserHidden?: InputMaybe<Scalars['AWSDateTime']['input']>;
  dateVerified?: InputMaybe<Scalars['AWSDateTime']['input']>;
  lastRead?: InputMaybe<Scalars['AWSDateTime']['input']>;
  mutedUntil?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type ConversationSortMethod =
  | 'DATE_CREATED'
  | 'LAST_UPDATED'
  | 'LATEST_MESSAGE';

export type ConversationStatus =
  | 'ACTIVE'
  | 'ARCHIVED'
  | 'UNVERIFIED';

export type ConversationType =
  | 'DIRECT'
  | 'GROUP';

export type CreateGraphIngestionJobsInput = {
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  submitAllFailed?: InputMaybe<Scalars['Boolean']['input']>;
  submitAllNew?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateRoomResult = {
  __typename?: 'CreateRoomResult';
  url: Scalars['String']['output'];
};

export type CreateTwinVoiceInput = {
  language: Scalars['String']['input'];
  voiceSampleUri: Scalars['String']['input'];
};

export type DeleteAccountReason =
  | 'DATA_CONCERNS'
  | 'DIFFICULT_USING'
  | 'NEED_A_BREAK'
  | 'NO_FRIENDS'
  | 'OTHER'
  | 'PRIVACY_CONCERNS'
  | 'SECOND_ACCOUNT'
  | 'TOO_BUSY';

export type DeleteItemInput = {
  targetId?: InputMaybe<Scalars['String']['input']>;
  type: ItemType;
};

export type DeleteUserOptions = {
  isPermanent: Scalars['Boolean']['input'];
  reason: DeleteAccountReason;
};

export type FeedType =
  | 'CIRCLE'
  | 'DEMO'
  | 'FRIENDS'
  | 'HIDDEN'
  | 'PERSONAL'
  | 'PUBLIC'
  | 'SAVED';

export type FeedbackType =
  | 'BUG'
  | 'FEATURE_REQUEST'
  | 'GENERAL';

export type FeelRequest = {
  __typename?: 'FeelRequest';
  dateCreated: Scalars['AWSDateTime']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  prompt?: Maybe<SuperfeelPrompt>;
  recipient?: Maybe<SuperfeelUser>;
  recipientUserId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  responsePostId?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<SuperfeelUser>;
  senderUserId: Scalars['String']['output'];
  status: RequestStatus;
  targetPromptId: Scalars['String']['output'];
  type: RequestType;
};

export type FilePartInput = {
  ETag: Scalars['String']['input'];
  PartNumber: Scalars['Int']['input'];
};

export type FileUpload = {
  __typename?: 'FileUpload';
  bucket: Scalars['String']['output'];
  eTag: Scalars['String']['output'];
  key: Scalars['String']['output'];
  location: Scalars['String']['output'];
};

export type Gender =
  | 'FEMALE'
  | 'MALE'
  | 'OTHER'
  | 'UNKNOWN';

export type GenericResponse = {
  __typename?: 'GenericResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GestureActionType =
  | 'SWIPE_DOWN'
  | 'SWIPE_LEFT'
  | 'SWIPE_RIGHT'
  | 'SWIPE_UP';

export type GestureEventInput = {
  action: GestureActionType;
  id: Scalars['String']['input'];
  screen: Scalars['String']['input'];
  screenParams?: InputMaybe<Scalars['AWSJSON']['input']>;
  timestamp: Scalars['AWSDateTime']['input'];
};

export type GetCurrentVersionInput = {
  stage?: InputMaybe<AppStage>;
  store?: InputMaybe<AppPublisher>;
};

export type GetSignedUrlInput = {
  expiry: Scalars['Int']['input'];
  headers?: InputMaybe<Scalars['AWSJSON']['input']>;
  key: Scalars['String']['input'];
  method: HttpMethod;
  partParams?: InputMaybe<PartParamsInput>;
  type: ObjectType;
};

export type GetTwinDigestInput = {
  getConversationalStyle?: InputMaybe<Scalars['Boolean']['input']>;
  getKeyExperiences?: InputMaybe<Scalars['Boolean']['input']>;
  getPersonality?: InputMaybe<Scalars['Boolean']['input']>;
  getTwinDescription?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetUserInput = {
  userId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type GroupSortMethod =
  | 'DATE_CREATED'
  | 'NAME';

export type HttpMethod =
  | 'GET'
  | 'POST'
  | 'PUT';

export type InputActionType =
  | 'BLUR'
  | 'FOCUS';

export type InputEventInput = {
  action: InputActionType;
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  screen: Scalars['String']['input'];
  screenParams?: InputMaybe<Scalars['AWSJSON']['input']>;
  timestamp: Scalars['AWSDateTime']['input'];
  type: InputEventType;
  value: Scalars['String']['input'];
};

export type InputEventType =
  | 'CHECKBOX'
  | 'DATE'
  | 'RATING'
  | 'SEARCH'
  | 'SELECT'
  | 'SLIDER'
  | 'SWITCH'
  | 'TEXT';

export type Interview = {
  __typename?: 'Interview';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  durationSecs: Scalars['Int']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  interviewId: Scalars['ID']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  parentCollectionId: Scalars['ID']['output'];
  processingStage?: Maybe<JobType>;
  s3Key: Scalars['String']['output'];
  segments?: Maybe<Array<InterviewSegment>>;
  targetUserId: Scalars['ID']['output'];
  twinGraphIngestionStatus?: Maybe<JobStatus>;
  uploaderUserId: Scalars['ID']['output'];
};

export type InterviewCollection = {
  __typename?: 'InterviewCollection';
  collectionId: Scalars['ID']['output'];
  creatorUserId: Scalars['ID']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  interviewerName?: Maybe<Scalars['String']['output']>;
  interviews?: Maybe<Array<Interview>>;
  lastUpdated: Scalars['AWSDateTime']['output'];
  name: Scalars['String']['output'];
  targetUserId: Scalars['ID']['output'];
};

export type InterviewCollectionInput = {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  interviewerName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type InterviewInput = {
  durationSecs?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileSize?: InputMaybe<Scalars['Int']['input']>;
  interviewId?: InputMaybe<Scalars['ID']['input']>;
  parentCollectionId?: InputMaybe<Scalars['ID']['input']>;
  s3Key?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
  uploaderUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type InterviewRole =
  | 'INTERVIEWER'
  | 'UNKNOWN'
  | 'USER';

export type InterviewSegment = {
  __typename?: 'InterviewSegment';
  dateCreated: Scalars['AWSDateTime']['output'];
  endTimeSecs: Scalars['Float']['output'];
  fileSize: Scalars['Int']['output'];
  interviewSegmentId: Scalars['ID']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  parentCollectionId: Scalars['ID']['output'];
  parentInterviewId: Scalars['ID']['output'];
  s3Key: Scalars['String']['output'];
  speakerLabel?: Maybe<SpeakerLabel>;
  speakerRole?: Maybe<InterviewRole>;
  startTimeSecs: Scalars['Float']['output'];
  targetUserId: Scalars['ID']['output'];
  transcription: Scalars['String']['output'];
};

export type InterviewSegmentsInput = {
  interviewId: Scalars['String']['input'];
  speakerLabel: SpeakerLabel;
  speakerRole: InterviewRole;
};

export type InvitationSortMethod =
  | 'DATE_CREATED'
  | 'FIRST_NAME';

export type InviteStatus =
  | 'ACCEPTED'
  | 'CANCELLED'
  | 'PENDING';

export type InviteTwinToRoomInput = {
  modelConfigId: Scalars['String']['input'];
  roomUrl: Scalars['String']['input'];
  topicId?: InputMaybe<Scalars['String']['input']>;
};

export type ItemType =
  | 'COMMENT'
  | 'GROUP'
  | 'POST';

export type JobFileType =
  | 'DOCUMENT'
  | 'INTERVIEW'
  | 'POST'
  | 'UNKNOWN';

export type JobSearchSortMethod =
  | 'DATE_CREATED'
  | 'LAST_UPDATED';

export type JobStatus =
  | 'FAILED'
  | 'NOT_STARTED'
  | 'PENDING'
  | 'QUEUED'
  | 'RUNNABLE'
  | 'RUNNING'
  | 'STARTING'
  | 'SUBMITTED'
  | 'SUCCEEDED';

export type JobType =
  | 'CLEANING'
  | 'DIARISATION'
  | 'GRAPH'
  | 'INTERVIEW'
  | 'PROCESSING'
  | 'SEGMENTATION'
  | 'TRANSCRIBE'
  | 'TTS';

export type LlmProvider =
  | 'ANTHROPIC'
  | 'OPENAI';

export type MembershipStatus =
  | 'ACTIVE'
  | 'UNPAID_INVOICE';

export type MembershipType =
  | 'ADMIN'
  | 'PLUS'
  | 'PRO'
  | 'STANDARD';

export type MessageActivity = FeelRequest | PostComment;

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  attachmentId: Scalars['ID']['output'];
  contentUri: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  parentConversationId: Scalars['ID']['output'];
  parentMessageId: Scalars['ID']['output'];
  sender?: Maybe<SuperfeelUser>;
  senderUserId: Scalars['ID']['output'];
};

export type MessageAttachmentInput = {
  contentUri: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
};

export type MessageInput = {
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  body?: InputMaybe<Scalars['String']['input']>;
  conversationId: Scalars['ID']['input'];
  messageId?: InputMaybe<Scalars['ID']['input']>;
  optimisticUpdateId?: InputMaybe<Scalars['String']['input']>;
};

export type MessageReaction = {
  __typename?: 'MessageReaction';
  authorUserId: Scalars['ID']['output'];
  conversationId: Scalars['ID']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  emoji: Scalars['String']['output'];
  reactionId: Scalars['ID']['output'];
  targetMessageId: Scalars['ID']['output'];
};

export type MessageSortMethod =
  | 'DATE_CREATED';

export type MusicSortMethod =
  | 'CATEGORY_NAME'
  | 'DATE_CREATED'
  | 'SONG_NAME';

export type Mutation = {
  __typename?: 'Mutation';
  adminCreateRoom?: Maybe<CreateRoomResult>;
  adminDeleteInvite?: Maybe<GenericResponse>;
  adminInviteTwinToChatRoom?: Maybe<GenericResponse>;
  adminInviteUsers?: Maybe<GenericResponse>;
  adminStartInterviewProcess?: Maybe<TwinJob>;
  adminUpdatePrompts?: Maybe<Array<SuperfeelPrompt>>;
  adminUpdateTwinDocument?: Maybe<TwinDocument>;
  adminUpdateTwinJob?: Maybe<Array<TwinJob>>;
  adminUpdateTwinModelConfig?: Maybe<TwinModelConfig>;
  adminUpdateTwinPrompt?: Maybe<TwinPromptConfig>;
  adminUpdateTwinVoice?: Maybe<TwinModelTtsConfig>;
  adminUpdateTwinVoiceSample?: Maybe<TwinModelTtsConfig>;
  adminUpdateUser?: Maybe<SuperfeelUser>;
  completeFileUpload: FileUpload;
  createGraphIngestionJobs?: Maybe<Array<TwinJob>>;
  createRoom?: Maybe<CreateRoomResult>;
  createTwinVoice?: Maybe<TwinModelTtsConfig>;
  deleteItem?: Maybe<GenericResponse>;
  inviteTwinToChatRoom?: Maybe<GenericResponse>;
  postAnalytics?: Maybe<GenericResponse>;
  postComment?: Maybe<PostComment>;
  publishConversationUpdate?: Maybe<SuperfeelConversation>;
  publishMessageUpdate?: Maybe<SuperfeelMessage>;
  publishNotificationUpdate?: Maybe<SuperfeelNotification>;
  publishRequestUpdate?: Maybe<FeelRequest>;
  publishUserTypingUpdate?: Maybe<UserTypingUpdate>;
  sendInvitations?: Maybe<Array<UserInvitation>>;
  submitFeedback?: Maybe<GenericResponse>;
  submitReport?: Maybe<SuperfeelReport>;
  updateConnection?: Maybe<SuperfeelUser>;
  updateContent?: Maybe<SuperfeelPost>;
  updateConversation?: Maybe<SuperfeelConversation>;
  updateConversationTypingStatus?: Maybe<GenericResponse>;
  updateFlags?: Maybe<GenericResponse>;
  updateGroup?: Maybe<SuperfeelUserGroup>;
  updateInterview?: Maybe<Interview>;
  updateInterviewCollection?: Maybe<InterviewCollection>;
  updateInterviewSegments?: Maybe<Array<Maybe<InterviewSegment>>>;
  updateMessage?: Maybe<SuperfeelMessage>;
  updateRating?: Maybe<PostRating>;
  updateRequest?: Maybe<Array<FeelRequest>>;
  updateSettings?: Maybe<SuperfeelUser>;
  updateTwinConfig?: Maybe<TwinModelConfig>;
  updateTwinMedia?: Maybe<TwinMedia>;
  updateTwinVoiceSample?: Maybe<TwinModelTtsConfig>;
  updateUser?: Maybe<SuperfeelUser>;
  updateUserConversationSettings?: Maybe<Array<SuperfeelConversationSettings>>;
};


export type MutationAdminDeleteInviteArgs = {
  input?: InputMaybe<AdminDeleteInviteInput>;
};


export type MutationAdminInviteTwinToChatRoomArgs = {
  input: AdminInviteTwinToRoomInput;
};


export type MutationAdminInviteUsersArgs = {
  input: AdminInviteUsersInput;
};


export type MutationAdminStartInterviewProcessArgs = {
  input: AdminStartInterviewProcessInput;
};


export type MutationAdminUpdatePromptsArgs = {
  input: AdminUpdatePromptInput;
};


export type MutationAdminUpdateTwinDocumentArgs = {
  input: AdminUpdateTwinDocumentInput;
};


export type MutationAdminUpdateTwinJobArgs = {
  input: AdminUpdateTwinJobInput;
};


export type MutationAdminUpdateTwinModelConfigArgs = {
  input: AdminUpdateTwinModelConfigInput;
};


export type MutationAdminUpdateTwinPromptArgs = {
  input: AdminUpdateTwinPromptInput;
};


export type MutationAdminUpdateTwinVoiceArgs = {
  input: AdminUpdateTwinVoiceInput;
};


export type MutationAdminUpdateTwinVoiceSampleArgs = {
  input: AdminUpdateTwinVoiceSampleInput;
};


export type MutationAdminUpdateUserArgs = {
  input: AdminUpdateUserInput;
};


export type MutationCompleteFileUploadArgs = {
  input: CompleteFileUploadInput;
};


export type MutationCreateGraphIngestionJobsArgs = {
  input: CreateGraphIngestionJobsInput;
};


export type MutationCreateTwinVoiceArgs = {
  input: CreateTwinVoiceInput;
};


export type MutationDeleteItemArgs = {
  input: DeleteItemInput;
};


export type MutationInviteTwinToChatRoomArgs = {
  input: InviteTwinToRoomInput;
};


export type MutationPostAnalyticsArgs = {
  input: PostAnalyticsInput;
};


export type MutationPostCommentArgs = {
  input: PostCommentInput;
};


export type MutationPublishConversationUpdateArgs = {
  data: Scalars['AWSJSON']['input'];
  targetUserId: Scalars['String']['input'];
};


export type MutationPublishMessageUpdateArgs = {
  data: Scalars['AWSJSON']['input'];
  targetUserId: Scalars['String']['input'];
};


export type MutationPublishNotificationUpdateArgs = {
  actorUserId: Scalars['String']['input'];
  data: Scalars['AWSJSON']['input'];
  recipientUserId: Scalars['String']['input'];
};


export type MutationPublishRequestUpdateArgs = {
  data: Scalars['AWSJSON']['input'];
  recipientUserId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
};


export type MutationPublishUserTypingUpdateArgs = {
  data: Scalars['AWSJSON']['input'];
  targetUserId: Scalars['String']['input'];
};


export type MutationSendInvitationsArgs = {
  input: SendInvitationInput;
};


export type MutationSubmitFeedbackArgs = {
  input: SubmitFeedbackInput;
};


export type MutationSubmitReportArgs = {
  input: SubmitReportInput;
};


export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};


export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};


export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};


export type MutationUpdateConversationTypingStatusArgs = {
  input: UpdateConversationTypingStatusInput;
};


export type MutationUpdateFlagsArgs = {
  input: UpdateFlagsInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateInterviewArgs = {
  input: UpdateInterviewInput;
};


export type MutationUpdateInterviewCollectionArgs = {
  input: UpdateInterviewCollectionInput;
};


export type MutationUpdateInterviewSegmentsArgs = {
  input: UpdateInterviewSegmentsInput;
};


export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};


export type MutationUpdateRatingArgs = {
  input: UpdateRatingInput;
};


export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};


export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};


export type MutationUpdateTwinConfigArgs = {
  input: UpdateTwinConfigInput;
};


export type MutationUpdateTwinMediaArgs = {
  input: UpdateTwinMediaInput;
};


export type MutationUpdateTwinVoiceSampleArgs = {
  input: UpdateTwinVoiceSampleInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserConversationSettingsArgs = {
  input: UpdateUserConversationSettingsInput;
};

export type NavigationEventInput = {
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  params?: InputMaybe<Scalars['AWSJSON']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['AWSDateTime']['input'];
};

export type NotificationActionStatus =
  | 'COMPLETE'
  | 'NONE'
  | 'NOT_STARTED'
  | 'PENDING';

export type NotificationActionType =
  | 'ADD_USER'
  | 'NONE';

export type NotificationDirection =
  | 'ALL'
  | 'RECEIVER'
  | 'SENDER';

export type NotificationEventInput = {
  body: Scalars['String']['input'];
  id: Scalars['String']['input'];
  params?: InputMaybe<Scalars['AWSJSON']['input']>;
  title: Scalars['String']['input'];
  type: NotificationType;
};

export type NotificationSetting =
  | 'ALL'
  | 'CIRCLE'
  | 'FOLLOWING'
  | 'OFF';

export type NotificationSubjectType =
  | 'CONVERSATION'
  | 'FEEL_REQUEST'
  | 'NONE'
  | 'POST'
  | 'POST_COMMENT'
  | 'PROMPT'
  | 'TWIN'
  | 'USER';

export type NotificationType =
  | 'FEEL_REQUEST_RESPONSE'
  | 'FOTD_REMINDER'
  | 'INVITATION_ACCEPTED'
  | 'JOINED_SUPERFEEL'
  | 'MORNING_SUMMARY'
  | 'NEW_COMMENT'
  | 'NEW_CONNECTION'
  | 'NEW_CONVERSATION'
  | 'NEW_FEEL_REQUEST'
  | 'NEW_FOLLOWER'
  | 'NEW_MESSAGE'
  | 'NEW_MESSAGE_REQUEST'
  | 'NEW_POST'
  | 'NEW_RATING'
  | 'NEW_TWIN_LEVEL'
  | 'NUDGE'
  | 'TWIN_READY';

export type NotificationsInput = {
  fotdReminder?: InputMaybe<Scalars['Boolean']['input']>;
  newComment?: InputMaybe<NotificationSetting>;
  newConnection?: InputMaybe<Scalars['Boolean']['input']>;
  newFeelRequest?: InputMaybe<Scalars['Boolean']['input']>;
  newFollower?: InputMaybe<Scalars['Boolean']['input']>;
  newMessage?: InputMaybe<Scalars['Boolean']['input']>;
  newMessageRequest?: InputMaybe<Scalars['Boolean']['input']>;
  newPost?: InputMaybe<NotificationSetting>;
  newRating?: InputMaybe<NotificationSetting>;
  nudge?: InputMaybe<Scalars['Boolean']['input']>;
  nudgeRequest?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ObjectType =
  | 'ATTACHMENT'
  | 'CONTENT'
  | 'CONTENT_AUDIO'
  | 'FEEDBACK'
  | 'INTERVIEW'
  | 'MUSIC'
  | 'PROFILE'
  | 'THUMBNAIL'
  | 'TRANSCRIPTION'
  | 'TWIN_DOCUMENT'
  | 'VOICE_SAMPLE';

export type PartParamsInput = {
  partNumber?: InputMaybe<Scalars['Int']['input']>;
  uploadId?: InputMaybe<Scalars['String']['input']>;
};

export type PostAnalyticsInput = {
  events: AnalyticsEvents;
  startTime: Scalars['AWSDateTime']['input'];
};

export type PostComment = {
  __typename?: 'PostComment';
  author?: Maybe<SuperfeelUser>;
  authorUserId: Scalars['String']['output'];
  body: Scalars['String']['output'];
  commentId: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  targetPostId: Scalars['String']['output'];
};

export type PostCommentInput = {
  body: Scalars['String']['input'];
  targetPostId: Scalars['String']['input'];
};

export type PostFiltersInput = {
  excludeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  excludeSelf?: InputMaybe<Scalars['Boolean']['input']>;
  excludeUnsaved?: InputMaybe<Scalars['Boolean']['input']>;
  includeDemo?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PostFlags = {
  __typename?: 'PostFlags';
  hidden?: Maybe<Scalars['Boolean']['output']>;
  saved?: Maybe<Scalars['Boolean']['output']>;
};

export type PostFlagsInput = {
  hidden: Scalars['Boolean']['input'];
  saved: Scalars['Boolean']['input'];
};

export type PostInfoInput = {
  audience: AudienceType;
  caption: Scalars['String']['input'];
  commentsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  customThumbnailUri?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  music?: InputMaybe<BackgroundMusicInput>;
  postId?: InputMaybe<Scalars['String']['input']>;
  ratingsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  submitToTwinGraph?: InputMaybe<Scalars['Boolean']['input']>;
  tag: PostTopic;
  targetPromptId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<PostType>;
  videoStartTimestamp?: InputMaybe<Scalars['Float']['input']>;
  videoStopTimestamp?: InputMaybe<Scalars['Float']['input']>;
  volume?: InputMaybe<Scalars['Float']['input']>;
};

export type PostMetadata = {
  __typename?: 'PostMetadata';
  bitRate?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  framesPerSecond?: Maybe<Scalars['Float']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  videoHeight?: Maybe<Scalars['Int']['output']>;
  videoWidth?: Maybe<Scalars['Int']['output']>;
};

export type PostRating = {
  __typename?: 'PostRating';
  author?: Maybe<SuperfeelUser>;
  authorUserId: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  ratingId: Scalars['String']['output'];
  targetPostId: Scalars['String']['output'];
  type: RatingType;
};

export type PostSortMethod =
  | 'COMBINED_SCORE'
  | 'DATE'
  | 'FUNNY'
  | 'HELPFUL'
  | 'INSPIRING'
  | 'RELATABLE'
  | 'VIEWS';

export type PostStatistics = {
  __typename?: 'PostStatistics';
  comments: Scalars['Int']['output'];
  funny: Scalars['Int']['output'];
  helpful: Scalars['Int']['output'];
  inspiring: Scalars['Int']['output'];
  relatable: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  views3secs: Scalars['Int']['output'];
  views50pct: Scalars['Int']['output'];
  viewsComplete: Scalars['Int']['output'];
};

export type PostTopic =
  | 'ART'
  | 'BUSINESS'
  | 'EDUCATION'
  | 'FASHION'
  | 'FOOD'
  | 'GAMES'
  | 'HOME'
  | 'MOVIES'
  | 'MUSIC'
  | 'NONE'
  | 'RELATIONSHIPS'
  | 'SCIENCE';

export type PostType =
  | 'CUSTOM'
  | 'FOR_YOU'
  | 'FOTD'
  | 'REALS';

export type PreferencesInput = {
  allowAnalytics?: InputMaybe<Scalars['Boolean']['input']>;
  feelsPromptDisplayLength?: InputMaybe<Scalars['Int']['input']>;
  initialLandingPage?: InputMaybe<UserLandingPagePreference>;
  language?: InputMaybe<Scalars['String']['input']>;
  onboardingBookmark?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingFeed?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingInviteUsers?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingProfile?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingRating?: InputMaybe<Scalars['AWSDateTime']['input']>;
  onboardingRecordVideo?: InputMaybe<Scalars['AWSDateTime']['input']>;
  /** @deprecated Reputation no longer manually set. Will be removed */
  onboardingReputation?: InputMaybe<Scalars['AWSDateTime']['input']>;
  receiveRequests?: InputMaybe<NotificationSetting>;
  showOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type PressEventInput = {
  icon: Scalars['String']['input'];
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  screen: Scalars['String']['input'];
  screenParams?: InputMaybe<Scalars['AWSJSON']['input']>;
  timestamp: Scalars['AWSDateTime']['input'];
  type: PressEventType;
};

export type PressEventType =
  | 'ALERT'
  | 'AVATAR'
  | 'BADGE'
  | 'DEFAULT'
  | 'ICON'
  | 'LIST'
  | 'PANEL'
  | 'SETTING'
  | 'TEXT';

export type PromptCategory =
  | 'EMOTION'
  | 'FAMILY'
  | 'FUN'
  | 'GENDER'
  | 'INTERESTS'
  | 'NONE'
  | 'PAST_RELATIONSHIPS'
  | 'PERSONAL_GOALS'
  | 'PHYSICAL_HEALTH'
  | 'SELF_ESTEEM'
  | 'SEXUALITY'
  | 'SOCIAL'
  | 'STRESS'
  | 'WORK';

export type PromptTag =
  | 'FEELING'
  | 'INSIGHT'
  | 'NONE'
  | 'STORY';

export type Query = {
  __typename?: 'Query';
  adminGetSignedS3Url: UploadUrl;
  adminGetTwinGraphDigest?: Maybe<TwinGraphDigest>;
  adminGetUser?: Maybe<AdminGetUserResponse>;
  adminGetUserInvites?: Maybe<AdminGetUserInvitesResponse>;
  adminSearchPosts?: Maybe<AdminSearchPostsResponse>;
  adminSearchPrompts?: Maybe<AdminSearchPromptsResponse>;
  adminSearchSamples?: Maybe<Array<InterviewSegment>>;
  adminSearchTwinDocuments?: Maybe<Array<TwinDocument>>;
  adminSearchTwinMedia?: Maybe<Array<TwinMedia>>;
  adminSearchTwinModelConfigs?: Maybe<Array<TwinModelConfig>>;
  adminSearchTwinPrompt?: Maybe<Array<TwinPromptConfig>>;
  adminSearchTwinVoices?: Maybe<Array<TwinModelTtsConfig>>;
  adminSearchUsers?: Maybe<AdminSearchUsersResponse>;
  getCurrentVersions?: Maybe<Array<AppReleaseHistory>>;
  getSelf?: Maybe<SuperfeelUser>;
  getSignedUrl: UploadUrl;
  getTwinGraphDigest?: Maybe<TwinGraphDigest>;
  getUser?: Maybe<SuperfeelUser>;
  searchComments?: Maybe<Array<PostComment>>;
  searchConversations?: Maybe<Array<SuperfeelConversation>>;
  searchGroups?: Maybe<Array<SuperfeelUserGroup>>;
  searchInterviewCollections?: Maybe<Array<InterviewCollection>>;
  searchInterviews?: Maybe<Array<Interview>>;
  searchInvitations: Array<UserInvitation>;
  searchMessages?: Maybe<Array<SuperfeelMessage>>;
  searchMusic?: Maybe<Array<SuperfeelMusic>>;
  searchNotifications: Array<SuperfeelNotification>;
  searchPostsV2?: Maybe<Array<SuperfeelPost>>;
  searchPrompts?: Maybe<Array<SuperfeelPrompt>>;
  searchRatings?: Maybe<Array<PostRating>>;
  searchRequests: Array<FeelRequest>;
  searchTwinJobs?: Maybe<Array<TwinJob>>;
  searchTwinMedia?: Maybe<Array<TwinMedia>>;
  searchUserTwinJobs?: Maybe<Array<TwinJob>>;
  searchUsers?: Maybe<Array<SuperfeelUser>>;
};


export type QueryAdminGetSignedS3UrlArgs = {
  input: AdminGetSignedS3UrlInput;
};


export type QueryAdminGetTwinGraphDigestArgs = {
  input: AdminGetTwinDigestInput;
};


export type QueryAdminGetUserArgs = {
  input: AdminGetUserInput;
};


export type QueryAdminGetUserInvitesArgs = {
  input: AdminGetUserInvitesInput;
};


export type QueryAdminSearchPostsArgs = {
  input: AdminSearchPostsInput;
};


export type QueryAdminSearchPromptsArgs = {
  input: AdminSearchPromptsInput;
};


export type QueryAdminSearchSamplesArgs = {
  input: AdminSearchSamplesInput;
};


export type QueryAdminSearchTwinDocumentsArgs = {
  input: AdminSearchTwinDocumentsInput;
};


export type QueryAdminSearchTwinMediaArgs = {
  input: AdminSearchTwinMediaInput;
};


export type QueryAdminSearchTwinModelConfigsArgs = {
  input: AdminSearchTwinModelConfigsInput;
};


export type QueryAdminSearchTwinPromptArgs = {
  input: AdminSearchTwinPromptInput;
};


export type QueryAdminSearchTwinVoicesArgs = {
  input: AdminSearchTwinVoicesInput;
};


export type QueryAdminSearchUsersArgs = {
  input: AdminSearchUsersInput;
};


export type QueryGetCurrentVersionsArgs = {
  input: GetCurrentVersionInput;
};


export type QueryGetSignedUrlArgs = {
  input: GetSignedUrlInput;
};


export type QueryGetTwinGraphDigestArgs = {
  input: GetTwinDigestInput;
};


export type QueryGetUserArgs = {
  input: GetUserInput;
};


export type QuerySearchCommentsArgs = {
  input: SearchCommentsInput;
};


export type QuerySearchConversationsArgs = {
  input: SearchConversationsInput;
};


export type QuerySearchGroupsArgs = {
  input: SearchGroupsInput;
};


export type QuerySearchInterviewCollectionsArgs = {
  input: SearchInterviewCollectionsInput;
};


export type QuerySearchInterviewsArgs = {
  input: SearchInterviewsInput;
};


export type QuerySearchInvitationsArgs = {
  input: SearchInvitationInput;
};


export type QuerySearchMessagesArgs = {
  input: SearchMessagesInput;
};


export type QuerySearchMusicArgs = {
  input: SearchMusicInput;
};


export type QuerySearchNotificationsArgs = {
  input: SearchNotificationInput;
};


export type QuerySearchPostsV2Args = {
  input: SearchPostsInputV2;
};


export type QuerySearchPromptsArgs = {
  input: SearchPromptsInput;
};


export type QuerySearchRatingsArgs = {
  input: SearchRatingsInput;
};


export type QuerySearchRequestsArgs = {
  input: SearchRequestInput;
};


export type QuerySearchTwinJobsArgs = {
  input: SearchTwinJobsInput;
};


export type QuerySearchTwinMediaArgs = {
  input: SearchTwinMediaInput;
};


export type QuerySearchUserTwinJobsArgs = {
  input: SearchUserTwinJobsInput;
};


export type QuerySearchUsersArgs = {
  input: SearchUsersInput;
};

export type RatingType =
  | 'FUNNY'
  | 'HELPFUL'
  | 'INSPIRING'
  | 'NONE'
  | 'RELATABLE';

export type ReportReason =
  | 'BULLYING'
  | 'FALSE_INFORMATION'
  | 'HATE_SPEECH'
  | 'ILLEGAL_ACTIVITY'
  | 'IMPERSONATION'
  | 'NO_REASON'
  | 'SCAM'
  | 'SELF_HARM'
  | 'SEXUAL'
  | 'SPAM'
  | 'UNDERAGE'
  | 'VIOLENCE';

export type ReportStatus =
  | 'FLAGGED'
  | 'OK'
  | 'PENDING';

export type ReportSubjectType =
  | 'COMMENT'
  | 'POST'
  | 'PROMPT'
  | 'USER';

export type ReportSubmitMethod =
  | 'AUTOMATED'
  | 'MANUAL'
  | 'USER_SUBMITTED';

export type RequestDirection =
  | 'RECEIVED'
  | 'SENT';

export type RequestStatus =
  | 'CANCELLED'
  | 'COMPLETED'
  | 'SENT';

export type RequestType =
  | 'FEEL_REQUEST'
  | 'NUDGE';

export type SearchCommentsInput = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  commentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  friendsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPostIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SearchConversationsInput = {
  conversationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  conversationTypes?: InputMaybe<Array<ConversationType>>;
  includeDeletedConversations?: InputMaybe<Scalars['Boolean']['input']>;
  includeDeletedMessages?: InputMaybe<Scalars['Boolean']['input']>;
  includeEmptyConversations?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  memberIds?: InputMaybe<Array<Scalars['String']['input']>>;
  messagesToRetrieve?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<ConversationSearchProperties>;
  sort?: InputMaybe<ConversationSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ConversationStatus>;
};

export type SearchDocumentsSort =
  | 'DATE_CREATED'
  | 'DOCUMENT_NAME'
  | 'LAST_UPDATED';

export type SearchGroupsInput = {
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ownerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<GroupSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchInterviewCollectionsInput = {
  collectionId?: InputMaybe<Scalars['ID']['input']>;
  creatorUserId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchInterviewsInput = {
  parentCollectionId?: InputMaybe<Scalars['ID']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchInvitationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<InvitationSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<InviteStatus>;
};

export type SearchMessagesInput = {
  conversationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  includeDeletedMessages?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  messageIds?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<MessageSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SearchMusicInput = {
  categories?: InputMaybe<Array<Scalars['ID']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  songIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<MusicSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchNotificationInput = {
  direction?: InputMaybe<NotificationDirection>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  types?: InputMaybe<Array<NotificationType>>;
};

export type SearchPostsInputV2 = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  feed?: InputMaybe<FeedType>;
  fromDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  postIds?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<PostSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPromptIds?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['AWSDateTime']['input']>;
  type?: InputMaybe<Array<PostType>>;
  unwatchedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  useSuggested?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchPromptsInput = {
  authorUserId?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<PromptCategory>>;
  fromDate?: InputMaybe<Scalars['AWSDate']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  promptIdsNew?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  toDate?: InputMaybe<Scalars['AWSDate']['input']>;
  type?: InputMaybe<PostType>;
  unansweredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchProperties = {
  exactMatch: Scalars['Boolean']['input'];
  properties: Array<Scalars['String']['input']>;
  terms: Array<Scalars['String']['input']>;
};

export type SearchRatingsInput = {
  authorUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetPostIds?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<RatingType>;
};

export type SearchRequestInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
  requestDirection?: InputMaybe<RequestDirection>;
  requestIds?: InputMaybe<Array<Scalars['String']['input']>>;
  requestType?: InputMaybe<Array<RequestType>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Array<RequestStatus>>;
};

export type SearchTwinJobsInput = {
  creatorUserId?: InputMaybe<Scalars['ID']['input']>;
  jobStatus?: InputMaybe<Array<InputMaybe<JobStatus>>>;
  jobType?: InputMaybe<Array<InputMaybe<JobType>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  parentFileId?: InputMaybe<Scalars['ID']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchTwinMediaInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  mediaIds?: InputMaybe<Array<Scalars['String']['input']>>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
  targetModelConfigId?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type SearchUserTwinJobsInput = {
  jobStatus?: InputMaybe<Array<InputMaybe<JobStatus>>>;
  jobType?: InputMaybe<Array<InputMaybe<JobType>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<JobSearchSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchUsersInput = {
  hasActiveTwin?: InputMaybe<Scalars['Boolean']['input']>;
  includeHidden?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  relationship?: InputMaybe<ConnectionProperties>;
  search?: InputMaybe<SearchProperties>;
  sort?: InputMaybe<UserSortMethod>;
  sortDirection?: InputMaybe<SortDirection>;
  startIndex?: InputMaybe<Scalars['Int']['input']>;
};

export type SendInvitationInput = {
  users: Array<UserInviteInput>;
};

export type ServiceType =
  | 'BATCH'
  | 'LAMBDA'
  | 'SAGEMAKER'
  | 'UNKNOWN';

export type SongCategory =
  | 'ANGST'
  | 'AWE'
  | 'EUPHORIA'
  | 'HYPE'
  | 'MELANCHOLY'
  | 'NOSTALGIA'
  | 'TRANQUILITY';

export type SortDirection =
  | 'ASC'
  | 'DESC'
  | 'FIELD'
  | 'RAND';

export type SpeakerLabel =
  | 'SPK_0'
  | 'SPK_1'
  | 'UNKNOWN';

export type SpeakerRole =
  | 'HUMAN'
  | 'TWIN'
  | 'UNKNOWN';

export type SubmissionsHistoryInput = {
  lastSkipped?: InputMaybe<Scalars['AWSDateTime']['input']>;
};

export type SubmitFeedbackInput = {
  body: Scalars['String']['input'];
  imageKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  model: Scalars['String']['input'];
  osVersion: Scalars['String']['input'];
  platform: Scalars['String']['input'];
  type: FeedbackType;
};

export type SubmitReportInput = {
  reason: ReportReason;
  targetId: Scalars['String']['input'];
  targetType: ReportSubjectType;
  targetUserId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  conversation?: Maybe<SuperfeelConversation>;
  empty?: Maybe<GenericResponse>;
  message?: Maybe<SuperfeelMessage>;
  notification?: Maybe<SuperfeelNotification>;
  request?: Maybe<FeelRequest>;
  userTyping?: Maybe<UserTypingUpdate>;
};


export type SubscriptionConversationArgs = {
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionMessageArgs = {
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionNotificationArgs = {
  actorUserId?: InputMaybe<Scalars['String']['input']>;
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionRequestArgs = {
  recipientUserId?: InputMaybe<Scalars['String']['input']>;
  senderUserId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionUserTypingArgs = {
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type SuperfeelAdminUser = {
  __typename?: 'SuperfeelAdminUser';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  email: Scalars['String']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  name: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SuperfeelConversation = {
  __typename?: 'SuperfeelConversation';
  author?: Maybe<SuperfeelUser>;
  conversationId: Scalars['ID']['output'];
  conversationName: Scalars['String']['output'];
  conversationType: ConversationType;
  creatorAuthorId: Scalars['ID']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
  latestMessage?: Maybe<Scalars['AWSDateTime']['output']>;
  members?: Maybe<Array<SuperfeelUser>>;
  messages?: Maybe<Array<SuperfeelMessage>>;
  settings?: Maybe<SuperfeelConversationSettings>;
  status?: Maybe<ConversationStatus>;
  targetUserId?: Maybe<Scalars['String']['output']>;
  thumbnailUri?: Maybe<Scalars['String']['output']>;
};

export type SuperfeelConversationSettings = {
  __typename?: 'SuperfeelConversationSettings';
  dateArchived?: Maybe<Scalars['AWSDateTime']['output']>;
  dateUserAdded?: Maybe<Scalars['AWSDateTime']['output']>;
  dateUserDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  dateUserHidden?: Maybe<Scalars['AWSDateTime']['output']>;
  dateVerified?: Maybe<Scalars['AWSDateTime']['output']>;
  lastRead?: Maybe<Scalars['AWSDateTime']['output']>;
  mutedUntil?: Maybe<Scalars['AWSDateTime']['output']>;
  parentConversationId: Scalars['ID']['output'];
  parentUserId: Scalars['ID']['output'];
  settingsId: Scalars['ID']['output'];
};

export type SuperfeelCustomer = {
  __typename?: 'SuperfeelCustomer';
  customerId: Scalars['ID']['output'];
  customerName: Scalars['String']['output'];
  customerStripeId?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
};

export type SuperfeelMessage = {
  __typename?: 'SuperfeelMessage';
  activity?: Maybe<MessageActivity>;
  attachmentCount?: Maybe<Scalars['Int']['output']>;
  attachments?: Maybe<Array<MessageAttachment>>;
  author?: Maybe<SuperfeelUser>;
  authorUserId: Scalars['ID']['output'];
  body?: Maybe<Scalars['String']['output']>;
  conversationId: Scalars['ID']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  editId?: Maybe<Scalars['ID']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
  messageId: Scalars['ID']['output'];
  optimisticUpdateId?: Maybe<Scalars['String']['output']>;
  subjectId?: Maybe<Scalars['ID']['output']>;
  subjectType: NotificationSubjectType;
  targetUserId?: Maybe<Scalars['String']['output']>;
};

export type SuperfeelMusic = {
  __typename?: 'SuperfeelMusic';
  categoryName?: Maybe<Scalars['String']['output']>;
  contentUri: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  duration: Scalars['Float']['output'];
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  parentCategoryId: Scalars['ID']['output'];
  songId: Scalars['ID']['output'];
  songName: Scalars['String']['output'];
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type SuperfeelMusicCategory = {
  __typename?: 'SuperfeelMusicCategory';
  categoryId: Scalars['ID']['output'];
  categoryName: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type SuperfeelNotification = {
  __typename?: 'SuperfeelNotification';
  actionStatus?: Maybe<NotificationActionStatus>;
  actionType: NotificationActionType;
  actor?: Maybe<SuperfeelUser>;
  actorUserId: Scalars['String']['output'];
  /** @deprecated Use content instead - body is a static value, content is dynamic */
  body?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  customValue?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  notificationId: Scalars['String']['output'];
  notificationLink: Scalars['String']['output'];
  notificationThumbnail?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer in use - remove after V3 migration */
  prompt?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<SuperfeelUser>;
  recipientUserId: Scalars['String']['output'];
  secondaryActors?: Maybe<Array<SuperfeelUser>>;
  subjectType: NotificationSubjectType;
  targetId: Scalars['String']['output'];
  targetUserId?: Maybe<Scalars['String']['output']>;
  type: NotificationType;
};

export type SuperfeelPost = {
  __typename?: 'SuperfeelPost';
  audience: AudienceType;
  author?: Maybe<SuperfeelUser>;
  authorUserId: Scalars['String']['output'];
  backgroundSongId?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  commentsDisabled?: Maybe<Scalars['Boolean']['output']>;
  contentUri: Scalars['String']['output'];
  customThumbnailUri?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  flags?: Maybe<PostFlags>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  metadata?: Maybe<PostMetadata>;
  music?: Maybe<SuperfeelMusic>;
  postId: Scalars['String']['output'];
  prompt?: Maybe<SuperfeelPrompt>;
  rating?: Maybe<PostRating>;
  ratingsDisabled?: Maybe<Scalars['Boolean']['output']>;
  stats?: Maybe<PostStatistics>;
  tag: PostTopic;
  targetPromptId: Scalars['String']['output'];
  thumbnailUri: Scalars['String']['output'];
  transcript?: Maybe<Scalars['String']['output']>;
  twinGraphIngestionStatus?: Maybe<JobStatus>;
  type: PostType;
  videoStartTimestamp?: Maybe<Scalars['Float']['output']>;
  videoStopTimestamp?: Maybe<Scalars['Float']['output']>;
  volume: Scalars['Float']['output'];
};

export type SuperfeelPrompt = {
  __typename?: 'SuperfeelPrompt';
  author?: Maybe<SuperfeelUser>;
  authorUserId?: Maybe<Scalars['String']['output']>;
  body: Scalars['String']['output'];
  category?: Maybe<PromptCategory>;
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  hasCompleted?: Maybe<Scalars['Boolean']['output']>;
  intensity?: Maybe<Scalars['Int']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  promptId: Scalars['String']['output'];
  publishDate?: Maybe<Scalars['AWSDate']['output']>;
  tag?: Maybe<PromptTag>;
  type: PostType;
};

export type SuperfeelReport = {
  __typename?: 'SuperfeelReport';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateReviewed?: Maybe<Scalars['AWSDateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  reason: ReportReason;
  reportId: Scalars['String']['output'];
  reporterUserId: Scalars['String']['output'];
  status: ReportStatus;
  submitMethod: ReportSubmitMethod;
  targetId: Scalars['String']['output'];
  targetType: ReportSubjectType;
  targetUserId: Scalars['String']['output'];
};

export type SuperfeelUser = {
  __typename?: 'SuperfeelUser';
  aboutMe?: Maybe<Scalars['String']['output']>;
  connectionStatus?: Maybe<ConnectionStatus>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  dateOfBirth: Scalars['AWSDate']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  flags?: Maybe<UserFlags>;
  gender: Gender;
  genderDesc?: Maybe<Scalars['String']['output']>;
  interactions?: Maybe<UserInteractions>;
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isFlagged?: Maybe<Scalars['Boolean']['output']>;
  isGuest?: Maybe<Scalars['Boolean']['output']>;
  isVirtual?: Maybe<Scalars['Boolean']['output']>;
  lastActivity: Scalars['AWSDateTime']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  membership?: Maybe<UserMembership>;
  notifications?: Maybe<UserNotifications>;
  phone?: Maybe<Scalars['String']['output']>;
  preferences?: Maybe<UserPreferences>;
  profilePictureUri?: Maybe<Scalars['String']['output']>;
  profilePictureUri512?: Maybe<Scalars['String']['output']>;
  profilePictureUri1024?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<FeelRequest>>;
  stats?: Maybe<UserStatistics>;
  sub?: Maybe<Scalars['String']['output']>;
  submissions?: Maybe<UserSubmitHistory>;
  subscription?: Maybe<UserSubscription>;
  /** @deprecated Use twinModelConfigs instead - will be removed in v5 */
  twinConfigs?: Maybe<Array<TempUserTwinConfig>>;
  twinModelConfigs?: Maybe<Array<TwinModelConfig>>;
  twinTopics?: Maybe<Array<TwinTopic>>;
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type SuperfeelUserGroup = {
  __typename?: 'SuperfeelUserGroup';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  groupId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  members?: Maybe<Array<SuperfeelUser>>;
  owner?: Maybe<SuperfeelUser>;
  ownerUserId: Scalars['String']['output'];
};

export type SystemPromptPosition =
  | 'MAIN'
  | 'START';

export type TempUserTwinConfig = {
  __typename?: 'TempUserTwinConfig';
  isActive: Scalars['Boolean']['output'];
  modelConfigId: Scalars['ID']['output'];
  twinDescription?: Maybe<Scalars['String']['output']>;
  twinLevel: Scalars['Int']['output'];
  twinType: TwinType;
};

export type TestInput = {
  property?: InputMaybe<Scalars['String']['input']>;
};

export type TimerCompletionType =
  | 'TIMEOUT'
  | 'USER';

export type TimerEventInput = {
  completed: TimerCompletionType;
  end: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  screen: Scalars['String']['input'];
  screenParams?: InputMaybe<Scalars['AWSJSON']['input']>;
  start: Scalars['Int']['input'];
  timestamp: Scalars['AWSDateTime']['input'];
};

export type TwinConfigInput = {
  /** @deprecated Use twinVisibility instead */
  isTwinActive?: InputMaybe<Scalars['Boolean']['input']>;
  modelConfigId: Scalars['String']['input'];
  promptConversationalStyle?: InputMaybe<Scalars['String']['input']>;
  promptCustomValue?: InputMaybe<Scalars['String']['input']>;
  promptNamePronunciation?: InputMaybe<Scalars['String']['input']>;
  promptPersonalBackground?: InputMaybe<Scalars['String']['input']>;
  promptPersonalityTraits?: InputMaybe<Scalars['String']['input']>;
  promptStarterPhrase?: InputMaybe<Scalars['String']['input']>;
  selectedTtsConfigId?: InputMaybe<Scalars['String']['input']>;
  twinDescription?: InputMaybe<Scalars['String']['input']>;
  twinLanguage?: InputMaybe<Scalars['String']['input']>;
  twinVisibility?: InputMaybe<TwinConfigVisibility>;
  voiceSpeed?: InputMaybe<Scalars['Float']['input']>;
};

export type TwinConfigVisibility =
  | 'FRIENDS'
  | 'HIDDEN'
  | 'PRIVATE'
  | 'PUBLIC';

export type TwinDeployment = {
  __typename?: 'TwinDeployment';
  approvedDomain: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  deploymentId: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  maxRatePerDay: Scalars['Int']['output'];
  maxRatePerSec: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentCustomerId: Scalars['ID']['output'];
  twinModelConfigId: Scalars['ID']['output'];
  twinUserId: Scalars['ID']['output'];
};

export type TwinDocument = {
  __typename?: 'TwinDocument';
  audience: AudienceType;
  contentUri: Scalars['String']['output'];
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  documentId: Scalars['ID']['output'];
  documentName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  targetUserId: Scalars['ID']['output'];
  twinGraphIngestionStatus?: Maybe<JobStatus>;
};

export type TwinFunctionConfig = {
  __typename?: 'TwinFunctionConfig';
  functionId: Scalars['ID']['output'];
  functionName: Scalars['String']['output'];
  instruction: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  parameters: Scalars['AWSJSON']['output'];
  requiredParameters: Scalars['AWSJSON']['output'];
  targetTwinType: TwinType;
};

export type TwinGraphDigest = {
  __typename?: 'TwinGraphDigest';
  conversationalStyle?: Maybe<Scalars['String']['output']>;
  keyExperiences?: Maybe<Scalars['String']['output']>;
  personalitySummary?: Maybe<Scalars['String']['output']>;
  twinDescription?: Maybe<Scalars['String']['output']>;
};

export type TwinJob = {
  __typename?: 'TwinJob';
  batchJobId?: Maybe<Scalars['ID']['output']>;
  creatorUserId?: Maybe<Scalars['ID']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  jobId: Scalars['ID']['output'];
  jobType: JobType;
  lastUpdated: Scalars['AWSDateTime']['output'];
  parentFileId: Scalars['ID']['output'];
  parentFileType?: Maybe<JobFileType>;
  serviceType: ServiceType;
  status: JobStatus;
  statusMessage?: Maybe<Scalars['String']['output']>;
  targetUserId: Scalars['ID']['output'];
};

export type TwinMedia = {
  __typename?: 'TwinMedia';
  contentUri: Scalars['String']['output'];
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  durationSecs: Scalars['Int']['output'];
  lastUpdated?: Maybe<Scalars['AWSDateTime']['output']>;
  mediaId: Scalars['ID']['output'];
  mediaName: Scalars['String']['output'];
  targetModelConfigId: Scalars['ID']['output'];
  targetUserId: Scalars['ID']['output'];
  transcription?: Maybe<Scalars['AWSJSON']['output']>;
};

export type TwinModelAsrConfig = {
  __typename?: 'TwinModelAsrConfig';
  activeModelConfigIds?: Maybe<Array<Scalars['ID']['output']>>;
  asrConfigId: Scalars['ID']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
};

export type TwinModelAsrConfigInput = {
  language?: InputMaybe<Scalars['String']['input']>;
};

export type TwinModelConfig = {
  __typename?: 'TwinModelConfig';
  asrConfig?: Maybe<TwinModelAsrConfig>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayText?: Maybe<Scalars['String']['output']>;
  functionConfigs?: Maybe<Array<TwinFunctionConfig>>;
  /** @deprecated Use visibility instead */
  isActive: Scalars['Boolean']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  llmProvider: LlmProvider;
  modelConfigId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  promptConfig?: Maybe<TwinPromptConfig>;
  selectedAsrConfigId?: Maybe<Scalars['String']['output']>;
  selectedPromptConfigId?: Maybe<Scalars['ID']['output']>;
  selectedTtsConfigId?: Maybe<Scalars['String']['output']>;
  targetUserId?: Maybe<Scalars['ID']['output']>;
  ttsConfig?: Maybe<TwinModelTtsConfig>;
  twinDescription?: Maybe<Scalars['String']['output']>;
  twinLevel: Scalars['Int']['output'];
  twinType: TwinType;
  visibility?: Maybe<TwinConfigVisibility>;
};

export type TwinModelConfigInput = {
  asrConfig?: InputMaybe<TwinModelAsrConfigInput>;
  configId?: InputMaybe<Scalars['ID']['input']>;
  llmProvider?: InputMaybe<LlmProvider>;
  name?: InputMaybe<Scalars['String']['input']>;
  selectedPromptConfigId?: InputMaybe<Scalars['ID']['input']>;
  selectedTtsConfigId?: InputMaybe<Scalars['ID']['input']>;
  targetUserId: Scalars['ID']['input'];
  ttsConfig?: InputMaybe<TwinModelTtsConfigInput>;
  twinDescription?: InputMaybe<Scalars['String']['input']>;
  visibility?: InputMaybe<TwinConfigVisibility>;
};

export type TwinModelTtsConfig = {
  __typename?: 'TwinModelTtsConfig';
  activeModelConfigIds?: Maybe<Array<Scalars['ID']['output']>>;
  clarity?: Maybe<Scalars['Float']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
  manifestUri: Scalars['String']['output'];
  maxCharacters?: Maybe<Scalars['Int']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
  stability?: Maybe<Scalars['Float']['output']>;
  streamingLatency?: Maybe<Scalars['Int']['output']>;
  targetUserId: Scalars['ID']['output'];
  ttsConfigId: Scalars['ID']['output'];
  useSpeakerBoost?: Maybe<Scalars['Boolean']['output']>;
  voiceId: Scalars['String']['output'];
  voiceSampleText?: Maybe<Scalars['String']['output']>;
  voiceSampleUri?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['Float']['output']>;
};

export type TwinModelTtsConfigInput = {
  clarity?: InputMaybe<Scalars['Float']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  manifestUri: Scalars['String']['input'];
  maxCharacters?: InputMaybe<Scalars['Int']['input']>;
  speed?: InputMaybe<Scalars['Float']['input']>;
  stability?: InputMaybe<Scalars['Float']['input']>;
  streamingLatency?: InputMaybe<Scalars['Int']['input']>;
  useSpeakerBoost?: InputMaybe<Scalars['Boolean']['input']>;
  voiceId: Scalars['String']['input'];
  volume?: InputMaybe<Scalars['Float']['input']>;
};

export type TwinPromptConfig = {
  __typename?: 'TwinPromptConfig';
  activeModelConfigIds?: Maybe<Array<Scalars['ID']['output']>>;
  configName: Scalars['String']['output'];
  conversationalStyle?: Maybe<Scalars['String']['output']>;
  customValue?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  lastUpdated: Scalars['AWSDateTime']['output'];
  namePronunciation?: Maybe<Scalars['String']['output']>;
  overrideActive: Scalars['Boolean']['output'];
  personalBackground?: Maybe<Scalars['String']['output']>;
  personalityTraits?: Maybe<Scalars['String']['output']>;
  promptConfigId: Scalars['ID']['output'];
  promptOverride?: Maybe<Scalars['String']['output']>;
  startPhrase?: Maybe<Scalars['String']['output']>;
  targetUserId: Scalars['ID']['output'];
};

export type TwinPromptInput = {
  configName?: InputMaybe<Scalars['String']['input']>;
  conversationalStyle?: InputMaybe<Scalars['String']['input']>;
  customValue?: InputMaybe<Scalars['String']['input']>;
  namePronunciation?: InputMaybe<Scalars['String']['input']>;
  overrideActive?: InputMaybe<Scalars['Boolean']['input']>;
  personalBackground?: InputMaybe<Scalars['String']['input']>;
  personalityTraits?: InputMaybe<Scalars['String']['input']>;
  promptConfigId?: InputMaybe<Scalars['ID']['input']>;
  promptOverride?: InputMaybe<Scalars['String']['input']>;
  startPhrase?: InputMaybe<Scalars['String']['input']>;
  targetUserId: Scalars['ID']['input'];
};

export type TwinTopic = {
  __typename?: 'TwinTopic';
  dateCreated: Scalars['AWSDateTime']['output'];
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  isActive: Scalars['Boolean']['output'];
  targetUserId: Scalars['ID']['output'];
  topicDescription?: Maybe<Scalars['String']['output']>;
  topicId: Scalars['ID']['output'];
  topicName: Scalars['String']['output'];
  twinModelConfigId: Scalars['ID']['output'];
};

export type TwinTranscript = {
  __typename?: 'TwinTranscript';
  body: Scalars['String']['output'];
  dateCreated: Scalars['AWSDateTime']['output'];
  roomId: Scalars['ID']['output'];
  speakerRole: SpeakerRole;
  speakerUserId: Scalars['ID']['output'];
  targetUserId: Scalars['ID']['output'];
  transcriptId: Scalars['ID']['output'];
  twinModelConfigId: Scalars['ID']['output'];
  twinUserId: Scalars['ID']['output'];
};

export type TwinType =
  | 'CHAT'
  | 'MEDIA';

export type UpdateConnectionInput = {
  /** @deprecated No longer a relationship type, kept in graphql schema to avoid 'unknown property' errors */
  isCircle?: InputMaybe<Scalars['Boolean']['input']>;
  isFollowing: Scalars['Boolean']['input'];
  isHidden?: InputMaybe<Scalars['Boolean']['input']>;
  newPostNotification?: InputMaybe<Scalars['String']['input']>;
  prioritisePostsInFeed?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContentInput = {
  action: UpdateType;
  post: PostInfoInput;
};

export type UpdateConversationInput = {
  action: UpdateType;
  conversation: ConversationInput;
};

export type UpdateConversationTypingStatusInput = {
  conversationId: Scalars['String']['input'];
};

export type UpdateFlagsInput = {
  flags: PostFlagsInput;
  targetCommentId?: InputMaybe<Scalars['String']['input']>;
  targetMessageId?: InputMaybe<Scalars['String']['input']>;
  targetPostId?: InputMaybe<Scalars['String']['input']>;
  targetUserId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  action: UpdateType;
  group: UserGroupInput;
};

export type UpdateInterviewCollectionInput = {
  action: UpdateType;
  collection: InterviewCollectionInput;
};

export type UpdateInterviewInput = {
  action: UpdateType;
  interview: InterviewInput;
};

export type UpdateInterviewSegmentsInput = {
  action: UpdateType;
  interviewSegment: InterviewSegmentsInput;
};

export type UpdateMessageInput = {
  action: UpdateType;
  message: MessageInput;
};

export type UpdateRatingInput = {
  rating: RatingType;
  targetPostId: Scalars['String']['input'];
};

export type UpdateRequestInput = {
  action: UpdateType;
  customPrompt?: InputMaybe<Scalars['String']['input']>;
  recipientUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  targetPromptId?: InputMaybe<Scalars['String']['input']>;
  targetRequestId?: InputMaybe<Scalars['String']['input']>;
  type: RequestType;
};

export type UpdateSettingsInput = {
  notifications?: InputMaybe<NotificationsInput>;
  preferences?: InputMaybe<PreferencesInput>;
  submissions?: InputMaybe<SubmissionsHistoryInput>;
};

export type UpdateTwinConfigInput = {
  action: UpdateType;
  input: TwinConfigInput;
};

export type UpdateTwinMediaInput = {
  legacyMediaId?: InputMaybe<Scalars['String']['input']>;
  mediaName: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  targetModelConfigId: Scalars['ID']['input'];
  targetUserId: Scalars['ID']['input'];
};

export type UpdateTwinVoiceSampleInput = {
  speed: Scalars['Float']['input'];
  text: Scalars['String']['input'];
  ttsConfigId: Scalars['String']['input'];
};

export type UpdateType =
  | 'CREATE'
  | 'DELETE'
  | 'UPDATE';

export type UpdateUserConversationSettingsInput = {
  conversationIds: Array<Scalars['ID']['input']>;
  settings: ConversationSettingsInput;
};

export type UpdateUserInput = {
  action: UpdateType;
  deleteOptions?: InputMaybe<DeleteUserOptions>;
  user: UserInfoInput;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  headers: Scalars['AWSJSON']['output'];
  type: Scalars['String']['output'];
  uploadId?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type UserFlags = {
  __typename?: 'UserFlags';
  deactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  deleteReason?: Maybe<DeleteAccountReason>;
  deletedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastDeactivatedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  lastReportedAt?: Maybe<Scalars['AWSDateTime']['output']>;
  reportedAt?: Maybe<Scalars['AWSDateTime']['output']>;
};

export type UserGroupInput = {
  addMembers?: InputMaybe<Array<Scalars['String']['input']>>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  internalGroup?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  removeMembers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserInfoInput = {
  aboutMe?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['AWSDate']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  genderDesc?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  profilePictureUri?: InputMaybe<Scalars['String']['input']>;
};

export type UserInteractions = {
  __typename?: 'UserInteractions';
  sendMessage: Scalars['Boolean']['output'];
  sendRequest: Scalars['Boolean']['output'];
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  dateAccepted?: Maybe<Scalars['AWSDateTime']['output']>;
  dateCreated?: Maybe<Scalars['AWSDateTime']['output']>;
  dateDeleted?: Maybe<Scalars['AWSDateTime']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  invitationId: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  senderUserId: Scalars['String']['output'];
  status?: Maybe<InviteStatus>;
};

export type UserInviteInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type UserInvitesSort =
  | 'DATE_CREATED'
  | 'FIRST_NAME'
  | 'LAST_NAME';

export type UserLandingPagePreference =
  | 'CREATE_PAGE'
  | 'FAVOURITES';

export type UserMembership = {
  __typename?: 'UserMembership';
  customerId?: Maybe<Scalars['String']['output']>;
  dateCreated: Scalars['AWSDateTime']['output'];
  lastUpdated: Scalars['AWSDateTime']['output'];
  membershipId: Scalars['String']['output'];
  parentUserId: Scalars['String']['output'];
  status: MembershipStatus;
  type: MembershipType;
};

export type UserNotifications = {
  __typename?: 'UserNotifications';
  fotdReminder: Scalars['Boolean']['output'];
  newComment: NotificationSetting;
  newConnection: Scalars['Boolean']['output'];
  newFeelRequest: Scalars['Boolean']['output'];
  newFollower: Scalars['Boolean']['output'];
  newMessage: Scalars['Boolean']['output'];
  newMessageRequest: Scalars['Boolean']['output'];
  newPost: NotificationSetting;
  newRating: NotificationSetting;
  newRequest: NotificationSetting;
  nudge: Scalars['Boolean']['output'];
  nudgeRequest: Scalars['Boolean']['output'];
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  allowAnalytics: Scalars['Boolean']['output'];
  feelsPromptDisplayLength: Scalars['Int']['output'];
  initialLandingPage: UserLandingPagePreference;
  language: Scalars['String']['output'];
  onboardingBookmark?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingFeed?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingInviteUsers?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingProfile?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingRating?: Maybe<Scalars['AWSDateTime']['output']>;
  onboardingRecordVideo?: Maybe<Scalars['AWSDateTime']['output']>;
  /** @deprecated Reputation no longer manually set. Will be removed */
  onboardingReputation?: Maybe<Scalars['AWSDateTime']['output']>;
  receiveRequests: NotificationSetting;
  sendAutomatedInvites?: Maybe<Scalars['Boolean']['output']>;
  showOnboarding: Scalars['Boolean']['output'];
  timezone: Scalars['String']['output'];
};

export type UserSortMethod =
  | 'DATE_CREATED'
  | 'LAST_TWIN_INTERACTION'
  | 'NAME'
  | 'USERNAME';

export type UserStatistics = {
  __typename?: 'UserStatistics';
  followedBy: Scalars['Int']['output'];
  follows: Scalars['Int']['output'];
  hidden: Scalars['Int']['output'];
};

export type UserSubmitHistory = {
  __typename?: 'UserSubmitHistory';
  lastFotd?: Maybe<Scalars['AWSDateTime']['output']>;
  lastNudgeRequest?: Maybe<Scalars['AWSDateTime']['output']>;
  lastReal?: Maybe<Scalars['AWSDateTime']['output']>;
  lastRealId?: Maybe<Scalars['String']['output']>;
  lastSkipped?: Maybe<Scalars['AWSDateTime']['output']>;
  lastSubmission?: Maybe<Scalars['AWSDateTime']['output']>;
  parentUserId: Scalars['String']['output'];
};

export type UserSubscription = {
  __typename?: 'UserSubscription';
  newPostNotification?: Maybe<Scalars['AWSDateTime']['output']>;
  parentUserId: Scalars['String']['output'];
  prioritisePostsInFeed?: Maybe<Scalars['AWSDateTime']['output']>;
  subscriptionId: Scalars['String']['output'];
  targetUserId: Scalars['String']['output'];
};

export type UserTypingUpdate = {
  __typename?: 'UserTypingUpdate';
  conversationId: Scalars['String']['output'];
  targetUserId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type UserWatchHistory = {
  __typename?: 'UserWatchHistory';
  lastViewed: Scalars['String']['output'];
  parentPostId: Scalars['String']['output'];
  parentUserId: Scalars['String']['output'];
  views3secs: Scalars['Int']['output'];
  views50pct: Scalars['Int']['output'];
  viewsComplete: Scalars['Int']['output'];
};

export type VideoEventInput = {
  duration: Scalars['Float']['input'];
  event: VideoEventType;
  id: Scalars['String']['input'];
  position: Scalars['Float']['input'];
  screen: Scalars['String']['input'];
  screenParams?: InputMaybe<Scalars['AWSJSON']['input']>;
  targetAuthorId?: InputMaybe<Scalars['String']['input']>;
  targetPostId?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['AWSDateTime']['input'];
};

export type VideoEventType =
  | 'FINISH'
  | 'PAUSE'
  | 'RESUME'
  | 'SEEK'
  | 'START';

export type AdminCreateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;


export type AdminCreateUserMutation = { __typename?: 'Mutation', adminUpdateUser?: { __typename?: 'SuperfeelUser', userId: string, username: string, firstName: string, lastName?: string | undefined, email?: string | undefined, phone?: string | undefined, isVirtual?: boolean | undefined, dateCreated: string, membership?: { __typename?: 'UserMembership', type: MembershipType } | undefined } | undefined };

export type AdminUpdateUserMutationVariables = Exact<{
  input: AdminUpdateUserInput;
}>;


export type AdminUpdateUserMutation = { __typename?: 'Mutation', adminUpdateUser?: { __typename?: 'SuperfeelUser', userId: string, username: string, firstName: string, lastName?: string | undefined, email?: string | undefined, phone?: string | undefined, isVirtual?: boolean | undefined, dateCreated: string, dateDeleted?: string | undefined, gender: Gender, lastActivity: string, isFlagged?: boolean | undefined, membership?: { __typename?: 'UserMembership', type: MembershipType } | undefined } | undefined };

export type UpdateInterviewCollectionMutationVariables = Exact<{
  input: UpdateInterviewCollectionInput;
}>;


export type UpdateInterviewCollectionMutation = { __typename?: 'Mutation', updateInterviewCollection?: { __typename?: 'InterviewCollection', interviewerName?: string | undefined, dateDeleted?: string | undefined, lastUpdated: string, name: string, targetUserId: string, collectionId: string, creatorUserId: string, dateCreated: string } | undefined };

export type AdminUpdateTwinModelConfigMutationVariables = Exact<{
  input: AdminUpdateTwinModelConfigInput;
}>;


export type AdminUpdateTwinModelConfigMutation = { __typename?: 'Mutation', adminUpdateTwinModelConfig?: { __typename?: 'TwinModelConfig', modelConfigId: string, name: string, description?: string | undefined, selectedTtsConfigId?: string | undefined, selectedAsrConfigId?: string | undefined, selectedPromptConfigId?: string | undefined, llmProvider: LlmProvider, targetUserId?: string | undefined, dateCreated: string, lastUpdated: string, dateDeleted?: string | undefined, twinLevel: number, twinDescription?: string | undefined, twinType: TwinType, visibility?: TwinConfigVisibility | undefined, ttsConfig?: { __typename?: 'TwinModelTtsConfig', dateCreated: string, voiceId: string } | undefined, asrConfig?: { __typename?: 'TwinModelAsrConfig', language?: string | undefined } | undefined, promptConfig?: { __typename?: 'TwinPromptConfig', promptConfigId: string } | undefined, functionConfigs?: Array<{ __typename?: 'TwinFunctionConfig', functionId: string, functionName: string }> | undefined } | undefined };

export type UpdateInterviewMutationVariables = Exact<{
  input: UpdateInterviewInput;
}>;


export type UpdateInterviewMutation = { __typename?: 'Mutation', updateInterview?: { __typename?: 'Interview', uploaderUserId: string, targetUserId: string, s3Key: string, parentCollectionId: string, lastUpdated: string, interviewId: string, fileSize: number, fileName: string, durationSecs: number, dateDeleted?: string | undefined, dateCreated: string } | undefined };

export type CompleteFileUploadMutationVariables = Exact<{
  input: CompleteFileUploadInput;
}>;


export type CompleteFileUploadMutation = { __typename?: 'Mutation', completeFileUpload: { __typename?: 'FileUpload', eTag: string, key: string, location: string } };

export type AdminUpdateVoiceSampleMutationVariables = Exact<{
  input: AdminUpdateTwinVoiceSampleInput;
}>;


export type AdminUpdateVoiceSampleMutation = { __typename?: 'Mutation', adminUpdateTwinVoiceSample?: { __typename?: 'TwinModelTtsConfig', voiceSampleUri?: string | undefined, voiceId: string, ttsConfigId: string, speed?: number | undefined } | undefined };

export type AdminUpdateTwinVoiceMutationVariables = Exact<{
  input: AdminUpdateTwinVoiceInput;
}>;


export type AdminUpdateTwinVoiceMutation = { __typename?: 'Mutation', adminUpdateTwinVoice?: { __typename?: 'TwinModelTtsConfig', voiceSampleUri?: string | undefined, ttsConfigId: string } | undefined };

export type AdminCreateRoomMutationVariables = Exact<{ [key: string]: never; }>;


export type AdminCreateRoomMutation = { __typename?: 'Mutation', adminCreateRoom?: { __typename?: 'CreateRoomResult', url: string } | undefined };

export type AdminInviteTwinToRoomMutationVariables = Exact<{
  input: AdminInviteTwinToRoomInput;
}>;


export type AdminInviteTwinToRoomMutation = { __typename?: 'Mutation', adminInviteTwinToChatRoom?: { __typename?: 'GenericResponse', success?: boolean | undefined, message?: string | undefined } | undefined };

export type AdminUpdateTwinPromptMutationVariables = Exact<{
  input: AdminUpdateTwinPromptInput;
}>;


export type AdminUpdateTwinPromptMutation = { __typename?: 'Mutation', adminUpdateTwinPrompt?: { __typename?: 'TwinPromptConfig', customValue?: string | undefined, dateCreated: string, dateDeleted?: string | undefined, lastUpdated: string, personalityTraits?: string | undefined, promptConfigId: string, startPhrase?: string | undefined, promptOverride?: string | undefined, namePronunciation?: string | undefined, conversationalStyle?: string | undefined, personalBackground?: string | undefined, configName: string, overrideActive: boolean } | undefined };

export type AdminStartInterviewProcessMutationVariables = Exact<{
  input: AdminStartInterviewProcessInput;
}>;


export type AdminStartInterviewProcessMutation = { __typename?: 'Mutation', adminStartInterviewProcess?: { __typename?: 'TwinJob', jobId: string, batchJobId?: string | undefined, serviceType: ServiceType, status: JobStatus, jobType: JobType, targetUserId: string, statusMessage?: string | undefined, parentFileId: string, parentFileType?: JobFileType | undefined, creatorUserId?: string | undefined, dateCreated: string, lastUpdated: string } | undefined };

export type AdminUpdateTwinJobMutationVariables = Exact<{
  input: AdminUpdateTwinJobInput;
}>;


export type AdminUpdateTwinJobMutation = { __typename?: 'Mutation', adminUpdateTwinJob?: Array<{ __typename?: 'TwinJob', jobId: string, batchJobId?: string | undefined, serviceType: ServiceType, status: JobStatus, jobType: JobType, targetUserId: string, statusMessage?: string | undefined, parentFileId: string, parentFileType?: JobFileType | undefined, creatorUserId?: string | undefined, dateCreated: string, lastUpdated: string }> | undefined };

export type AdminUpdateTwinDocumentMutationVariables = Exact<{
  input: AdminUpdateTwinDocumentInput;
}>;


export type AdminUpdateTwinDocumentMutation = { __typename?: 'Mutation', adminUpdateTwinDocument?: { __typename?: 'TwinDocument', documentId: string, targetUserId: string, documentName: string, contentUri: string, audience: AudienceType, fileSize: number, dateCreated?: string | undefined, lastUpdated?: string | undefined, dateDeleted?: string | undefined, twinGraphIngestionStatus?: JobStatus | undefined } | undefined };

export type UpdateInterviewSegmentsMutationVariables = Exact<{
  input: UpdateInterviewSegmentsInput;
}>;


export type UpdateInterviewSegmentsMutation = { __typename?: 'Mutation', updateInterviewSegments?: Array<{ __typename?: 'InterviewSegment', interviewSegmentId: string, parentInterviewId: string, parentCollectionId: string, targetUserId: string, transcription: string, s3Key: string, fileSize: number, startTimeSecs: number, endTimeSecs: number, speakerLabel?: SpeakerLabel | undefined, speakerRole?: InterviewRole | undefined, dateCreated: string, lastUpdated: string } | undefined> | undefined };

export type AdminGetUserQueryVariables = Exact<{
  input: AdminGetUserInput;
}>;


export type AdminGetUserQuery = { __typename?: 'Query', adminGetUser?: { __typename?: 'AdminGetUserResponse', totalCount?: number | undefined, user?: { __typename?: 'SuperfeelUser', userId: string, username: string, email?: string | undefined, firstName: string, lastName?: string | undefined, dateCreated: string, dateDeleted?: string | undefined, isFlagged?: boolean | undefined, isVirtual?: boolean | undefined, gender: Gender, phone?: string | undefined, lastActivity: string, profilePictureUri?: string | undefined, stats?: { __typename?: 'UserStatistics', follows: number, hidden: number, followedBy: number } | undefined, flags?: { __typename?: 'UserFlags', deactivatedAt?: string | undefined, deleteReason?: DeleteAccountReason | undefined, deletedAt?: string | undefined, lastDeactivatedAt?: string | undefined } | undefined, interactions?: { __typename?: 'UserInteractions', sendMessage: boolean, sendRequest: boolean } | undefined, membership?: { __typename?: 'UserMembership', type: MembershipType, membershipId: string, dateCreated: string, lastUpdated: string, status: MembershipStatus, parentUserId: string } | undefined } | undefined } | undefined };

export type SearchInterviewCollectionsQueryVariables = Exact<{
  input: SearchInterviewCollectionsInput;
}>;


export type SearchInterviewCollectionsQuery = { __typename?: 'Query', searchInterviewCollections?: Array<{ __typename?: 'InterviewCollection', interviewerName?: string | undefined, dateDeleted?: string | undefined, lastUpdated: string, name: string, targetUserId: string, collectionId: string, creatorUserId: string, dateCreated: string, interviews?: Array<{ __typename?: 'Interview', dateCreated: string, durationSecs: number, fileName: string, fileSize: number, interviewId: string, parentCollectionId: string, s3Key: string, targetUserId: string, uploaderUserId: string, lastUpdated: string, processingStage?: JobType | undefined, twinGraphIngestionStatus?: JobStatus | undefined }> | undefined }> | undefined };

export type AdminSearchUsersQueryVariables = Exact<{
  input: AdminSearchUsersInput;
}>;


export type AdminSearchUsersQuery = { __typename?: 'Query', adminSearchUsers?: { __typename?: 'AdminSearchUsersResponse', totalCount: number, users?: Array<{ __typename?: 'SuperfeelUser', userId: string, username: string, sub?: string | undefined, firstName: string, lastName?: string | undefined, dateOfBirth: string, email?: string | undefined, phone?: string | undefined, gender: Gender, genderDesc?: string | undefined, dateCreated: string, dateDeleted?: string | undefined, lastActivity: string, profilePictureUri?: string | undefined, aboutMe?: string | undefined, isFlagged?: boolean | undefined, isVirtual?: boolean | undefined, connectionStatus?: ConnectionStatus | undefined, membership?: { __typename?: 'UserMembership', type: MembershipType, membershipId: string, dateCreated: string, lastUpdated: string, status: MembershipStatus, parentUserId: string } | undefined } | undefined> | undefined } | undefined };

export type AdminSearchTwinModelConfigsQueryVariables = Exact<{
  input: AdminSearchTwinModelConfigsInput;
}>;


export type AdminSearchTwinModelConfigsQuery = { __typename?: 'Query', adminSearchTwinModelConfigs?: Array<{ __typename?: 'TwinModelConfig', modelConfigId: string, name: string, description?: string | undefined, selectedTtsConfigId?: string | undefined, selectedAsrConfigId?: string | undefined, selectedPromptConfigId?: string | undefined, llmProvider: LlmProvider, targetUserId?: string | undefined, dateCreated: string, lastUpdated: string, dateDeleted?: string | undefined, twinLevel: number, twinDescription?: string | undefined, twinType: TwinType, visibility?: TwinConfigVisibility | undefined, ttsConfig?: { __typename?: 'TwinModelTtsConfig', ttsConfigId: string, dateCreated: string, voiceId: string, voiceSampleUri?: string | undefined, language?: string | undefined, speed?: number | undefined, voiceSampleText?: string | undefined } | undefined, asrConfig?: { __typename?: 'TwinModelAsrConfig', language?: string | undefined } | undefined, promptConfig?: { __typename?: 'TwinPromptConfig', promptConfigId: string, configName: string, overrideActive: boolean, personalityTraits?: string | undefined, personalBackground?: string | undefined, promptOverride?: string | undefined, startPhrase?: string | undefined, conversationalStyle?: string | undefined, customValue?: string | undefined } | undefined, functionConfigs?: Array<{ __typename?: 'TwinFunctionConfig', functionId: string, functionName: string }> | undefined }> | undefined };

export type AdminGetSignedS3UrlQueryVariables = Exact<{
  input: AdminGetSignedS3UrlInput;
}>;


export type AdminGetSignedS3UrlQuery = { __typename?: 'Query', adminGetSignedS3Url: { __typename?: 'UploadUrl', headers: any, type: string, url: string, uploadId?: string | undefined } };

export type AdminSearchTwinMediaQueryVariables = Exact<{
  input: AdminSearchTwinMediaInput;
}>;


export type AdminSearchTwinMediaQuery = { __typename?: 'Query', adminSearchTwinMedia?: Array<{ __typename?: 'TwinMedia', mediaId: string, targetModelConfigId: string, mediaName: string, contentUri: string, durationSecs: number, targetUserId: string }> | undefined };

export type AminSearchTwinVoicesQueryVariables = Exact<{
  input: AdminSearchTwinVoicesInput;
}>;


export type AminSearchTwinVoicesQuery = { __typename?: 'Query', adminSearchTwinVoices?: Array<{ __typename?: 'TwinModelTtsConfig', voiceSampleUri?: string | undefined, voiceSampleText?: string | undefined, speed?: number | undefined, language?: string | undefined, ttsConfigId: string, targetUserId: string, voiceId: string, dateCreated: string, lastUpdated: string, activeModelConfigIds?: Array<string> | undefined }> | undefined };

export type AdminGetTwinGraphDigestQueryVariables = Exact<{
  input: AdminGetTwinDigestInput;
}>;


export type AdminGetTwinGraphDigestQuery = { __typename?: 'Query', adminGetTwinGraphDigest?: { __typename?: 'TwinGraphDigest', personalitySummary?: string | undefined, keyExperiences?: string | undefined, conversationalStyle?: string | undefined, twinDescription?: string | undefined } | undefined };

export type AdminSearchTwinPromptQueryVariables = Exact<{
  input: AdminSearchTwinPromptInput;
}>;


export type AdminSearchTwinPromptQuery = { __typename?: 'Query', adminSearchTwinPrompt?: Array<{ __typename?: 'TwinPromptConfig', customValue?: string | undefined, dateCreated: string, dateDeleted?: string | undefined, lastUpdated: string, personalityTraits?: string | undefined, promptConfigId: string, overrideActive: boolean, startPhrase?: string | undefined, promptOverride?: string | undefined, namePronunciation?: string | undefined, conversationalStyle?: string | undefined, personalBackground?: string | undefined, configName: string, activeModelConfigIds?: Array<string> | undefined }> | undefined };

export type AdminSearchSamplesQueryVariables = Exact<{
  input: AdminSearchSamplesInput;
}>;


export type AdminSearchSamplesQuery = { __typename?: 'Query', adminSearchSamples?: Array<{ __typename?: 'InterviewSegment', dateCreated: string, endTimeSecs: number, fileSize: number, interviewSegmentId: string, lastUpdated: string, parentCollectionId: string, parentInterviewId: string, s3Key: string, startTimeSecs: number, targetUserId: string, transcription: string, speakerRole?: InterviewRole | undefined, speakerLabel?: SpeakerLabel | undefined }> | undefined };

export type AdminSearchTwinJobsQueryVariables = Exact<{
  input: SearchTwinJobsInput;
}>;


export type AdminSearchTwinJobsQuery = { __typename?: 'Query', searchTwinJobs?: Array<{ __typename?: 'TwinJob', jobId: string, batchJobId?: string | undefined, serviceType: ServiceType, status: JobStatus, jobType: JobType, targetUserId: string, statusMessage?: string | undefined, parentFileId: string, parentFileType?: JobFileType | undefined, creatorUserId?: string | undefined, dateCreated: string, lastUpdated: string }> | undefined };

export type AdminSearchTwinDocumentsQueryVariables = Exact<{
  input: AdminSearchTwinDocumentsInput;
}>;


export type AdminSearchTwinDocumentsQuery = { __typename?: 'Query', adminSearchTwinDocuments?: Array<{ __typename?: 'TwinDocument', documentId: string, targetUserId: string, documentName: string, contentUri: string, audience: AudienceType, fileSize: number, dateCreated?: string | undefined, lastUpdated?: string | undefined, dateDeleted?: string | undefined, twinGraphIngestionStatus?: JobStatus | undefined }> | undefined };


export const AdminCreateUserDocument = `
    mutation AdminCreateUser($input: AdminUpdateUserInput!) {
  adminUpdateUser(input: $input) {
    userId
    username
    firstName
    lastName
    email
    phone
    isVirtual
    dateCreated
    membership {
      type
    }
  }
}
    `;
export const AdminUpdateUserDocument = `
    mutation AdminUpdateUser($input: AdminUpdateUserInput!) {
  adminUpdateUser(input: $input) {
    userId
    username
    firstName
    lastName
    email
    phone
    isVirtual
    dateCreated
    dateDeleted
    gender
    lastActivity
    isFlagged
    membership {
      type
    }
  }
}
    `;
export const UpdateInterviewCollectionDocument = `
    mutation UpdateInterviewCollection($input: UpdateInterviewCollectionInput!) {
  updateInterviewCollection(input: $input) {
    interviewerName
    dateDeleted
    lastUpdated
    name
    targetUserId
    collectionId
    creatorUserId
    dateCreated
  }
}
    `;
export const AdminUpdateTwinModelConfigDocument = `
    mutation AdminUpdateTwinModelConfig($input: AdminUpdateTwinModelConfigInput!) {
  adminUpdateTwinModelConfig(input: $input) {
    modelConfigId
    name
    description
    selectedTtsConfigId
    ttsConfig {
      dateCreated
      voiceId
    }
    selectedAsrConfigId
    asrConfig {
      language
    }
    selectedPromptConfigId
    promptConfig {
      promptConfigId
    }
    llmProvider
    targetUserId
    dateCreated
    lastUpdated
    dateDeleted
    twinLevel
    twinDescription
    twinType
    functionConfigs {
      functionId
      functionName
    }
    visibility
  }
}
    `;
export const UpdateInterviewDocument = `
    mutation UpdateInterview($input: UpdateInterviewInput!) {
  updateInterview(input: $input) {
    uploaderUserId
    targetUserId
    s3Key
    parentCollectionId
    lastUpdated
    interviewId
    fileSize
    fileName
    durationSecs
    dateDeleted
    dateCreated
  }
}
    `;
export const CompleteFileUploadDocument = `
    mutation CompleteFileUpload($input: CompleteFileUploadInput!) {
  completeFileUpload(input: $input) {
    eTag
    key
    location
  }
}
    `;
export const AdminUpdateVoiceSampleDocument = `
    mutation AdminUpdateVoiceSample($input: AdminUpdateTwinVoiceSampleInput!) {
  adminUpdateTwinVoiceSample(input: $input) {
    voiceSampleUri
    voiceId
    ttsConfigId
    speed
  }
}
    `;
export const AdminUpdateTwinVoiceDocument = `
    mutation AdminUpdateTwinVoice($input: AdminUpdateTwinVoiceInput!) {
  adminUpdateTwinVoice(input: $input) {
    voiceSampleUri
    ttsConfigId
  }
}
    `;
export const AdminCreateRoomDocument = `
    mutation AdminCreateRoom {
  adminCreateRoom {
    url
  }
}
    `;
export const AdminInviteTwinToRoomDocument = `
    mutation AdminInviteTwinToRoom($input: AdminInviteTwinToRoomInput!) {
  adminInviteTwinToChatRoom(input: $input) {
    success
    message
  }
}
    `;
export const AdminUpdateTwinPromptDocument = `
    mutation AdminUpdateTwinPrompt($input: AdminUpdateTwinPromptInput!) {
  adminUpdateTwinPrompt(input: $input) {
    customValue
    dateCreated
    dateDeleted
    lastUpdated
    personalityTraits
    promptConfigId
    startPhrase
    promptOverride
    namePronunciation
    conversationalStyle
    personalBackground
    configName
    overrideActive
  }
}
    `;
export const AdminStartInterviewProcessDocument = `
    mutation AdminStartInterviewProcess($input: AdminStartInterviewProcessInput!) {
  adminStartInterviewProcess(input: $input) {
    jobId
    batchJobId
    serviceType
    status
    jobType
    targetUserId
    statusMessage
    parentFileId
    parentFileType
    creatorUserId
    dateCreated
    lastUpdated
  }
}
    `;
export const AdminUpdateTwinJobDocument = `
    mutation adminUpdateTwinJob($input: AdminUpdateTwinJobInput!) {
  adminUpdateTwinJob(input: $input) {
    jobId
    batchJobId
    serviceType
    status
    jobType
    targetUserId
    statusMessage
    parentFileId
    parentFileType
    creatorUserId
    dateCreated
    lastUpdated
  }
}
    `;
export const AdminUpdateTwinDocumentDocument = `
    mutation AdminUpdateTwinDocument($input: AdminUpdateTwinDocumentInput!) {
  adminUpdateTwinDocument(input: $input) {
    documentId
    targetUserId
    documentName
    contentUri
    audience
    fileSize
    dateCreated
    lastUpdated
    dateDeleted
    twinGraphIngestionStatus
  }
}
    `;
export const UpdateInterviewSegmentsDocument = `
    mutation UpdateInterviewSegments($input: UpdateInterviewSegmentsInput!) {
  updateInterviewSegments(input: $input) {
    interviewSegmentId
    parentInterviewId
    parentCollectionId
    targetUserId
    transcription
    s3Key
    fileSize
    startTimeSecs
    endTimeSecs
    speakerLabel
    speakerRole
    dateCreated
    lastUpdated
  }
}
    `;
export const AdminGetUserDocument = `
    query AdminGetUser($input: AdminGetUserInput!) {
  adminGetUser(input: $input) {
    totalCount
    user {
      userId
      username
      email
      firstName
      lastName
      dateCreated
      dateDeleted
      isFlagged
      isVirtual
      gender
      phone
      lastActivity
      stats {
        follows
        hidden
        followedBy
      }
      profilePictureUri
      flags {
        deactivatedAt
        deleteReason
        deletedAt
        lastDeactivatedAt
      }
      interactions {
        sendMessage
        sendRequest
      }
      membership {
        type
        membershipId
        dateCreated
        lastUpdated
        status
        parentUserId
      }
    }
  }
}
    `;
export const SearchInterviewCollectionsDocument = `
    query searchInterviewCollections($input: SearchInterviewCollectionsInput!) {
  searchInterviewCollections(input: $input) {
    interviewerName
    dateDeleted
    lastUpdated
    name
    targetUserId
    collectionId
    creatorUserId
    dateCreated
    interviews {
      dateCreated
      durationSecs
      fileName
      fileSize
      interviewId
      parentCollectionId
      s3Key
      targetUserId
      uploaderUserId
      lastUpdated
      processingStage
      twinGraphIngestionStatus
    }
  }
}
    `;
export const AdminSearchUsersDocument = `
    query AdminSearchUsers($input: AdminSearchUsersInput!) {
  adminSearchUsers(input: $input) {
    users {
      userId
      username
      sub
      firstName
      lastName
      dateOfBirth
      email
      phone
      gender
      genderDesc
      dateCreated
      dateDeleted
      lastActivity
      profilePictureUri
      aboutMe
      isFlagged
      isVirtual
      connectionStatus
      isVirtual
      membership {
        type
        membershipId
        dateCreated
        lastUpdated
        status
        parentUserId
      }
    }
    totalCount
  }
}
    `;
export const AdminSearchTwinModelConfigsDocument = `
    query AdminSearchTwinModelConfigs($input: AdminSearchTwinModelConfigsInput!) {
  adminSearchTwinModelConfigs(input: $input) {
    modelConfigId
    name
    description
    selectedTtsConfigId
    ttsConfig {
      ttsConfigId
      dateCreated
      voiceId
      voiceSampleUri
      language
      speed
      voiceSampleText
    }
    selectedAsrConfigId
    asrConfig {
      language
    }
    selectedPromptConfigId
    promptConfig {
      promptConfigId
      configName
      overrideActive
      personalityTraits
      personalBackground
      promptOverride
      startPhrase
      conversationalStyle
      customValue
    }
    llmProvider
    targetUserId
    dateCreated
    lastUpdated
    dateDeleted
    twinLevel
    twinDescription
    twinType
    functionConfigs {
      functionId
      functionName
    }
    visibility
  }
}
    `;
export const AdminGetSignedS3UrlDocument = `
    query AdminGetSignedS3Url($input: AdminGetSignedS3UrlInput!) {
  adminGetSignedS3Url(input: $input) {
    headers
    type
    url
    uploadId
  }
}
    `;
export const AdminSearchTwinMediaDocument = `
    query AdminSearchTwinMedia($input: AdminSearchTwinMediaInput!) {
  adminSearchTwinMedia(input: $input) {
    mediaId
    targetModelConfigId
    mediaName
    contentUri
    durationSecs
    targetUserId
  }
}
    `;
export const AminSearchTwinVoicesDocument = `
    query AminSearchTwinVoices($input: AdminSearchTwinVoicesInput!) {
  adminSearchTwinVoices(input: $input) {
    voiceSampleUri
    voiceSampleText
    speed
    language
    ttsConfigId
    targetUserId
    voiceId
    dateCreated
    lastUpdated
    activeModelConfigIds
  }
}
    `;
export const AdminGetTwinGraphDigestDocument = `
    query AdminGetTwinGraphDigest($input: AdminGetTwinDigestInput!) {
  adminGetTwinGraphDigest(input: $input) {
    personalitySummary
    keyExperiences
    conversationalStyle
    twinDescription
  }
}
    `;
export const AdminSearchTwinPromptDocument = `
    query AdminSearchTwinPrompt($input: AdminSearchTwinPromptInput!) {
  adminSearchTwinPrompt(input: $input) {
    customValue
    dateCreated
    dateDeleted
    lastUpdated
    personalityTraits
    promptConfigId
    overrideActive
    startPhrase
    promptOverride
    namePronunciation
    conversationalStyle
    personalBackground
    configName
    activeModelConfigIds
  }
}
    `;
export const AdminSearchSamplesDocument = `
    query AdminSearchSamples($input: AdminSearchSamplesInput!) {
  adminSearchSamples(input: $input) {
    dateCreated
    endTimeSecs
    fileSize
    interviewSegmentId
    lastUpdated
    parentCollectionId
    parentInterviewId
    s3Key
    startTimeSecs
    targetUserId
    transcription
    speakerRole
    speakerLabel
  }
}
    `;
export const AdminSearchTwinJobsDocument = `
    query AdminSearchTwinJobs($input: SearchTwinJobsInput!) {
  searchTwinJobs(input: $input) {
    jobId
    batchJobId
    serviceType
    status
    jobType
    targetUserId
    statusMessage
    parentFileId
    parentFileType
    creatorUserId
    dateCreated
    lastUpdated
  }
}
    `;
export const AdminSearchTwinDocumentsDocument = `
    query AdminSearchTwinDocuments($input: AdminSearchTwinDocumentsInput!) {
  adminSearchTwinDocuments(input: $input) {
    documentId
    targetUserId
    documentName
    contentUri
    audience
    fileSize
    dateCreated
    lastUpdated
    dateDeleted
    twinGraphIngestionStatus
  }
}
    `;

const injectedRtkApi = baseApiWithGraphQL.injectEndpoints({
  endpoints: (build) => ({
    AdminCreateUser: build.mutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>({
      query: (variables) => ({ document: AdminCreateUserDocument, variables })
    }),
    AdminUpdateUser: build.mutation<AdminUpdateUserMutation, AdminUpdateUserMutationVariables>({
      query: (variables) => ({ document: AdminUpdateUserDocument, variables })
    }),
    UpdateInterviewCollection: build.mutation<UpdateInterviewCollectionMutation, UpdateInterviewCollectionMutationVariables>({
      query: (variables) => ({ document: UpdateInterviewCollectionDocument, variables })
    }),
    AdminUpdateTwinModelConfig: build.mutation<AdminUpdateTwinModelConfigMutation, AdminUpdateTwinModelConfigMutationVariables>({
      query: (variables) => ({ document: AdminUpdateTwinModelConfigDocument, variables })
    }),
    UpdateInterview: build.mutation<UpdateInterviewMutation, UpdateInterviewMutationVariables>({
      query: (variables) => ({ document: UpdateInterviewDocument, variables })
    }),
    CompleteFileUpload: build.mutation<CompleteFileUploadMutation, CompleteFileUploadMutationVariables>({
      query: (variables) => ({ document: CompleteFileUploadDocument, variables })
    }),
    AdminUpdateVoiceSample: build.mutation<AdminUpdateVoiceSampleMutation, AdminUpdateVoiceSampleMutationVariables>({
      query: (variables) => ({ document: AdminUpdateVoiceSampleDocument, variables })
    }),
    AdminUpdateTwinVoice: build.mutation<AdminUpdateTwinVoiceMutation, AdminUpdateTwinVoiceMutationVariables>({
      query: (variables) => ({ document: AdminUpdateTwinVoiceDocument, variables })
    }),
    AdminCreateRoom: build.mutation<AdminCreateRoomMutation, AdminCreateRoomMutationVariables | void>({
      query: (variables) => ({ document: AdminCreateRoomDocument, variables })
    }),
    AdminInviteTwinToRoom: build.mutation<AdminInviteTwinToRoomMutation, AdminInviteTwinToRoomMutationVariables>({
      query: (variables) => ({ document: AdminInviteTwinToRoomDocument, variables })
    }),
    AdminUpdateTwinPrompt: build.mutation<AdminUpdateTwinPromptMutation, AdminUpdateTwinPromptMutationVariables>({
      query: (variables) => ({ document: AdminUpdateTwinPromptDocument, variables })
    }),
    AdminStartInterviewProcess: build.mutation<AdminStartInterviewProcessMutation, AdminStartInterviewProcessMutationVariables>({
      query: (variables) => ({ document: AdminStartInterviewProcessDocument, variables })
    }),
    adminUpdateTwinJob: build.mutation<AdminUpdateTwinJobMutation, AdminUpdateTwinJobMutationVariables>({
      query: (variables) => ({ document: AdminUpdateTwinJobDocument, variables })
    }),
    AdminUpdateTwinDocument: build.mutation<AdminUpdateTwinDocumentMutation, AdminUpdateTwinDocumentMutationVariables>({
      query: (variables) => ({ document: AdminUpdateTwinDocumentDocument, variables })
    }),
    UpdateInterviewSegments: build.mutation<UpdateInterviewSegmentsMutation, UpdateInterviewSegmentsMutationVariables>({
      query: (variables) => ({ document: UpdateInterviewSegmentsDocument, variables })
    }),
    AdminGetUser: build.query<AdminGetUserQuery, AdminGetUserQueryVariables>({
      query: (variables) => ({ document: AdminGetUserDocument, variables })
    }),
    searchInterviewCollections: build.query<SearchInterviewCollectionsQuery, SearchInterviewCollectionsQueryVariables>({
      query: (variables) => ({ document: SearchInterviewCollectionsDocument, variables })
    }),
    AdminSearchUsers: build.query<AdminSearchUsersQuery, AdminSearchUsersQueryVariables>({
      query: (variables) => ({ document: AdminSearchUsersDocument, variables })
    }),
    AdminSearchTwinModelConfigs: build.query<AdminSearchTwinModelConfigsQuery, AdminSearchTwinModelConfigsQueryVariables>({
      query: (variables) => ({ document: AdminSearchTwinModelConfigsDocument, variables })
    }),
    AdminGetSignedS3Url: build.query<AdminGetSignedS3UrlQuery, AdminGetSignedS3UrlQueryVariables>({
      query: (variables) => ({ document: AdminGetSignedS3UrlDocument, variables })
    }),
    AdminSearchTwinMedia: build.query<AdminSearchTwinMediaQuery, AdminSearchTwinMediaQueryVariables>({
      query: (variables) => ({ document: AdminSearchTwinMediaDocument, variables })
    }),
    AminSearchTwinVoices: build.query<AminSearchTwinVoicesQuery, AminSearchTwinVoicesQueryVariables>({
      query: (variables) => ({ document: AminSearchTwinVoicesDocument, variables })
    }),
    AdminGetTwinGraphDigest: build.query<AdminGetTwinGraphDigestQuery, AdminGetTwinGraphDigestQueryVariables>({
      query: (variables) => ({ document: AdminGetTwinGraphDigestDocument, variables })
    }),
    AdminSearchTwinPrompt: build.query<AdminSearchTwinPromptQuery, AdminSearchTwinPromptQueryVariables>({
      query: (variables) => ({ document: AdminSearchTwinPromptDocument, variables })
    }),
    AdminSearchSamples: build.query<AdminSearchSamplesQuery, AdminSearchSamplesQueryVariables>({
      query: (variables) => ({ document: AdminSearchSamplesDocument, variables })
    }),
    AdminSearchTwinJobs: build.query<AdminSearchTwinJobsQuery, AdminSearchTwinJobsQueryVariables>({
      query: (variables) => ({ document: AdminSearchTwinJobsDocument, variables })
    }),
    AdminSearchTwinDocuments: build.query<AdminSearchTwinDocumentsQuery, AdminSearchTwinDocumentsQueryVariables>({
      query: (variables) => ({ document: AdminSearchTwinDocumentsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


