import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

type ConfirmProps = {
  title?: string;
  message: string;
  isShown?: boolean;
  confirmLabel?: string;
  rejectLabel?: string;
  onClose?: () => void;
  onConfirm?: () => void;
  onReject?: () => void;
};

export function ConfirmDialog({
  title = 'Are you sure?',
  message,
  isShown = false,
  confirmLabel = 'Continue',
  rejectLabel = 'Cancel',
  onConfirm = () => {},
  onReject = () => {},
  onClose = () => {},
}: ConfirmProps) {
  return (
    <Dialog
      open={isShown}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onReject}
        >
          {rejectLabel}
        </Button>
        <Button onClick={onConfirm}>{confirmLabel}</Button>
      </DialogActions>
    </Dialog>
  );
}
