import {
  AdminStartInterviewProcessInput,
  AdminUpdateTwinJobInput,
  Interview,
  TwinJob,
} from '@superfeel/types';
import { Button } from 'components/button';
import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import {
  useAdminStartInterviewProcessMutation,
  useAdminUpdateTwinJobMutation,
} from 'routes/user/api/user';
import { useSnackbar } from 'state/context/snackBar';

type ActionButtonInput = {
  interview: Interview;
  jobs?: TwinJob[];
  openSamplesModal: () => void;
};

export function InterviewActionButtons({
  interview,
  jobs = [],
  openSamplesModal,
}: ActionButtonInput) {
  const { showSnackbar } = useSnackbar();
  const [startInterviewProcess, { isLoading: isStarting }] =
    useAdminStartInterviewProcessMutation();
  const [startIngestionJob, { isLoading: isStartingJob }] =
    useAdminUpdateTwinJobMutation();

  const currentJob: TwinJob | undefined = useMemo(() => {
    const sortedJobs = jobs
      .filter((job) => job.jobType === interview.processingStage)
      .sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
    if (!sortedJobs.length) {
      return undefined;
    }
    return sortedJobs[0];
  }, [interview.processingStage, jobs]);

  const buttonLabel = useMemo(() => {
    const { processingStage, twinGraphIngestionStatus } = interview;
    if (
      processingStage === 'SEGMENTATION' &&
      currentJob?.status === 'SUCCEEDED'
    ) {
      return 'Identify audio samples';
    }
    if (currentJob?.status === 'FAILED') {
      return 'Retry';
    }
    if (processingStage === 'PROCESSING') {
      return 'Start processing';
    }
    if (
      processingStage === 'GRAPH' &&
      twinGraphIngestionStatus === 'NOT_STARTED'
    ) {
      return 'Start ingestion';
    }
    if (processingStage === 'GRAPH' && twinGraphIngestionStatus === 'FAILED') {
      return 'Retry';
    }
    return '';
  }, [currentJob?.status, interview]);

  const handleStartProcess = useCallback(() => {
    if (isStarting || !interview?.interviewId) {
      return;
    }
    const input: AdminStartInterviewProcessInput = {
      interviewId: interview.interviewId,
    };
    startInterviewProcess({ input })
      .unwrap()
      .then(() => showSnackbar('File submitted for processing', 'success'))
      .catch((err) =>
        showSnackbar(
          `Error submitting file: ${(err as Error)?.message || 'Unknown error'}`,
          'error',
        ),
      );
  }, [interview?.interviewId, isStarting, showSnackbar, startInterviewProcess]);

  const handleStartIngestionJob = useCallback(() => {
    if (isStartingJob || !interview?.interviewId) {
      return;
    }
    const input: AdminUpdateTwinJobInput = {
      index: 'demo',
      interviewIds: [interview.interviewId],
      targetUserId: interview.targetUserId,
    };
    startIngestionJob({ input })
      .unwrap()
      .then(() => showSnackbar('Interview submitted for ingestion', 'success'))
      .catch((err) =>
        showSnackbar(
          `Error submitting interview: ${(err as Error)?.message || 'Unknown error'}`,
          'error',
        ),
      );
  }, [
    interview.interviewId,
    interview.targetUserId,
    isStartingJob,
    showSnackbar,
    startIngestionJob,
  ]);

  const handleButtonPress = useCallback(() => {
    const { processingStage, twinGraphIngestionStatus } = interview;
    if (
      processingStage === 'SEGMENTATION' &&
      currentJob?.status === 'SUCCEEDED'
    ) {
      openSamplesModal();
    } else if (
      (processingStage === 'GRAPH' &&
        twinGraphIngestionStatus === 'NOT_STARTED') ||
      twinGraphIngestionStatus === 'FAILED'
    ) {
      handleStartIngestionJob();
    } else if (
      processingStage === 'PROCESSING' ||
      currentJob?.status === 'FAILED'
    ) {
      handleStartProcess();
    }
  }, [
    interview,
    currentJob?.status,
    openSamplesModal,
    handleStartIngestionJob,
    handleStartProcess,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        gap: 2,
        m: 1,
      }}
    >
      {!!buttonLabel && (
        <Button
          label={buttonLabel}
          size="small"
          onClick={handleButtonPress}
          loading={isStarting || isStartingJob}
        />
      )}
    </Box>
  );
}
