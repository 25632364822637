import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import { useCallback, useState } from 'react';
import { Loading } from 'components/atoms';
import { TwinModelTtsConfig } from 'routes/graphql.generated';
import { Typography } from '@mui/material';
import useGetTargetUser from 'routes/user/hooks/useGetTargetUser';
import { useSearchAdminTwinVoicesQuery } from '../../../api/user';
import VoiceCard from '../voice-card/voice-card';
import VoiceEditModal from '../voice-edit-modal';

export default function UserVoice() {
  const [selectedVoiceConfig, setSelectedVoiceConfig] =
    useState<TwinModelTtsConfig | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { user } = useGetTargetUser();
  const { data, isLoading, isError } = useSearchAdminTwinVoicesQuery(
    {
      input: {
        targetUserId: user?.userId || '',
      },
    },
    { skip: !user?.userId },
  );

  const handleCloseModal = useCallback(() => {
    setIsEditModalOpen(false);
    setSelectedVoiceConfig(null);
  }, []);

  if (isError || isError) return <div>Error fetching data</div>;
  if (isLoading || isLoading) return <Loading />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {!data?.adminSearchTwinVoices?.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="75vh"
          width="100%"
          height="100%"
          gap={3}
        >
          <Box
            textAlign="center"
            maxWidth="450px"
          >
            <Typography
              variant="body1"
              color="text.primary"
              gutterBottom
            >
              Add your first voice
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              Add a voice to give your Heir some personality
            </Typography>
          </Box>
        </Box>
      ) : (
        <Grid
          container
          spacing={2}
        >
          {data.adminSearchTwinVoices?.map((voice) => (
            <Grid
              size={{ sm: 12, md: 12, lg: 6 }}
              key={voice.voiceId}
            >
              <VoiceCard
                config={voice}
                onEdit={(cfg) => {
                  setSelectedVoiceConfig(cfg);
                  setIsEditModalOpen(true);
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <VoiceEditModal
        config={selectedVoiceConfig}
        closeModal={handleCloseModal}
        isOpen={isEditModalOpen}
        targetUser={user}
      />
    </Box>
  );
}
