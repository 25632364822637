import { ResourcesConfig } from 'aws-amplify';

const {
  VITE_REACT_APP_ADMIN_COGNITO_IDENTITY_POOL_ID,
  VITE_REACT_APP_ADMIN_COGNITO_USER_POOL_ID,
  VITE_REACT_APP_ADMIN_COGNITO_APP_CLIENT_ID,
  VITE_REACT_APP_ADMIN_COGNITO_DOMAIN,
  VITE_REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_IN,
  VITE_REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_OUT,
  VITE_REACT_APP_ADMIN_APPSYNC_API_ENDPOINT,
} = import.meta.env;

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      identityPoolId: String(VITE_REACT_APP_ADMIN_COGNITO_IDENTITY_POOL_ID),
      userPoolId: String(VITE_REACT_APP_ADMIN_COGNITO_USER_POOL_ID),
      userPoolClientId: String(VITE_REACT_APP_ADMIN_COGNITO_APP_CLIENT_ID),
      allowGuestAccess: true,
      loginWith: {
        oauth: {
          domain: String(VITE_REACT_APP_ADMIN_COGNITO_DOMAIN),
          scopes: ['email', 'openid', 'phone', 'profile'],
          redirectSignIn: [
            String(VITE_REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_IN),
          ],
          redirectSignOut: [
            String(VITE_REACT_APP_ADMIN_COGNITO_REDIRECT_SIGN_OUT),
          ],
          responseType: 'code',
          providers: [],
        },
        email: true,
        phone: true,
        username: true,
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: String(VITE_REACT_APP_ADMIN_APPSYNC_API_ENDPOINT),
      region: 'eu-west-2',
      defaultAuthMode: 'lambda',
    },
  },
};
